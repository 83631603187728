<template>
    <div class="panel card">
    <div class="card-body">
      <div class="panel-header mb-4"><h1 class="h3">免责声明 </h1></div>
      <div class="panel-body mt-2"><p>
        任何用户在使用123导航（www.123coco.com）服务之前，均应仔细阅读本声明，用户可选择不使用123导航站服务，一旦使用，即被视为对本声明全部内容的认可和接受。</p>
        <p>
          1.123导航网为用户提供的上网导航服务，通过多项技术创新为用户提供更好的浏览服务和功能，包括浏览网页、网址输入及搜索联想推荐、网址导航、精彩资讯推荐等服务。123导航网不直接上传、提供内容，对用户接入、浏览、访问、传输的内容不做任何修改或编辑。</p>
        <p>2.123导航网对于本站的链接或任何与本网站有关服务所获得的信息、广告，不保证其正确性或可靠性。您需自行判断，自行承担其风险。本站有权但无此义务，改善或更正本站信息中任何部分的错误或疏忽；对于因本站信息所生的任何直接、间接、附带的或因此而导致的衍生性损失概不负责。</p>
        <p>3.123导航网不对本网站内的言论、文章、资源和信息的真实性、正确性和有效性做任何保证或承诺，也不对任何因本站内容而采取的行为负责。</p>
        <p>4.123导航网所有广告内容，本网站不做担保，出现任何问题您自己承担。任何个人或组织，不得借靠本网站的传播力度，发布任何违法相关法律的内容，否则，本网站将保留法律自护的权利。</p>
        <p>5.123导航网尊重他人的任何权利（包括知识产权），同时要求我们的使用者也尊重他人的权利。本站分享的部分公开资料来源于因特网，如果本站有损其所有人利益的内容，请及时通本站，本站将妥善处理。</p>
        <p>6.本站仅收录网站和推荐精彩资讯，不对其网站内容或交易负责。若收录的站点和推荐相关资讯侵害到您的利益，请联系我们删除收录。 邮箱： wkfs2008@126.com。</p>
        <p>为了更好维护本站用户的权益！如有发现违法,欺诈行为。欢迎大家及时举报！</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Agreement",
  components: {},

  data() {
    return {};
  },

  created() {
  },

  methods: {},
};
</script>

<style scoped lang="less">
.card {
  background: #fff;
  border-width: 0;
  margin: 5rem;
  box-shadow: 0px 0px 20px -5px rgb(158 158 158 / 20%);
  transition: background-color .3s;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.panel-body p {
  margin-bottom: 1.25rem;
  font-size: 1rem;
  line-height: 2;
  word-wrap: break-word;
}
</style>
