const state = {

}
const mutations = {

}
const actions = {
    async test() {
        //console.log('test actions test...')
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
