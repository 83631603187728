const state = {
    data: [],
    title: [],
}
const mutations = {
    setMoreData(state, data) {
        state.data = data
    },
    setMoreTitle(state, data) {
        state.title = data
    }
}
const actions = {
    // 获取办公大站
    async getMoreBusinessData({commit}) {
         const data1 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'https://www.callmysoft.com/jiami?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '文件夹加密'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'https://www.callmysoft.com/chongmingming?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '批量重命名'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.callmysoft.com/duoliao?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '金舟多聊'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/formatfactory?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '格式工厂'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/pdfzhuanhuan?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: 'PDF转换器'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/watermark?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '水印助手'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/yinpinzhuanhuan?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '音频格式转换'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/diannaoluyin?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '电脑录音软件'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/voice?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '音频人声分离'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/shipinzhuanhuan?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '视频格式转换'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/mv?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '电子相册'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/shipinfengehebing?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '视频大师'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/luping?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '录屏大师'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/pingguotouping?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '金舟投屏'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/subtitle-editor?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '视频字幕编辑'
                },
            ],
        ];
           const data2 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'https://translate.google.cn/',
                    title: 'googel翻译'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'https://fanyi.youdao.com',
                    title: '有道翻译'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://fanyi.baidu.com/',
                    title: '百度翻译'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.iciba.com/',
                    title: '金山词霸'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.deepl.com/translator',
                    title: 'DeepL翻译'
                },
            ],
        ];
        const data3 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://pan.baidu.com/',
                    title: '百度网盘'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.aliyundrive.com/',
                    title: '阿里云盘'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://cloud.189.cn',
                    title: '天翼云盘'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.weiyun.com/',
                    title: '腾讯微云'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/souhu.png'),
                    url: 'https://pan.xunlei.com/login/',
                    title: '迅雷云盘'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://pan.quark.cn/',
                    title: '夸克网盘 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.123pan.com/',
                    title: '123网盘 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://115.com/',
                    title: '115网盘 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://cowtransfer.com/',
                    title: '奶牛快传 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/taobao.png'),
                    url: 'https://pan.wo.cn',
                    title: '联通云盘'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://www.ctfile.com/',
                    title: '诚通网盘',
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://vdisk.weibo.com/',
                    title: '新浪微盘',
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://www.quqi.com/',
                    title: '曲奇云盘',
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://yun.139.com',
                    title: '和彩云',
                },
                                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://wormhole.app/',
                    title: '虫洞文件共享',
                },
            ],
        ];
        const data4 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.utrainee.com/',
                    title: '职徒'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.yoojober.com/',
                    title: 'YOO简历'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.polebrief.com/',
                    title: '极简简历'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.zhiyeapp.com/',
                    title: '知页简历'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/souhu.png'),
                    url: 'https://jlcr.haitou.cc/',
                    title: '简历超人'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.qmjianli.com/',
                    title: '全民简历'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.jianliben.com/',
                    title: '简历本'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.wondercv.com/',
                    title: '超级简历'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.100chui.com/',
                    title: '锤子简历'
                },
            ],
        ];
         const data5 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.1ppt.com/',
                    title: '第一PPT模版'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.pptsupermarket.com/',
                    title: 'PPT超级市场'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.51pptmoban.com/',
                    title: '51PPT模版'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://sc.chinaz.com/ppt/free.html',
                    title: '站长PPT'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/souhu.png'),
                    url: 'https://www.52ppt.com/',
                    title: 'PPT之家'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.redpptx.com/',
                    title: '红船PPT'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://588ku.com/ppt/',
                    title: '千库网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.pptjia.com/',
                    title: 'PPT家园'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.99ppt.com/',
                    title: '当图网'
                },
            ],
        ]; 
        const data6 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://cont.12315.cn/',
                    title: '合同文本库'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.66law.cn/contractmodel/',
                    title: '合同范本库'
                },
            ],
        ];
        const data7 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.auditcn.com/Index.html',
                    title: '中审网校'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.gaotu.cn/?genshuixue=1',
                    title: '高途在线'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.xdf.cn/',
                    title: '新东方网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.gaodun.com/',
                    title: '高顿教育'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://www.teacher.com.cn/',
                    title: '教师继续教育网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.fenbi.com/page/home',
                    title: '粉笔网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.offcn.com/',
                    title: '中公教育网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://www.cpta.com.cn/',
                    title: '人事考试网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.jszg.edu.cn/portal/home/index',
                    title: '教师资格网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.coc.gov.cn/coc/',
                    title: '中国建造师网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.txks.org.cn/',
                    title: '通信专业考试'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://www.catticenter.com/',
                    title: '翻译专业考试'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.ruankao.org.cn/',
                    title: '计算机职业资格'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://yangbang.cn/',
                    title: '互联网VIP'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://bbs.hh010.com/',
                    title: '鸿鹄论坛'
                },
            ],
         ];
        const data8 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.67tool.com/',
                    title: '即时工具'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://aigc.yizhentv.com',
                    title: '一帧秒创'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://online-video-cutter.com/cn/',
                    title: '在线视频剪辑'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://ghostcut.jollytoday.com/index/cn',
                    title: '视频去字幕'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/souhu.png'),
                    url: 'https://www.ijianji.com/',
                    title: '爱剪辑'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.huishenghuiying.com.cn',
                    title: '会声会影'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.zimujiang.com/',
                    title: '字幕酱'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.aipai.com/',
                    title: '拍大师'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/taobao.png'),
                    url: 'https://www.animiz.cn/',
                    title: '万彩动画大师'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://aimeike.tv/',
                    title: '爱美刻',
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tianmao.png'),
                    url: 'https://www.laihua.com/',
                    title: '来画'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/weipinhui.png'),
                    url: 'https://www.videotosave.com/',
                    title: 'VideoToSave'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/dianshi.png'),
                    url: 'https://www.chuanying520.com/',
                    title: '传影DIY'
                },
            ],
        ];
        const data9 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.onlinedoctranslator.com/zh-CN/translationform',
                    title: '在线文档翻译'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://img.logosc.cn/photo-id',
                    title: '证件照制作'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.ilovepdf.com/zh-cn',
                    title: 'PDF在线工具'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://web.baimiaoapp.com/',
                    title: '图片转文字'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://convertio.co/zh/',
                    title: '万能格式转换'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.aies.cn/',
                    title: '繁体字转换'
                },
                                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.bitbug.net/',
                    title: '制作ico图标'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://tools.jb51.net/aideddesign/bt2mag/',
                    title: '磁力种子转换器'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.butterpig.top/icopro',
                    title: '桌面图标制作'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://dongci.kawo.com/',
                    title: '视频转Gif'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://123apps.com/cn/',
                    title: '123apps'
                },
            ],
         ];
           const data10 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://wantwords.net/',
                    title: '反向词典'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.jq22.com/textDifference',
                    title: '在线文本比较'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://www.biezi.com/',
                    title: '错别字检测'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://www.mb5u.com/tool/zishutongji/',
                    title: '在线字数统计'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://www.ju1.cn/',
                    title: '广告违禁词检测'
                },
            ],
         ];
          const data11 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.logosc.cn/',
                    title: 'LOGO设计神器'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.yciyun.com/',
                    title: '文字云制作'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://zhongguose.com/',
                    title: '中国色'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.coocolors.com/',
                    title: '设计师配色'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://yige.baidu.com/',
                    title: '文心一格'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.lanrentuyun.com/',
                    title: '懒人图云'
                },
                                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://fakes.netlify.app/',
                    title: '伪造网页截图'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://icon.wuruihong.com/',
                    title: 'APP启动图生成'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://loading.io/',
                    title: 'loading'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.yoo.la/',
                    title: '有啦拼字幕'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://design.alipay.com/emotion',
                    title: '犸良'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://icon.ray.so/',
                    title: 'Icon Maker'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://tylify.app/',
                    title: 'Tylify'
                },
            ],
         ];
          const data12 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.weather.com.cn/',
                    title: '天气查询'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://wannianli.tianqi.com/',
                    title: '日历查询'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://wangsuceshi.bmcx.com/',
                    title: '网速测试'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://aiqicha.baidu.com/',
                    title: '爱企查'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.ip138.com/',
                    title: 'ip地址查询'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://chacha.so.com/home',
                    title: '图查查'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.chinadaily.com.cn/static/tools/exchange.html',
                    title: '度量制式转换'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://getsimnum.caict.ac.cn/#/',
                    title: '电话卡一证通查'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.51salary.com/',
                    title: '薪情'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://cloud.niucodata.com/',
                    title: '词云统计'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://pccz.court.gov.cn/pcajxxw/index/xxwsy',
                    title: '企业破产重整案'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.sbfl.cn/',
                    title: '商标分类表'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.hurun.net/',
                    title: '胡润富豪榜'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.chsi.com.cn/xlcx/',
                    title: '学历查询'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://qq.ip138.com/idsearch/index.asp',
                    title: '身份证查询'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://typhoon.slt.zj.gov.cn/default.aspx',
                    title: '实时台风路径'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://caipiao.ip138.com/',
                    title: '彩票开奖查询'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.amap.com/',
                    title: '在线地图查询'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.creditchina.gov.cn/',
                    title: '信用中国'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://fonts.safe.360.cn/',
                    title: '字体版权查询'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.youbianku.com/%E9%A6%96%E9%A1%B5',
                    title: '邮政编码查询'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.piyao.org.cn/',
                    title: '辟谣平台'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.sojson.com/time/jsq.html',
                    title: '天数计算器'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://zxgk.court.gov.cn/',
                    title: '中国执行信息公开'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://heat.qq.com/qianxi.php',
                    title: '腾讯位置大数据'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://map.variflight.com/',
                    title: '航线图'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.99cankao.com/',
                    title: '99参考计算网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://zscx.osta.org.cn/',
                    title: '职业资格证查询'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://std.samr.gov.cn/',
                    title: '全国标准信息'
                },
            ],
         ];
          const data13 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://cli.im/',
                    title: '二维码工具'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.bitbug.net/',
                    title: '在线制作ico图标'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.avast.com/zh-cn/random-password-generator/#pc',
                    title: '随机密码生成器'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://www.beautifulcarrot.com/',
                    title: '手写字体生成器'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.butterpig.top/icopro',
                    title: '桌面图标制作'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://qrbtf.com/',
                    title: '二维码美化'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.lishichelun.com/',
                    title: '历史车轮'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.mygraphpaper.com/index.php?lang=zh-hans',
                    title: '图形方格纸制作'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://hao.shufami.com/',
                    title: '书法迷导航'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://bzdt.ch.mnr.gov.cn/',
                    title: '标准地图服务'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.makeamap.cn/',
                    title: '易制地图'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://seal.biaozhiku.com/',
                    title: '395公章'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://privacy.1ts.fun/',
                    title: '隐私政策生成器'
                },
            ],
         ];
          const data14 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://app.haikei.app/',
                    title: 'haikei'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://glitchart.io/',
                    title: 'Generator'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://wickedbackgrounds.com/app',
                    title: 'Backgrounds'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://cojdev.github.io/lowpoly/',
                    title: 'Generator'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://meshgradient.in/',
                    title: 'Meshy'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://codepen.io/pissang/full/geajpX',
                    title: '矢量波浪生成器'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://more.graphics/',
                    title: 'graphics'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://fffuel.co/',
                    title: 'fffuel'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://heropatterns.com/',
                    title: 'Patterns'
                },
            ],
         ];
            const data15 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://isorepublic.com/',
                    title: 'Republic'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.pexels.com/zh-cn/',
                    title: '免费素材图片'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://pixabay.com/zh/',
                    title: 'Pixabay'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://freeforcommercialuse.net/',
                    title: 'FFCU'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.photock.asia/',
                    title: 'Photock'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.pickpik.com/',
                    title: 'PickPik'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://unsplash.com/',
                    title: 'Unsplash'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.hippopx.com/zh/',
                    title: 'Hippopx'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.lifeofpix.com/',
                    title: 'LifeOfPix'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.cc0.cn/',
                    title: 'cc0图片网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://magdeleine.co/browse/',
                    title: 'Magdeleine'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://freenaturestock.com/',
                    title: 'FreeNature'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://mystock.themeisle.com/',
                    title: 'StockPhotos'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://colorhub.me/',
                    title: 'Colorhub'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://pickupimage.com/cn/',
                    title: 'Pickupimage'
                },
            ],
         ];
            const data16 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://iconpark.oceanengine.com/home',
                    title: '图标库'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.iconfont.cn/',
                    title: '矢量图标库'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.flaticon.com/',
                    title: 'Flaticon'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://seekvectors.com/',
                    title: 'Seekvectors'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://dryicons.com/',
                    title: 'dryicons'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://iconstore.co/',
                    title: 'iconstore'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://iconscout.com/',
                    title: 'iconscout'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.freeimages.com/icon?ref=findicons',
                    title: 'FindIcons'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.iosicongallery.com/',
                    title: 'iosicongallery'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.iconfinder.com/',
                    title: 'Iconfinder'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://igoutu.cn/',
                    title: 'Icons8'
                },
            ],
         ];
            const data17 = [
            // 第一行  
             [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://pixelmob.co/',
                    title: 'Pixelmob'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.logosc.cn/so/',
                    title: '标小智'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://soutushenqi.com/home',
                    title: '搜图神器'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.soogif.com/',
                    title: 'Soogif动图'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.cc0.cn/',
                    title: 'cc0图片网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://colorhub.me/',
                    title: 'Colorhub'
                },
            ],
         ];
        const data18 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://wordswag.co/',
                    title: 'WordSwag'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.tusij.com/',
                    title: '图司机'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.isheji.com/',
                    title: '爱设计'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://www.picxiaobai.com/',
                    title: '图小白'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://luban.aliyun.com/',
                    title: '鹿班'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.canva.cn/',
                    title: '可画'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.fotor.com.cn/',
                    title: 'Fotor懒设计'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.canva.cn/?display-com-option=true',
                    title: 'Canva'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://kt.fkw.com/',
                    title: '凡科快图'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.tubangzhu.com/',
                    title: '图帮主'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.zhangu365.com/',
                    title: '战鼓网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://vectr.com/',
                    title: 'Vectr'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://www.eqxiu.com/',
                    title: '易企秀'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://ling.jd.com/?loginstatus=SUCCESS&autologin=0',
                    title: '羚珑'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.postermywall.com/',
                    title: 'PosterMyWall'
                },
                                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://mobile.colorotate.org/',
                    title: 'Colorotate'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.adobe.com/cn/',
                    title: 'AdobeSpark'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.picmonkey.com/',
                    title: 'PicMonkey'
                },
            ],
         ];
          const data19 = [
            // 第一行
             [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.bullmind.com/',
                    title: 'BullMind'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://naotu.baidu.com/',
                    title: '百度脑图'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://mubu.com/home',
                    title: '幕布'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.zhixi.com/',
                    title: '知犀思维导图'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://www.mindline.cn',
                    title: 'MindLine'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://shutu.cn/',
                    title: '树图'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.processon.com/',
                    title: 'ProcessOn'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://zhimap.com/',
                    title: 'ZhiMap'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.liuchengtu.com/',
                    title: '迅捷画图'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://gitmind.cn/',
                    title: 'GitMind'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.edrawsoft.cn/',
                    title: '亿图软件'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.mindmeister.com/zh',
                    title: 'MindMeister'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://xmind.cn/',
                    title: 'Xmind'
                },
            ],
         ];
            const data20 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://huamaobizhi.com/',
                    title: '花猫壁纸'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.4kbizhi.com/',
                    title: '4K高清壁纸'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.3gbizhi.com/',
                    title: '3G壁纸'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.todaybing.com/',
                    title: '必应壁纸'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://papers.co/',
                    title: 'papers.co壁纸'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.jj20.com/',
                    title: '娟娟壁纸'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://bz.zzzmh.cn/index',
                    title: '极简壁纸'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://www.win4000.com/',
                    title: '美桌网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.jpgjpg.com/',
                    title: '极品图片'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.wallpaperfusion.com/',
                    title: 'wallpaper'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.netcarshow.com/',
                    title: 'netcarshow'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://wallhaven.cc/',
                    title: 'wallhaven'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://desk.zol.com.cn/',
                    title: 'ZOL壁纸'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://www.netbian.com/',
                    title: '彼岸桌面'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.goodfon.com/',
                    title: 'goodfon'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://wallpaperscraft.com/',
                    title: 'wallpaperscraft'
                },
            ],
        ];
          const data21 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.godfont.com/',
                    title: '国风书法字体'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.reeji.com/',
                    title: '锐字潮牌字库'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://www.geetype.cn/',
                    title: '极字和风字库'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.fontspace.com/',
                    title: 'fontspace'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://www.ziticq.com/',
                    title: '字体传奇网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://zh.fonts2u.com/',
                    title: 'Fonts2u'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://ziyouziti.com/',
                    title: '自由字体'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.maoken.com/',
                    title: '猫啃网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.hellofont.cn/',
                    title: '字由'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.100font.com/',
                    title: '100font.com'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.likefont.com/',
                    title: '在线识别字体'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://fonts.safe.360.cn/',
                    title: '字体版权查询'
                },
            ],
         ];
         const data22 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://pc.meitu.com/',
                    title: '美图秀秀'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://img.logosc.cn/photo-id',
                    title: '证件照制作'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://img.logosc.cn/',
                    title: '改图神器'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.gaituya.com/',
                    title: '改图鸭'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://tinypng.com/',
                    title: '图片压缩'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://bigjpg.com/',
                    title: '图片无损放大'
                },
                                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://bgsub.cn/webapp/',
                    title: '在线抠图'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.tutieshi.com/tools/',
                    title: '图贴士'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://catocr.com/#/',
                    title: '易飞文字识别'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://makeup.pho.to/zh/',
                    title: '人像在线修图'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://www.zuohaotu.com/svg/',
                    title: 'SVG编辑器'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://zh.pixfix.com/',
                    title: '图片噪点处理'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://www.yingjingtu.com/',
                    title: 'Gif添加字幕'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://picwish.cn/',
                    title: '证件照换底色'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://qingtu.cn/',
                    title: '清图'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.gaitubao.com/',
                    title: '改图宝'
                },
            ],
        ];
            const data23 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.tjxz.cc/',
                    title: '田间小站'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.hujiang.com/',
                    title: '沪江网校'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://www.qcenglish.com',
                    title: '七彩英语'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.openlanguage.com/p/index/',
                    title: '开言英语'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://lang-8.com/',
                    title: '多国语言学习'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.duolingo.cn/',
                    title: 'Duolingo'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.tingclass.net/',
                    title: '听力课堂'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://www.tingroom.com/',
                    title: '在线英语听力'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.kekenet.com/',
                    title: '可可英语'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://web.shanbay.com/web/main',
                    title: '扇贝'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.51voa.com/',
                    title: '美国之音'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.enfamily.cn/',
                    title: '英语家园'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.liulishuo.com/',
                    title: '英语流利说'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://dict.cn/',
                    title: '海词词典'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://www.mofunenglish.com/',
                    title: '魔方英语'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://www.wumii.com/web/website/pc',
                    title: '一点英语'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://learn-english.dev/',
                    title: '程序员英语'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.lingohut.com/',
                    title: 'LingoHut'
                },
            ],
         ];
          const data24 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.learnfk.com/',
                    title: '无涯教程网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.aidaxue.com/',
                    title: 'AI大学堂'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.ituring.com.cn/',
                    title: '图灵社区'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.tedu.cn/',
                    title: '达内教育'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://ife.baidu.com/',
                    title: '百度前端技术'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://css.doyoe.com/',
                    title: 'CSS参考手册'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.epubit.com/',
                    title: '异步社区'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://edu.51cto.com/',
                    title: '51CTO学堂'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://edu.csdn.net/',
                    title: 'CSDN程序员'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://time.geekbang.org/',
                    title: '极客时间'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.jikexueyuan.com/',
                    title: '极客学院'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.w3cschool.cn/',
                    title: 'w3cschool'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.w3school.com.cn/',
                    title: 'W3School'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://live.freebuf.com/',
                    title: 'FreeBuf公开课'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.imooc.com/',
                    title: '慕课网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.kanxue.com/course.htm',
                    title: '看雪学院'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://edu.51cto.com/',
                    title: '51CTO学堂'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.ichunqiu.com/',
                    title: 'i春秋'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.runoob.com/',
                    title: '菜鸟教程'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.lanqiao.cn/',
                    title: '蓝桥云课'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.itcast.cn/',
                    title: '传智教育'
                },
            ],
         ];
         const data25 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://c.p2hp.com/',
                    title: '高手工具'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://1024tools.com/',
                    title: '程序员开发工具'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.free-api.com/',
                    title: 'api接口大全'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://cssgradient.io/',
                    title: 'CSSGradient'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://app.quicktype.io/',
                    title: 'quicktype'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://c.runoob.com/',
                    title: '菜鸟工具'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.bejson.com/',
                    title: 'BeJSON'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.json.cn/',
                    title: 'JSON.cn'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://tool.oschina.net/',
                    title: 'NET在线工具'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.sojson.com/',
                    title: 'JSON在线工具'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://tool.lu/',
                    title: '在线工具'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://tools.jb51.net/',
                    title: '脚本之家工具'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://kindeditor.net/demo.php',
                    title: 'HTML编辑器'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://qqe2.com/',
                    title: '奇Q工具网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.juhe.cn',
                    title: '聚合数据'
                },
            ],
         ];
        const data = {
            //标题不能一样
            "金舟办公": data1,
            "在线翻译": data2,
            "网上云盘": data3,
            "简历模版": data4,
            "PPT模版": data5,
            "合同协议": data6,
            "职场考证": data7,
            "视频工具": data8,
            "转换工具": data9,
            "文字工具": data10,
            "设计工具": data11,
            "查询工具": data12,
            "制作生成": data13,
            "背景生成": data14,
            "高清图片": data15,
            "图标素材": data16,
            "图片搜索": data17,
            "在线作图": data18,
            "导图流程": data19,
            "壁纸下载": data20,
            "字体下载": data21,
            "图片工具": data22,
            "英语外语": data23,
            "IT技术": data24,
            "编程开发": data25,
        }
        const title = [
            ["金舟办公","在线翻译", "网上云盘", "简历模版", "PPT模版", "合同协议","职场考证",],
            ["视频工具","转换工具","文字工具","设计工具", "查询工具", "制作生成", "背景生成",],
            ["高清图片","图标素材","图片搜索", "在线作图", "导图流程","壁纸下载", "字体下载", "图片工具",],
            ["英语外语", "IT技术", "编程开发",]
        ];
        commit('setMoreData', data)
        commit('setMoreTitle', title)
    },
    // 获取新闻大站
    async getMoreData({commit}) {
        const data1 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'https://www.chinanews.com.cn',
                    title: '中国新闻网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'http://paper.people.com.cn',
                    title: '人民日报'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://www.china.com.cn',
                    title: '中国网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.gov.cn',
                    title: '中国政府网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.china.com',
                    title: '中华网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.cnr.cn',
                    title: '央广网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.qq.com',
                    title: '央视网新闻'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.youth.cn',
                    title: '中国青年网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/fenghuang.png'),
                    url: 'http://www.ce.cn',
                    title: '中国经济网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/taobao.png'),
                    url: 'http://www.cankaoxiaoxi.com',
                    title: '参考消息'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'http://www.chinadaily.com.cn',
                    title: '中国日报网',
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tianmao.png'),
                    url: 'https://www.southcn.com/',
                    title: '南方网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/weipinhui.png'),
                    url: 'https://www.thepaper.cn/',
                    title: '澎湃新闻'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/dianshi.png'),
                    url: 'https://www.cri.cn',
                    title: '国际在线'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/yeyou.png'),
                    url: 'http://www.infzm.com/',
                    title: '南方周末'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/suningyigou.png'),
                    url: 'http://www.legaldaily.com.cn/',
                    title: '法制网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/suningyigou.png'),
                    url: 'http://www.cyol.net/',
                    title: '中青在线'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/toutiao.png'),
                    url: 'http://www.wenming.cn/',
                    title: '中国文明网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.wenweipo.com/',
                    title: '香港文汇网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.gmw.cn/',
                    title: '光明网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/douyin.png'),
                    url: 'http://www.takungpao.com/',
                    title: '大公网',
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/douyin.png'),
                    url: 'https://www.douyin.com',
                    title: '华商网',
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/aiqiyi.png'),
                    url: 'https://www.dahe.cn/',
                    title: '大河网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/youku.png'),
                    url: 'http://www.bjnews.com.cn/',
                    title: '新京报'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/bilibili.png'),
                    url: 'http://www.inewsweek.cn/',
                    title: '中国新闻周刊'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/dianshi.png'),
                    url: 'http://www.lifeweek.com.cn/',
                    title: '三联生活周刊'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huya.png'),
                    url: 'http://www.banyuetan.org/',
                    title: '半月谈 ',
                },
            ],
        ];
          const data2 = [
            // 第一行
              [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://tv.cctv.com',
                    title: '新闻1+1'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.crntt.com/',
                    title: '中评网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://bbs1.people.com.cn',
                    title: '强国社区'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://bbs.tianya.cn',
                    title: '天涯杂谈'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/souhu.png'),
                    url: 'http://www.ifengweekly.com/',
                    title: '凤凰周刊'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.guancha.cn/',
                    title: '观察者网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://bbs.tianya.cn',
                    title: '天涯国际观察 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://www.m4.cn/',
                    title: '四月网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://www.aisixiang.com/',
                    title: '爱思想 '
                },
            ],
        ];
           const data3 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.cnstock.com/',
                    title: '中国证券网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://cnfol.com/',
                    title: '中金在线'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://www.jrj.com.cn/',
                    title: '金融界'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.eastmoney.com/',
                    title: '东方财富'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/souhu.png'),
                    url: 'https://money.163.com/',
                    title: '网易财经'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.hexun.com/',
                    title: '和讯网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://finance.ifeng.com/',
                    title: '凤凰财经'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://finance.sina.com.cn/',
                    title: '新浪财经'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://new.qq.com/ch/finance/',
                    title: '腾讯财经'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/taobao.png'),
                    url: 'https://xueqiu.com/',
                    title: '雪球'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'http://www.ce.cn/',
                    title: '中国经济网',
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tianmao.png'),
                    url: 'https://www.caixin.com/',
                    title: '财新网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/weipinhui.png'),
                    url: 'https://www.10jqka.com.cn/',
                    title: '同花顺财经'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/dianshi.png'),
                    url: 'https://finance.chinairn.com/',
                    title: '中研财经'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/yeyou.png'),
                    url: 'http://business.sohu.com/',
                    title: '搜狐财经'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/yeyou.png'),
                    url: 'https://www.yicai.com/',
                    title: '第一财经'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/yeyou.png'),
                    url: 'https://www.cls.cn',
                    title: '财联社'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/yeyou.png'),
                    url: 'https://www.lanjinger.com/',
                    title: '蓝鲸财经'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/taobao.png'),
                    url: 'https://www.stockstar.com/',
                    title: '证券之星'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://www.p5w.net/',
                    title: '全景网',
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tianmao.png'),
                    url: 'https://www.cfi.cn/',
                    title: '中财网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/weipinhui.png'),
                    url: 'http://www.dyhjw.com/',
                    title: '第一黄金网'
                },
            ],
        ];
          const data4 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://sports.sina.com.cn',
                    title: '新浪体育'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://sports.163.com',
                    title: '网易体育'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://www.hupu.com',
                    title: '虎扑体育'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'http://sports.sohu.com/',
                    title: '搜狐体育'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/souhu.png'),
                    url: 'http://sports.pptv.com',
                    title: 'PPTV体育'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://sports.tom.com',
                    title: 'TOM体育'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://sports.qq.com/',
                    title: '腾讯体育'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.sports.cn/',
                    title: '华奥星空'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://zhibo8.cc/',
                    title: '直播吧'
                },
            ],
         ];
        const data = {
            //标题不能一样
            "新闻网站": data1,
            "时事评论": data2,
            "财经新闻": data3,
            "体育新闻": data4,
        }
        const title = [
            ["新闻网站",],
            ["时事评论",],
            ["财经新闻",],
            ["体育新闻",]
        ];
        commit('setMoreData', data)
        commit('setMoreTitle', title)
    },
    // 获取综合大站
    async getMoreALLData({commit}) {
        const data1 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'https://translate.google.cn/',
                    title: 'googel翻译'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'https://fanyi.youdao.com',
                    title: '有道翻译'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://fanyi.baidu.com/',
                    title: '百度翻译'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.iciba.com/',
                    title: '金山词霸'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.deepl.com/translator',
                    title: 'DeepL翻译'
                },
            ],
        ];
         const data2 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'https://www.callmysoft.com/jiami?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '文件夹加密'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'https://www.callmysoft.com/chongmingming?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '批量重命名'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.callmysoft.com/duoliao?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '金舟多聊'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/formatfactory?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '格式工厂'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/pdfzhuanhuan?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: 'PDF转换器'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/watermark?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '水印助手'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/yinpinzhuanhuan?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '音频格式转换'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/diannaoluyin?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '电脑录音软件'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/voice?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '音频人声分离'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/shipinzhuanhuan?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '视频格式转换'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/mv?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '电子相册'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/shipinfengehebing?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '视频大师'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/luping?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '录屏大师'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/pingguotouping?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '金舟投屏'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.callmysoft.com/subtitle-editor?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '视频字幕编辑'
                },
            ],
        ];
        const data = {
            //标题不能一样
            "在线翻译": data1,
            "金舟办公": data2,
        }
        const title = [
            ["在线翻译",],
            ["金舟办公",]
        ];
        commit('setMoreData', data)
        commit('setMoreTitle', title)
    },
    // 获取游戏大站
    async getMoreGameData({commit}) {
        const data1 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'https://www.17173.com/',
                    title: '17173游戏'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'https://new.qq.com/ch/games/',
                    title: '腾讯游戏'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://game.163.com/',
                    title: '网易游戏'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://games.sina.com.cn/',
                    title: '新浪游戏'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.gamersky.com/',
                    title: '游民星空'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.3dmgame.com/',
                    title: '3DM游戏网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.ali213.net/',
                    title: '游侠网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.duowan.com/',
                    title: '多玩游戏网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/fenghuang.png'),
                    url: 'https://www.kongzhong.com/',
                    title: '空中网'
                },
            ],
        ];
        const data2 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.4399.com/',
                    title: '4399小游戏'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.7k7k.com/',
                    title: '7k7k小游戏'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://xyx.hao123.com/',
                    title: 'hao123小游戏'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://seer.61.com/',
                    title: '淘米游戏'
                },
            ],
        ];
        const data3 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://wan.baidu.com/cover?gameId=24063258&idfrom=6044',
                    title: '原始传奇'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://wan.baidu.com/cover?gameId=24508850&idfrom=6044',
                    title: '百战沙城'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://wan.baidu.com/cover?gameId=24568999&idfrom=6044',
                    title: '谁是首富'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://wan.baidu.com/cover?gameId=23168162&idfrom=6044',
                    title: '三国群英传'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/souhu.png'),
                    url: 'https://wan.baidu.com/cover?gameId=24517022&idfrom=6044',
                    title: '笑傲江湖'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://wan.baidu.com/cover?gameId=24751171&idfrom=6044',
                    title: '天剑奇缘'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://wan.baidu.com/cover?gameId=24866805&idfrom=6044',
                    title: '苍空物语'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://wan.baidu.com/cover?gameId=19793595&idfrom=6044',
                    title: '三国杀'
                },
            ],
        ];
        const data4 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://dnf.qq.com/',
                    title: '地下城勇士'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://lol.qq.com/main.shtml',
                    title: '英雄联盟'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://speed.qq.com/',
                    title: 'QQ飞车'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://x5.qq.com/main.shtml',
                    title: 'QQ炫舞'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/souhu.png'),
                    url: 'https://dn.web.sdo.com/web11/home/',
                    title: '龙之谷'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://bns.qq.com/',
                    title: '剑灵'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://wd.gyyx.cn/',
                    title: '问道'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://nz.qq.com/',
                    title: '逆战'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://csol.tiancity.com/homepage/v6/',
                    title: '反恐精英'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://war3.uuu9.com/',
                    title: '魔兽世界'
                },
            ],
        ];
         const data5 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.cga.com.cn/',
                    title: '浩方电竞平台'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.5211game.com/',
                    title: '11对战平台'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.longzhu.com/v2/',
                    title: '龙珠直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.yy.com/',
                    title: 'YY直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/souhu.png'),
                    url: 'https://www.zhanqi.tv/',
                    title: '战旗直播'
                },
            ],
        ];
        const data = {
            //标题不能一样
            "游戏资讯": data1,
            "小游戏": data2,
            "网页游戏": data3,
            "网络游戏": data4,
            "电竞直播": data5,
        }
        const title = [
            ["游戏资讯",],
            ["小游戏", ],
            ["网页游戏",],
            ["网络游戏",],
            ["电竞直播",]
        ];
        commit('setMoreData', data)
        commit('setMoreTitle', title)
    },
    // 获取视频大站
    async getMoreVideoData({commit}) {
        const data1 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'https://www.douyu.com/',
                    title: '斗鱼直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'https://www.zhanqi.tv/',
                    title: '战旗直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.huya.com/',
                    title: '虎牙直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://cc.163.com/',
                    title: '网易CC直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.longzhu.com/',
                    title: '龙珠直播'
                },
              ],
           ];
        const data2 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.zhibo8.cc/',
                    title: '直播吧'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.zhuafan.co/v2/',
                    title: '抓饭直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://www.hbhmn.com/',
                    title: '黑白直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://live.qq.com/',
                    title: '企鹅直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/souhu.png'),
                    url: 'http://www.9qzb.com/',
                    title: '9球直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.longzhu.com/v2/',
                    title: '龙珠直播'
                },
              ],
           ];
        const data3 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.huya.com/',
                    title: '虎牙直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.yy.com/',
                    title: 'YY直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://live.baidu.com/',
                    title: '百度直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.huajiao.com/',
                    title: '花椒直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/souhu.png'),
                    url: 'https://www.inke.cn/',
                    title: '映客直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://cc.163.com/',
                    title: '网易CC直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.yizhibo.com/',
                    title: '一直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://union.2339.com/',
                    title: '么么直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.showself.com/',
                    title: '秀色直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/taobao.png'),
                    url: 'https://www.laifeng.com/',
                    title: '来疯直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://www.6.cn/',
                    title: '六间房',
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tianmao.png'),
                    url: 'https://www.kktv5.com/',
                    title: 'KK直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/weipinhui.png'),
                    url: 'https://fanxing.kugou.com/',
                    title: '酷狗直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/dianshi.png'),
                    url: 'https://qf.56.com/',
                    title: '千帆直播'
                },
            ],
        ];
            const data4 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.tvyan.com/',
                    title: '电视眼'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.tvquanqiu.com/',
                    title: '看全球'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://tv.haoqu99.com/',
                    title: '好趣网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://tv.cctv.com/live/',
                    title: 'CCTV节目'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://www.360live.cc/',
                    title: '爱看TV'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://tv.cztv.com/',
                    title: '中国蓝TV'
                },
                                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.sctv.com/watchTV',
                    title: '四川卫视直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.brtn.cn/btv/btvsy_index',
                    title: '北京卫视直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.gdtv.cn/tvChannelDetail/43',
                    title: '广东卫视直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/taobao.png'),
                    url: 'http://live.jstv.com/',
                    title: '江苏卫视直播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://www.sztv.com.cn/index.shtml',
                    title: '壹深圳',
                },
            ],
        ];
            const data5 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.wandou.pro/',
                    title: '豌豆免费影视'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.panghuys.com/',
                    title: '胖虎影视'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.lkvod.com/',
                    title: '来看点播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.qimaitv.com/',
                    title: '青麦TV'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.lkvod.com/',
                    title: '来看点播'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.dlidl.com/',
                    title: '嘀哩嘀哩'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://tv.lshang.cn/',
                    title: '视频在线解析'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://gaze.run/',
                    title: '注视'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://juhuang.tv/',
                    title: '剧荒TV影视'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/taobao.png'),
                    url: 'https://www.subaibaiys.com/',
                    title: '素白白影视'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://mv.lshang.cn/',
                    title: '乐尚TV',
                },
                                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://www.kkdy.live/',
                    title: '看看电影',
                },
                                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://www.ysjd.top/',
                    title: '影视基地',
                },
                                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://www.sztv.com.cn/index.shtml',
                    title: '壹深圳',
                },
                                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://www.j9yy.top/',
                    title: '九九影院',
                },
                                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://chnnetflix.com/',
                    title: '奈飞中文影视',
                },
                                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://www.88tvs.cc/',
                    title: '88影视网',
                },
            ],
        ];
            const data6 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.fqfun.com/',
                    title: '番茄动漫'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.silisili.tv/',
                    title: '嘶哩嘶哩'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://www.zzzfun.vip/',
                    title: '动漫视频网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.acfun.cn/',
                    title: 'AcFun'
                },
            ],
        ];
            const data7 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.23qb.net/',
                    title: '铅笔小说'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.boyunso.com/',
                    title: '拨云搜索'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.52shuku.vip/',
                    title: '52书库'
                },
                {      
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://book.sfacg.com/',
                    title: 'SF轻小说'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.linovel.net/',
                    title: '轻之文库小说'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.x33xs.com/',
                    title: '33小说网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.xuges.com/',
                    title: '虚阁上'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.lightnovel.us/cn/',
                    title: '轻之国度'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.zei8.vip/',
                    title: '贼吧网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://zxcs.me/',
                    title: '知轩藏书'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.aixiaoshuo.com/',
                    title: '爱小说搜索'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.zhulang.com/',
                    title: '逐浪网小说'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.51shucheng.net/',
                    title: '无忧书城'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://b.faloo.com/',
                    title: '飞卢小说网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.xiaoshuohui.com.cn/',
                    title: '小说会'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.qingting.fm/',
                    title: '蜻蜓FM'
                },
                                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.lizhi.fm/',
                    title: '荔枝FM'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.ihuaben.com/',
                    title: '话本小说网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://chuangshi.qq.com/',
                    title: '创世中文网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.readnovel.com/',
                    title: '小说阅读网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.17k.com/',
                    title: '17k小说网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.qdmm.com/',
                    title: '起点女生网'
                },
  
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.xxsy.net/',
                    title: '潇湘书院'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.laikan.com/index',
                    title: '磨铁中文网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://www.zongheng.com/',
                    title: '纵横中文网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.qidian.com/',
                    title: '起点中文网'
                },
            ],
        ];
           const data8 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.yeitu.com/meinv/',
                    title: '亿图全景图库'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.mmm131.com/',
                    title: 'MM美女图片'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://pic.yesky.com/',
                    title: '天极图片'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://huaban.com/favorite/beauty',
                    title: '花瓣美女'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.cct58.com/tuku/',
                    title: 'CCT58明星搜网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.gogo51.com/',
                    title: 'GOGO图库网'
                },
            ],
        ];
           const data9 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://y.qq.com/',
                    title: 'QQ音乐'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://music.163.com/',
                    title: '网易云音乐'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.kugou.com/',
                    title: '酷狗音乐'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://music.douban.com/',
                    title: '豆瓣音乐'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://bailemi.com/',
                    title: '百乐米'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://biu.moe/#/Index/home',
                    title: 'Biu'
                },
                       {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.luoow.com/',
                    title: '落网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://neal.fun/ambient-chaos/',
                    title: '白噪音'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://5sing.kugou.com/index.html',
                    title: '5SING'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://music.migu.cn/v3',
                    title: '咪咕音乐网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://music.91q.com/',
                    title: '千千音乐'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.vvvdj.com/',
                    title: '清风DJ音乐'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://www.ik123301.com/',
                    title: 'DJ总站'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://www.72dj.com/',
                    title: '72DJ舞曲网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://sao.fm/',
                    title: '思奥FM'
                },
            ],
        ];
        const data = {
            //标题不能一样
            "游戏直播": data1,
            "赛事直播": data2,
            "娱乐直播": data3,
            "电视直播": data4,
            "影视网站": data5,
            "动漫网站": data6,
            "小说网站": data7,
            "美图欣赏": data8,
            "在线音乐": data9,

        }
        const title = [
            ["游戏直播",],
            ["赛事直播",],
            ["娱乐直播",], 
            ["电视直播",], 
            ["影视网站",], 
            ["动漫网站",], 
            ["小说网站",],
            ["美图欣赏",],
            ["在线音乐",]
        ];
        commit('setMoreData', data)
        commit('setMoreTitle', title)
    },
    // 获取生活大站
    async getMoreLiveData({commit}) {
        const data1 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'https://www.ctrip.com/?AllianceID=1094590&sid=2220294&ouid=&app=0101F00',
                    title: '携程旅行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'https://www.fliggy.com/',
                    title: '飞猪'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.ly.com/?refid=447819135',
                    title: '同程旅行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.qunar.com/',
                    title: '去哪儿'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.lvmama.com/',
                    title: '驴妈妈旅游'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.tuniu.com/',
                    title: '途牛'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.booking.com/',
                    title: '缤客'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.xiaozhu.com/',
                    title: '小猪民宿'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/fenghuang.png'),
                    url: 'https://www.airbnb.cn/',
                    title: 'Airbnb'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/fenghuang.png'),
                    url: 'https://www.qyer.com/',
                    title: '穷游'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/taobao.png'),
                    url: 'https://www.mafengwo.cn/',
                    title: '马蜂窝'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://www.elong.com/',
                    title: '艺龙旅行网',
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tianmao.png'),
                    url: 'https://www.youxiake.com/',
                    title: '游侠客旅行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/weipinhui.png'),
                    url: 'http://www.aoyou.com/',
                    title: '中青旅遨游网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/dianshi.png'),
                    url: 'https://bbs.8264.com/',
                    title: '驴友论坛'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/yeyou.png'),
                    url: 'https://www.tujia.com/',
                    title: '途家网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/suningyigou.png'),
                    url: 'http://www.dazijia.com/',
                    title: '大自驾网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/suningyigou.png'),
                    url: 'http://www.dili360.com/',
                    title: '中国国家地理'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/toutiao.png'),
                    url: 'https://visalist.io/',
                    title: '签证查询'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.zhuna.cn/',
                    title: '住哪儿网'
                },
            ],
        ];
        const data2 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.nowcoder.com/',
                    title: '牛客网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.51job.com/',
                    title: '前程无忧'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.zhaopin.com/',
                    title: '智联招聘'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.zhipin.com/',
                    title: 'BOSS直聘'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/souhu.png'),
                    url: 'https://www.shixiseng.com',
                    title: '实习僧'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.txgc.com/',
                    title: '甜薪工场 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://leetcode.cn/',
                    title: '力扣网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.yingjiesheng.com/',
                    title: '应届生求职网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.yjbys.com/',
                    title: '应届毕业生网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.jobui.com/',
                    title: '职友集 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.job592.com/',
                    title: '职朋 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://maimai.cn/',
                    title: '脉脉 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.linkedin.cn/incareer/hp/',
                    title: '领英 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.chinahr.com/',
                    title: '中华英才网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.kanzhun.com/',
                    title: '看准网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.liepin.com/',
                    title: '猎聘 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.1010jz.com/',
                    title: '1010兼职网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.jiaoshi.com.cn/',
                    title: '教师人才网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://www.138job.com/',
                    title: '大美业人才网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://www.pjob.net/',
                    title: '印刷人才网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://www.doctorjob.com.cn/',
                    title: '医疗人才网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://www.buildjob.net/',
                    title: '建筑人才网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://hr.bjx.com.cn/',
                    title: '北极星招聘'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://www.job1001.com/',
                    title: '一览英才网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.zhaojiao.net/',
                    title: '招教网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://www.gaoxiaojob.com/',
                    title: '高校人才网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.jobeast.com/',
                    title: '外语人才网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.job910.com/',
                    title: '教师招聘网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://chinajob.mohrss.gov.cn/',
                    title: '中国就业网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://58.com',
                    title: '58同城'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://www.carjob.com.cn/',
                    title: '汽车人才网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://ganji.com/',
                    title: '赶集网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.shoeshr.com/',
                    title: '鞋业人才网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.jobmd.cn/',
                    title: '丁香人才网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.cfw.cn/',
                    title: '服装人才网 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.21wecan.com/',
                    title: '中国卫生人才'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.800hr.com/',
                    title: '英才网联 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.veryeast.cn/',
                    title: '最佳东方'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.cdpee.org.cn/',
                    title: '残疾人就业'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.lagou.com/',
                    title: '拉勾招聘 '
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.cjol.com/',
                    title: '人才热线'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.jobcn.com/',
                    title: '卓博人才网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://www.utrainee.com/',
                    title: '职徒'
                },
            ],
        ];
        const data3 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://lianjia.com/',
                    title: '链家网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://ke.com/',
                    title: '贝壳找房'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://bj.5i5j.com/',
                    title: '我爱我家'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://anjuke.com/',
                    title: '安居客'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/souhu.png'),
                    url: 'https://www.uoolu.com/',
                    title: '有路网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.ziroom.com/',
                    title: '自如'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'http://www.leju.com/',
                    title: '乐居房产网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://house.focus.cn/',
                    title: '搜狐焦点'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://house.qq.com/',
                    title: '腾讯房产'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/taobao.png'),
                    url: 'https://www.fangdd.com/',
                    title: '房多多'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://fang.com/',
                    title: '房天下',
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tianmao.png'),
                    url: 'https://58.com/',
                    title: '58同城'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/weipinhui.png'),
                    url: 'https://ganji.com/',
                    title: '赶集网'
                },
            ],
        ];
        const data4 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.dongchedi.com/',
                    title: '懂车帝'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.yoojia.com/',
                    title: '有驾'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.xincheping.com/',
                    title: '新车评'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.taoche.com/',
                    title: '淘车网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://2sc.sohu.com/',
                    title: '搜狐二手车'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.che168.com/',
                    title: '二手车之家'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.58che.com/',
                    title: '58汽车'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://auto.sohu.com/',
                    title: '搜狐汽车'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://auto.sina.com.cn/',
                    title: '新浪汽车'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://auto.163.com/',
                    title: '网易汽车'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://bbs.fblife.com/forum.php',
                    title: '越野e族论坛'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.xcar.com.cn/',
                    title: '爱卡汽车'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.guazi.com/',
                    title: '瓜子二手车'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.xin.com/',
                    title: '优信二手车'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.renrenche.com/',
                    title: '人人车'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.yiche.com/',
                    title: '易车'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.pcauto.com.cn/',
                    title: '太平洋汽车网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.autohome.com.cn/',
                    title: '汽车之家'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://auto.sina.com.cn/zhishu/',
                    title: '新浪汽车排行榜'
                },
            ],
        ];
                const data5 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://sspai.com/',
                    title: '少数派'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.feng.com/',
                    title: '威锋'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.gfan.com/',
                    title: '机锋网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.pingce.net/',
                    title: '大米评测'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.geekpark.net/',
                    title: '极客公园'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.ifanr.com/',
                    title: '爱范儿'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://shuma.jd.com/',
                    title: '京东数码'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.chiphell.com/',
                    title: 'Chiphell'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.dgtle.com/',
                    title: '数字尾巴'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.zealer.com/',
                    title: 'ZEALER'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.ithome.com/',
                    title: 'IT之家'
                },               
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.pconline.com.cn/',
                    title: '太平洋电脑网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.zol.com.cn/',
                    title: '中关村在线'
                },
            ],
        ];
                const data6 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://mail.qq.com',
                    title: 'QQ邮箱'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://mail.google.com/',
                    title: 'Gmail'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://mail.163.com/',
                    title: '163网易邮箱'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://work.weixin.qq.com/mail/',
                    title: '腾讯企业邮箱'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://mail.126.com/',
                    title: '126邮箱'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://mail.sina.com.cn/',
                    title: '新浪邮箱'
                },
            ],
        ];
                const data7 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.goofish.com/',
                    title: '闲鱼'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.zhuanzhuan.com/',
                    title: '转转'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://2.jd.com/',
                    title: '京东拍拍二手'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.duozhuayu.com/book',
                    title: '多抓鱼'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.feiyu.live/',
                    title: '妃鱼'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.kongfz.com/',
                    title: '孔夫子旧书网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.youlu.net/',
                    title: '有路网'
                },              
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.goshare2.com/portal/',
                    title: '只二App'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://ponhu.cn/',
                    title: '胖虎奢侈品'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.ibaodashi.com/',
                    title: '包大师'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.zhaoliangji.com/',
                    title: '找靓机'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://sf.taobao.com/',
                    title: '阿里拍卖'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://auction.jd.com/home.html',
                    title: '京东拍卖'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://paimai.caa123.org.cn/',
                    title: '中拍平台'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.gpai.net/sf/',
                    title: '公拍网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.360pai.com/',
                    title: '360PAI.COM'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.rmfysszc.gov.cn/',
                    title: '人民法院诉讼资产网'
                },
            ],
        ];
                const data8 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.clady.cn/',
                    title: '中国婚恋网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.soulapp.cn/',
                    title: 'Soul'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://tantanapp.com/',
                    title: '探探'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.immomo.com/',
                    title: '陌陌'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://yuehui.163.com/',
                    title: '同城约会'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://love.163.com/',
                    title: '花田'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.baihe.com/',
                    title: '百合网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.zhenai.com/',
                    title: '珍爱网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.jiayuan.com/',
                    title: '世纪佳缘'
                },
            ],
        ];
                const data9 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://ai.12348.gov.cn/pc/',
                    title: '中国法律服务网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.110.com/',
                    title: '110法律咨询网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://china.findlaw.cn/ask/',
                    title: '找法网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.64365.com/',
                    title: '律图'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.66law.cn/question/',
                    title: '华律网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.lawtime.cn/ask/',
                    title: '法律快车'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://flk.npc.gov.cn/',
                    title: '国家法律法规数据库'
                },
            ],
        ];
                const data10 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.3d66.com/',
                    title: '3D溜溜网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.moxingyun.com/',
                    title: '模型云'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.om.cn/',
                    title: '欧模网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://bbs.zhulong.com/',
                    title: '筑龙论坛'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://huaban.com/favorite/home',
                    title: '花瓣网家装'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.mati.hk/',
                    title: '马蹄室内设计'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.wonadea.com/',
                    title: '达人室内设计'
                }, 
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.co188.com/',
                    title: '土木在线网'
                }, 
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.ddove.com/',
                    title: '定鼎网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.gooood.cn/',
                    title: '谷德设计网'
                }, 
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.sketchupbar.com/',
                    title: 'SketchUp'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.znzmo.com/',
                    title: '知末网'
                }, 
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.justeasy.cn/',
                    title: '建E网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.mysearchome.cn/',
                    title: 'MyHome设计家'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.vsszan.com/',
                    title: '序赞网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.cool-de.com/portal.php',
                    title: '室内设计联盟'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.tuozhe8.com/',
                    title: '拓者设计吧'
                },
            ],
        ];
                const data11 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.eeff.net/',
                    title: '穿针引线网'
                },
               {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://door11.com/',
                    title: 'door11'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://theimpression.com/',
                    title: 'IMPRESSION'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://fuckingyoung.es/',
                    title: 'FuckingYoung'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://adamkatzsinding.com/',
                    title: 'AdamKatz'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.textures.com/',
                    title: 'Textures'
                },
            ],
        ];
                const data12 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.design006.com/invite?code=SKIALGGM',
                    title: '享设计'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.cg99.com/',
                    title: 'CG99设计网'
                },                
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.capablist.com/',
                    title: '领贤网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.meiye.art/',
                    title: '美叶'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://meigongyun.com/',
                    title: '美工云'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.psjia.com/',
                    title: 'PS家园网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.xuexiniu.com/',
                    title: '学犀牛'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.xiusheji.com/',
                    title: '秀设计'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.sheboo.com/',
                    title: '设宝网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.iamxk.com/',
                    title: '设计小咖'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.fxysw.com/',
                    title: '分型艺术网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://ycg.qq.com/',
                    title: '腾讯原创馆'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.gracg.com/',
                    title: '涂鸦王国'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.gtn9.com/index.aspx',
                    title: '古田路9号'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.ycyui.com/',
                    title: '优创意'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.huashi6.com/',
                    title: '触站'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://arting365.com/',
                    title: 'Arting365'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.logonews.cn/',
                    title: '标志情报局'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.iguoguo.net/',
                    title: '爱果果'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.shejidaren.com/',
                    title: '设计达人'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.tuyiyi.com/',
                    title: '图翼网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.sj33.cn/',
                    title: '设计之家'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://ishijue.com/',
                    title: '爱视觉'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.uisdc.com/',
                    title: '优设网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.designboom.cn/',
                    title: '设计邦'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://design.maliquankai.com/',
                    title: '码力全开资源'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.bossdesign.cn/',
                    title: 'Boss设计'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.creativeboom.com/',
                    title: 'CreativeBoom'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.fevte.com/',
                    title: '飞特网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.ui.cn/',
                    title: 'UI中国'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.qijishow.com/',
                    title: '奇迹秀'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.shejipi.com/',
                    title: '设计癖'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.91sotu.com/',
                    title: '搜图导航'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.zcool.com.cn/',
                    title: '站酷'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://idesign.qq.com/#!index/feed',
                    title: '腾讯设计导航'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://hao.uisdc.com/',
                    title: '优设导航'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://artand.cn/mobile',
                    title: 'Artand'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://huaban.com/',
                    title: '花瓣网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://ollymoss.com/',
                    title: 'OllyMoss'
                },
            ],
        ];
                const data13 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.sf-express.com/chn/sc',
                    title: '顺丰速运'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.sto.cn/',
                    title: '申通快递'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.yto.net.cn/',
                    title: '圆通速递'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.ttkdex.com/',
                    title: '天天快递'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.yundaex.com/cn/index.php',
                    title: '韵达速递'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.guoguo-app.com/',
                    title: '菜鸟裹裹'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.ems.com.cn/',
                    title: '中国邮政'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.zto.com/',
                    title: '中通快递'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.800bestex.com/',
                    title: '百世快递'
                },
            ],
        ];
                const data14 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.icbc.com.cn/',
                    title: '工商银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.ccb.com/cn/home/indexv3.html',
                    title: '建设银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.boc.cn/',
                    title: '中国银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.bankcomm.com/BankCommSite/default.shtml',
                    title: '交通银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.abchina.com/cn/',
                    title: '中国农业银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.cmbchina.com/',
                    title: '招商银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.cebbank.com/',
                    title: '中国光大银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.cmbc.com.cn/',
                    title: '中国民生银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://bank.pingan.com/',
                    title: '平安银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.spdb.com.cn/',
                    title: '浦发银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://bank.ecitic.com/',
                    title: '中信银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.cib.com.cn/cn/index.html',
                    title: '兴业银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.hxb.com.cn/index.shtml',
                    title: '华夏银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.cgbchina.com.cn/',
                    title: '广发银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.pbc.gov.cn/',
                    title: '中国人民银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'http://www.cbirc.gov.cn/cn/view/pages/index/index.html',
                    title: '银监会'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://cn.unionpay.com/upowhtml/cn/templates/index/index.html',
                    title: '中国银联'
                },  
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.cdb.com.cn/',
                    title: '国家开发银行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.adbc.com.cn/',
                    title: '中国农发行'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.visa.cn/',
                    title: 'visa官网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.jcbcard.cn/',
                    title: 'JCB卡'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.pbccrc.org.cn/',
                    title: '征信中心'
                },
            ],
        ];
                const data15 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://portal.dxy.cn/',
                    title: '丁香园'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.chunyuyisheng.com/',
                    title: '春雨医生'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.99.com.cn/',
                    title: '99健康网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.39.net/',
                    title: '39健康网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.haodf.com/',
                    title: '好大夫在线'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.xywy.com/',
                    title: '寻医问药'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.guahao.com/',
                    title: '微医'
                },
            ],
        ];
                const data16 = [
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.meishichina.com/',
                    title: '美食天下'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.xiachufang.com/',
                    title: '下厨房'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.meishij.net/',
                    title: '美食杰'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.douguo.com/',
                    title: '豆果美食'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'https://www.xiangha.com/',
                    title: '香哈网'
                },
            ],
        ];
        const data = {
            //标题不能一样
            "旅游出行": data1,
            "求职招聘": data2,
            "房屋租售": data3,
            "汽车": data4,
             "数码": data5, 
             "常用邮箱": data6,
             "二手交易": data7,
             "婚恋交友": data8,
             "法律咨询": data9,
             "室内设计": data10,
             "服装设计": data11,
             "设计创意": data12,
             "快递": data13,
             "银行": data14,
             "健康": data15,
             "菜谱": data16,
        }
        const title = [
            ["旅游出行",],
            ["求职招聘",],
            ["房屋租售",],
            ["汽车",],
            ["数码",],
            ["常用邮箱",],
            ["二手交易",],
            ["婚恋交友",],
            ["法律咨询",],
            ["室内设计",],
            ["服装设计",],
            ["设计创意",],
            ["快递",],
            ["银行",],
            ["菜谱",],
            ["健康",]
        ];
        commit('setMoreData', data)
        commit('setMoreTitle', title)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
