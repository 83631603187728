<template>
  <div>
    <SectionHead v-bind="headConfig"/>
    <div class="card-body">
      <div class="list-grid my-n2" v-for="item in newsData" :key="item.id">
        <div class="list-item py-2">
          <div class="media media-3x2 rounded col-4 mr-3">
            <a target="_blank" class="media-content" :href="item.url" :data-src="item.pic" data-was-processed="true"
               :style="'background-image: url('+item.pic+');'"></a>
          </div>
          <div class="list-content py-0">
            <div class="list-body">
              <a target="_blank" :href="item.url" class="list-title overflowClip_2" rel="bookmark">{{ item.title }}</a>
            </div>
            <div class="list-footer">
              <div class="d-flex flex-fill text-muted text-xs">
                <time class="d-inline-block">{{ timeToString(item.create_time) }}</time>
                <div class="flex-fill"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHead from "./SectionHead";
import {timeToString} from "@/utils";
import request from "@/utils/request";
// const playIcon = require("@/assets/images/rightbox/play.png");

export default {
  name: "HotTv",
  components: {
    SectionHead,
  },
  data() {
    return {
      // playIcon,
      headConfig: this.$store.state.leftbox_tv.head,
      newsData:[],
    };
  },
  mounted() {
    // //console.log("this.newsData", this.newsData)
    this.getNewsData();
  },
  methods: {
    timeToString,
    async getNewsData(){
      const res = await request.get('/news?page=1&type=社会')
      this.newsData = res.data.data.splice(0,6);
      for(let i=0; i < this.newsData.length; i ++){
        let item = this.newsData[i];
        item.title = item.title.slice(0,20)
      }
      //console.log("this.newsData", this.newsData);
    }
  }
};
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  /*padding: 1rem;*/
  padding: 13px 15px 15px;
}

.list-grid .list-item {
  -ms-flex-direction: row;
  flex-direction: row
}

.list-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}

.my-n2 {
  margin-top: -0.5rem !important
}

.py-2 {
  padding-bottom: 0.5rem !important;
}

.media {
  position: relative;
  width: 90px;
  height: 66px;
  display: block;
  overflow: hidden;
  padding: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.media-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0;
  border-radius: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: rgba(120, 120, 120, .1);
}

.d-inline-block {
  margin-top: 15px;
}
</style>
