<template>
  <div class="head">
    <div class="divide"></div>
    <div class="con clearfix">
      <div class="con-left">
<!--        <img-->
<!--          :src="icon"-->
<!--          alt="图片"-->
<!--        />-->
        <span>&nbsp; <a :href="link" target="_blank">{{title}}</a></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionHead",
  props: ['link', 'icon', 'title']
};
</script>

<style scoped>
.divide {
  width: 100px;
  height: 1px;
  margin: 0 auto;
  background: #f4f4f4;
}
.con {
  margin-top: 6px;
  margin-left: 15px;
  margin-bottom: 15px;
  position: relative;
}
.con .con-left {
  line-height: 22px;
  font-size: 15px;
  color: #222222;
  font-weight: 600;
  float: left;
  font-family: MicrosoftYaHei-Bold;
  max-width: 190px;
  height: 22px;
  overflow: hidden;
}
.con .con-left img {
  width: 22px;
  height: 22px;
  border: 0;
}
.con .con-left svg {
  vertical-align: -6px;
  width: 22px;
  height: 22px;
}
.con .con-right {
  float: right;
}
</style>
