<template>
  <li class="xxl-news-ppt">
    <div class="ppt-left">
      <Swiper @change="onSwiperChange"></Swiper>
    </div>

    <ul class="ppt-right">
      <li class="ppt-item" v-for="(item, index) in topNewsData.caroucel" :key="item.id">
        <a
          :href="item.url"
          target="_blank"
          class="ppt-title bold"
          :class="[index == activeIndex ? 'carouse_red': '']"
        >
          {{item.title}}
        </a>
      </li>
    </ul>
  </li>
</template>

<script>
import Swiper from './Swiper';
import { newsDataState } from '@/mixins';

export default {
  name: 'Carousel',
  mixins: [newsDataState],
  components: {
    Swiper
  },
  data() {
    return {
      activeIndex: 2,
    }
  },
  methods: {
    onSwiperChange(activeIndex) {
      //console.log("onSwiperChange activeIndex", activeIndex)
      // 貌似是swiper的bug，index不太对，处理下
      if(activeIndex > this.topNewsData.caroucel.length) {
        this.activeIndex = 0
      } else if (activeIndex <= 0) {
        this.activeIndex = this.topNewsData.caroucel.length - 1
      } else {
        this.activeIndex = activeIndex - 1
      }
    },
  }
};
</script>

<style>
.carouse_red {
  color: red !important;
}
</style>
