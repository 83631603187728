const state = {
    head:{
        icon: "https://www.ctrip.com/favicon.ico",
        link: 'http://baoku.360.cn/',
        title: '常用办公软件'
    },
    apps:[
        [
            {
                link:"https://down.haowangkj.com/123inst_26.exe",
                img:require('@/assets/images/soft/123jiasu.png'),
                title:"123加速浏览器",
                key: 1,
            },
            {
                link:"https://f63b34172e1de592636bcd721fab82ae.rdt.tfogc.com:49156/dldir1.qq.com/weixin/Windows/WeChatSetup_x86.exe?mkey=64bfd205e6111efcfc21b7fc7298309a&arrive_key=20948342093&cip=111.29.131.80&proto=https",
                img:require('@/assets/images/soft/weixin.png'),
                title:"微信",
                key: 1,
            },
        ],
        [
            {
                link:"https://voice-cdn.shouji.sogou.com/wapdl/hole/202103/19/sogou_pinyin_101a.exe",
                img:require('@/assets/images/soft/sougou.png'),
                title:"搜狗输入法",
                key: 1,
            },
            {
                link:"https://dldir1.qq.com/qqfile/qq/PCQQ9.7.3/QQ9.7.3.28946.exe",
                img:require('@/assets/images/soft/QQ.png'),
                title:"QQ",
                key: 1,
            },
         ],
         [
         {
                link:"https://7-zip.org/a/7z2301.exe",
                img:require('@/assets/images/soft/7Z.png'),
                title:"7-Zip",
                key: 1,
            },
            {
                link:"https://pc-package.wpscdn.cn/wps/download/W.P.S.50.513.exe",
                img:require('@/assets/images/soft/WPS.png'),
                title:"WPS",
                key: 1,
            },      
            ],
           [
            {
                link:"https://downmini.yun.kugou.com/web/kugou_10193.exe",
                img:require('@/assets/images/soft/kugou.png'),
                title:"酷狗音乐",
                key: 1,
            },
            {
                link:"https://updatecdn.meeting.qq.com/cos/10335c07a804e37b63e2b17b6c880010/TencentMeeting_0300000000_3.18.5.411.publish.officialwebsite.exe",
                img:require('@/assets/images/soft/tengxunhuiyi.png'),
                title:"腾讯会议",
                key: 1,
            },
        ],
        [
            {
                link:"https://down.haowangkj.com/zhilesetup_26.exe",
                img:require('@/assets/images/soft/zhile.png'),
                title:"知了浏览器",
                key: 1,
            },
            {
                link:"https://down.oray.com/sunlogin/windows/SunloginClient_13.3.1.56398.exe",
                img:require('@/assets/images/soft/xiangrikui.png'),
                title:"向日葵远程控制",
                key: 1,
            },
        ]
    ],
}
const mutations = {
    setRightBoxNewsData (state, data) {
        state.data = data
    },
}
const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
