import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState('infoflow',['newsData', 'topNewsData', 'flowLoading']),
    ...mapState('rightbox_news',['tab_data']),
    ...mapState('custom_nav',['nav_data']),
    ...mapState('rightbox_hotbuy',['hot_buy_tabs_data','hot_buy_tmail','hot_buy_taobao','hot_buy_good','hot_buy_sale','hot_buy_hongbao']),
  },
  methods: {
    ...mapActions('infoflow',['getTopNews', 'addNewsData', 'clearNewsData', 'setFlowLoading']),
    ...mapActions('commonuse',['getCommonData']),
    ...mapActions('more',['getMoreData','getMoreALLData','getMoreGameData','getMoreVideoData','getMoreLiveData','getMoreBusinessData']),
    ...mapActions('linklist',['getLinklistData']),
    ...mapActions('test',['test']),
    ...mapMutations('infoflow',['setFlowLoading','addPage','setType','setPage']),
    ...mapMutations('rightbox_news',['setRightBoxNewsData']),
  }
}
