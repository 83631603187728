const state = {
    head:{
        icon: "https://www.ctrip.com/favicon.ico",
        link: 'https://www.ctrip.com/',
        title: '热门软件'
    },
    data: [
        {
            link: "https://ruanjian.2345.cc/nav/?id=99041",
            imgUrl:
                "//www-stream.2345cdn.net/dhrest/upload/2022/54baad8af8b7b26e4ae7fb6238ea7941.png",
            title: "爱奇艺1",
        },
        {
            link: "https://ruanjian.2345.cc/nav/?id=60897",
            imgUrl: "//www-stream.2345cdn.net/dhrest/upload/2022/ad3d15d5ae13bc2d7af63b6ac2e2f18c.png",
            title: "安全卫士1",
        },
        {
            link: "https://ruanjian.2345.cc/nav/?id=33359",
            imgUrl: "//www-stream.2345cdn.net/dhrest/upload/2022/be1e74020199f59abf0ab19cc2bbccea.png",
            title: "芒果tv1",
        },
        {
            link: "https://ruanjian.2345.cc/nav/?id=98718",
            imgUrl: "//www-stream.2345cdn.net/dhrest/upload/2022/f447f0bf96ece9034f2d73d2506a0f2b.png",
            title: "模拟器1",
        },
        {
            link: "https://ruanjian.2345.cc/nav/?id=99118",
            imgUrl: "//www-stream.2345cdn.net/dhrest/upload/2022/f29505dcafff93f700ae8f6b026278f7.png",
            title: "浏览器1",
        },
        {
            link: "https://ruanjian.2345.cc/nav/?id=38097",
            imgUrl: "//www-stream.2345cdn.net/dhrest/upload/2022/c2a75369149ccfec5ca9aeeb16e85561.png",
            title: "迅雷1",
        },
        {
            link: "https://ruanjian.2345.cc/nav/?id=38126",
            imgUrl: "//www-stream.2345cdn.net/dhrest/upload/2022/8f5e79eb89b05812682eb34ff44c9943.png",
            title: "驱动人生1",
        },
        {
            link: "https://ruanjian.2345.cc/nav/?id=99042",
            imgUrl: "//www-stream.2345cdn.net/dhrest/upload/2022/df2cadab0f1c5c4cbb46c7d8deca4004.png",
            title: "播放器1",
        },
    ],
}
const mutations = {
    setRightBoxNewsData (state, data) {
        state.data = data
    },
}
const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
