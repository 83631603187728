import request from "@/utils/request";

const state = {
    topNewsData: {caroucel: []}, // 头条
    newsData: [], // 信息流
    flowLoading: true, // 是否正在加载信息流
    page: 1,
    type: "热点",
}
const mutations = {
    setTopNewsData(state, data) {
        state.topNewsData = data
    },
    setNewsData(state, data){
      state.newsData = data
    },
    addNewsData(state, data) {
        state.newsData = state.newsData.concat(data)
    },
    clearNewsData(state) {
        state.newsData = []
    },
    setFlowLoading(state, data) {
        state.flowLoading = data
    },
    addPage(state) {
        state.page += 1
    },
    setPage(state, page) {
        state.page = page
    },
    setType(state, type) {
        state.type = type
    }
}
const actions = {
    // 获取头条
    async getTopNews({commit}) {
        commit('setFlowLoading', true)

        const res = await request.get('/news?page=' + state.page + '&type=' + state.type)
        let data = {}
        data.caroucel = res.data.data.splice(0, 6)
        commit('setTopNewsData', data)

        // //console.log('getTopNews...', data)
        // mock
        // const data = {
        //     // 轮播数据
        //     caroucel: [
        //         {
        //             id: 1,
        //             link: "https://news.jxcn.cn/p/20220921/37681807.html?from=jx35",
        //             imgUrl: "https://imgcdn.toutiaoyule.com/20220921/bacb70e497d65ecb02cac7755f910fbc.jpg",
        //             title: "拜登警告普京：若敢使用核武器，就让你们变回最“贱民”时代",
        //         },
        //     ]
        // }
        let dd = res.data.data
        commit('setNewsData', dd)
        commit('setFlowLoading', false)

    },

    // 获取信息流新闻
    async addNewsData({commit}) {
        if (state.page === 1) {
            return actions.getTopNews({commit})
        }
        //console.log('addNewsData...')
        const res = await request.get('/news?page=' + state.page + '&type=' + state.type)
        commit('setFlowLoading', true)
        let data = res.data.data
        //console.log('addNewsData...', data)
        // mock. api获取
        // const data = [
        //     {
        //         id: 1,
        //         type: 2,
        //         typeText: '娱乐',
        //         source: '大众网',
        //         time: '2天前',
        //         link: "https://kan.china.com/click/5432xf/?aid=1663750&d=20220923",
        //         imgUrl: "//dfzximg02.dftoutiao.com/minimodify/20220918/591x800_632698de28d61_mwpm_03200403.jpeg?qid=04522",
        //         title: "安徽女子二婚嫁给初恋，生三胎后受宠变得更漂亮，这次遇到真爱了",
        //         subTitle: "女子经历过一次失败的婚姻，在二婚的时候，毅然选择嫁给自己每一次谈恋爱的那名男生，在生下三胎后，因为得到丈夫的宠爱而变得更加漂亮，她这次是真正遇到了合适的人。 女子也像很多",
        //     },
        // ]
        if (data && data.length > 0) {
            commit('addNewsData', data)
            commit('setFlowLoading', false)
        } else {
            commit('setFlowLoading', true)
        }
    },
    clearNewsData({commit}) {
        commit('clearNewsData')
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
