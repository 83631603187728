<template>
  <div class="panel card">
    <div class="card-body">
      <div class="panel-header mb-4"><h1 class="h3">广告合作 </h1></div>
      <div class="panel-body mt-2"><p>如有合作意向，请添加QQ:3467926156联系站长沟通。</p></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Contact",
  components: {},

  data() {
    return {};
  },

  created() {
  },

  methods: {},
};
</script>

<style scoped lang="less">
.card {
  background: #fff;
  border-width: 0;
  margin: 5rem;
  box-shadow: 0px 0px 20px -5px rgb(158 158 158 / 20%);
  transition: background-color .3s;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.panel-body p {
  margin-bottom: 1.25rem;
  font-size: 1rem;
  line-height: 2;
  word-wrap: break-word;
}
</style>
