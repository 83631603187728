import _ from "lodash";

export const storage = {
    get(key) {
        const val = localStorage.getItem(key)
        try {
            return JSON.parse(val)
        } catch (error) {
            return val
        }
    },
    set(key, val) {
        try {
            const _val = JSON.stringify(val)
            localStorage.setItem(key, _val)
        } catch (error) {
            localStorage.setItem(key, val)
        }
    }
}

// 判断某节点是否在视野中
export const isInViePortOfOne = (element) => {
    const viewWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewHeight = window.innerHeight || document.documentElement.clientHeight;
    const {
        top,
        right,
        bottom,
        left,
    } = element.getBoundingClientRect();

    return (
        top >= 0 &&
        left >= 0 &&
        right <= viewWidth &&
        bottom <= viewHeight
    );
}


export function setHistory(item) {
    // //console.log("setHistory item", item);
    let val = storage.get("mine_history") || [];
    val.unshift(item);

    let newHistory = _.uniqBy(val, (item) => item.href);
    if (newHistory.length > 14) {
        newHistory = _.slice(newHistory, 0, 14);
    }
    storage.set("mine_history", newHistory);
    return newHistory;
}

export function timeToString(time) {
    // 时间戳为10位需*1000，时间戳为13位不需乘1000
    let date = new Date(parseInt(time*1000));
    let Y = date.getFullYear() + "-";
    let M = (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
    // let h = date.getHours() + ":";
    // let m = date.getMinutes() + ":";
    // let s = date.getSeconds();

    return Y + M + D;
}
