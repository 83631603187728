import Vue from 'vue'
import VueRouter from "vue-router"
import 'es6-promise/auto'
import store from '@/store'

import App from './App.vue'
import '@/assets/global.css'
import ContentWrap from '@/components/ContentWrap.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import PageHome from "@/views/PageHome"
import About from "@/views/About"
import Agreement from "@/views/Agreement"
import Contact from "@/views/Contact"
import More from "@/views/More"
import MoreBusiness from "@/views/MoreBusiness"
import MoreAll from "@/views/MoreAll";
import MoreVideo from "@/views/MoreVideo";
import MoreLive from "@/views/MoreLive";
import MoreGames from "@/views/MoreGames";


// import style
import vmodal from 'vue-js-modal'

Vue.use(vmodal)

import '@/assets/swiper.min.css'


Vue.use(VueAwesomeSwiper)
Vue.component('ContentWrap', ContentWrap)
Vue.config.productionTip = false

// router
Vue.use(VueRouter)


const routes = [
    {path: '/', component: PageHome},
    {path: '/about', component: About},
    {path: '/agreement', component: Agreement},
    {path: '/contact', component: Contact},
    {path: '/more', component: More},
    {path: '/more_business', component: MoreBusiness},
    {path: '/more_all', component: MoreAll},
    {path: '/more_video', component: MoreVideo},
    {path: '/more_live', component: MoreLive},
    {path: '/more_game', component: MoreGames},
]
const router = new VueRouter({
    mode: 'history',
    routes
})
new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')

var _hmt = _hmt || [];
window._hmt = _hmt;

(function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?2b5ad2cbf52693bc269300b4c2ecd226";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
})();

router.beforeEach((to, from, next) => {
    if(_hmt){
        if(to.path){
            _hmt.push(["_trackPageview", '/' + to.fullPath])
        }
    }
    next()
})
