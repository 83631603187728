<template>
  <div class="search">
    <!--    <img :src="logo" height="50" />-->
    <form
      class="search-form"
      name="tn"
      id="form"
      action="https://www.baidu.com/s"
      target="_blank"
      @submit.prevent="toBaidu(word)"
    >
      <input value="29021690_dg" name="tn" style="display: none">
      <div class="bdsug bdsug-new bdsugbg" style="height: auto;" v-show="completeVisible">
        <ul>
          <li v-for="(item, index) in completeData" :key="index" class="bdsug-overflow" @click="toBaidu(item.q)" style="z-index: 99999">
            {{ word }}<b>{{ sliceWord(item.q) }}</b>
          </li>
        </ul>
      </div>
      <!-- new-ipt-focus -->
      <span class="input-wrap">
        <input
          type="text"
          class="input"
          name="wd"
          @input="onInput"
          @focus="onInput"
          @blur="onBlur"
          maxlength="100"
          autocomplete="off"
          :placeholder="placeHolder"
        />
        <!-- <i class="c-icon quickdelete c-color-gray2" title="清空"></i><i class="quickdelete-line"></i> -->
      </span>
      <span class="search-btn-wrap">
        <input type="submit" value="百度一下" class="search-btn"/>
      </span>
    </form>
    <ExpandTop v-if="is_show_news === true"></ExpandTop>
  </div>
</template>

<script>
const logo = require("@/assets/images/baidu.png");
import request from "@/utils/request";
import ExpandTop from "./ExpandTop";
import jsonp from "jsonp";
export default {
  components: { ExpandTop },
  props:['is_show_news'],
  data() {
    return {
      logo,
      completeVisible: false,
      word: '',
      completeData: [],
      placeHolderData: [],
      placeHolder:"",
      placeHolderIndex: 0,
      timerId:0,
      formData:''
    };
  },
  mounted(){
    this.getBaidu();
  },
  methods: {

    async getBaidu(){
      let resp = await request.get("/hots?type=baidu");
      if(resp.status == 200 && resp.data.code == 0){
        // console.log(resp);
        this.placeHolderData = resp.data.data;
        this.placeHolder = this.placeHolderData[0].title;
        this.timerId = setInterval(function(){
          this.placeHolderIndex += 1;
          if(this.placeHolderIndex == this.placeHolderData.length){
            this.placeHolderIndex = 0;
          }
          this.placeHolder = this.placeHolderData[this.placeHolderIndex].title;
        }.bind(this), 10*1000)
      }
    },
    sliceWord(result) {
      return result.slice(this.word.length)
    },
    onInput(e) {
      this.word = e.target.value
      if(this.word.length > 0){
        this.getCompleteData(this.word)
      }
    },
    getCompleteData(word) {
      const url = `https://www.baidu.com/sugrec?pre=1&p=3&ie=utf-8&json=1&prod=pc&from=pc_web&wd=${word}&req=2&csor=3`
      jsonp(url, {param: 'cb'}, (err, data) => {
        // console.log('11111222, ', data)
        if(!err && !!data & !!data.g) {
          this.completeData = data.g
          this.completeVisible = true;
        } else if(!!data && !data.g) {
          this.completeVisible = false;
          this.completeData = []
        }
      })
    },

    onBlur() {
      setTimeout(() => {
        this.completeVisible = false
      }, 500)
    },

    toBaidu(word) {
      // console.log("toBaidu",word);
      if(word.length == 0){
        word = this.placeHolderData[this.placeHolderIndex].title;
      }
      window.open(`https://www.baidu.com/s?tn=29021690_dg&word=${word}`)
    },
  }
};
</script>

<style scoped lang="less">
.search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .search-form {
    position: relative;
    z-index: 20;
  }

  .input-wrap {
    position: relative;

    &.new-ipt-focus{border-color:#4e6ef2}
  }

  .input {
    width: 443px;
    padding-right: 87px;
    width: 512px;
    height: 16px;
    padding: 12px 16px;
    font-size: 16px;
    margin: 0;
    vertical-align: top;
    outline: 0;
    box-shadow: none;
    border-radius: 10px 0 0 10px;
    border: 2px solid #c4c7ce;
    background: #fff;
    color: #222;
    overflow: hidden;
    box-sizing: content-box;
    border-right: 0;
    -webkit-tap-highlight-color: transparent;

    &:focus {
      border-color: #4e6ef2 !important;
      opacity: 1;
      filter: alpha(opacity=100) \9;
    }
  }

  .search-btn-wrap {
    width: 108px;
    height: 44px;
    position: relative;
    z-index: 2;
    display: inline-block;
    zoom: 1;
    background: 0 0;
    vertical-align: top;

    .search-btn {
      cursor: pointer;
      width: 108px;
      height: 44px;
      line-height: 45px;
      line-height: 44px\9;
      padding: 0;
      background: 0 0;
      background-color: #4e6ef2;
      border-radius: 0 10px 10px 0;
      font-size: 17px;
      color: #fff;
      box-shadow: none;
      font-weight: 400;
      border: none;
      outline: 0;
      -webkit-appearance: none;
    }
  }
  .bdsug-new {
    width: 544px;
    top: 35px;
    position: absolute;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    border: 2px solid #4e71f2 !important;
    border-top: 0 !important;
    box-shadow: none;
    font-family: "Microsoft YaHei", Arial, sans-serif;
    z-index: 1;
  }
  .wrapper_new.wrapper_s #form .bdsug-new {
    width: 478px;
  }
  .bdsug-new ul {
    margin: 7px 14px 0;
    padding: 8px 0 7px;
    background: 0 0;
    border-top: 2px solid #f5f5f6;
  }
  .wrapper_new.wrapper_s #form .bdsug-new ul li {
    width: auto;
  }
  .bdsug-new ul li {
    width: auto;
    padding-left: 14px;
    margin-left: -14px;
    margin-right: -14px;
    color: #626675;
    line-height: 28px;
    background: 0 0;
    font-family: "Microsoft YaHei", Arial, sans-serif;
  }
  .bdsug-new ul li span {
    color: #626675;
  }
  .bdsug-new ul li b {
    font-weight: 400;
    color: #222;
  }
  .bdsug-new .bdsug-store-del {
    font-size: 13px;
    text-decoration: none;
    color: #9195a3;
    right: 16px;
  }
  .bdsug-new .bdsug-store-del:hover {
    color: #315efb;
    cursor: pointer;
  }
  .bdsug-new ul li:hover,
  .bdsug-new ul li:hover span,
  .bdsug-new ul li:hover b {
    cursor: pointer;
  }
  #head .s-down #form .bdsug-new {
    top: 32px;
  }
  .bdsug-new .bdsug-s,
  .bdsug-new .bdsug-s span,
  .bdsug-new .bdsug-s b {
    color: #315efb;
  }
  .bdsug-new .bdsug-s {
    background-color: #f5f5f6 !important;
  }
  .bdsug-new > div span:hover,
  .bdsug-new > div a:hover {
    color: #315efb !important;
  }
  #kw.new-ipt-focus {
    border-color: #4e6ef2;
  }
  .wrapper_new .bdsug-new .bdsug-feedback-wrap {
    border-radius: 0 0 10px 10px;
    background: 0 0;
    line-height: 19px;
    margin-bottom: 3px;
    margin-top: -7px;
  }
  .wrapper_new .bdsug-new .bdsug-feedback-wrap span {
    text-decoration: none;
    color: #9195a3;
    font-size: 13px;
    cursor: pointer;
    margin-right: 14px;
  }
  .wrapper_new .bdsug-new .bdsug-feedback-wrap span:hover {
    color: #315efb;
  }
}
</style>
