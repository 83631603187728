<template>
  <div class="panel card">
    <div class="card-body">
      <div class="panel-header mb-4">
        <h1 class="h3">关于本站 </h1>
      </div>
      <div class="panel-body mt-2">
        <p>123导航网（www.123coco.com）是一个实用的办公生活导航网站，专注于收集分享各类优质的办公生活网站网址、最新资讯、软件资源等，提高办公生活效率！</p>
        <p>
          <strong>联系我们</strong>
        </p>
         <p>如果您的网站或页面被本站收录/推荐而您不希望这样，请及时联系我们，我们将及时修改清除贵站信息。</p>
        <p>你可以通过以下方式与本站联系。</p>
        <p>1.wkfs2008@126.coom</p>
        <p>2.QQ:3467926156</p>
        <p>
          <strong>关于侵权：</strong>
        </p>
        <p>本站分享内容均收集来自网络，供大家免费学习研究使用。如本站分享的内容侵犯了您的权益，请及时与本站联系，我们将及时改正清除相关内容。联系邮箱：wkfs2008@126.com（来信还请说明情况。）</p>
        <p>
          <strong>支持本站：</strong>
        </p>
        <p>1.收藏使用本站是对本站最大的支持，欢迎将本站分享给身边的朋友。</p>
        <p>2.如果你有好的建议意见欢迎添加网站QQ:3467926156 反馈。</p>
        <p>3.欢迎大家举报违法违规网站及内容。</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "About",
  components: {},

  data() {
    return {};
  },

  created() {
  },

  methods: {},
};
</script>

<style scoped lang="less">
.card {
  background: #fff;
  border-width: 0;
  margin: 5rem;
  box-shadow: 0px 0px 20px -5px rgb(158 158 158 / 20%);
  transition: background-color .3s;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.panel-body p {
  margin-bottom: 1.25rem;
  font-size: 1rem;
  line-height: 2;
  word-wrap: break-word;
}
</style>
