const state = {
    head:{
        icon: "https://www.ctrip.com/favicon.ico",
        link: 'https://www.ctrip.com/',
        title: '最新资讯'
    },
    carouselData: [
        {
            link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
            imgUrl: 'https://www-stream.2345cdn.net/dhrest/upload/2022/63e72b5bc695b1193fe7b7322445e477.png',
            title: '1底线：好敢拍👍 剧版“江歌案”再现！！',
        },
        {
            link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
            imgUrl: '//www-stream.2345cdn.net/dhrest/upload/2022/d6ed40770fd7059df5b16d86a731f3de.png',
            title: '1消失的孩子：  三线并行 全员演技炸裂',
        },
        {
            link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
            imgUrl: '//www-stream.2345cdn.net/dhrest/upload/2022/b422863b7b7fea9e7a1c32df18fd645b.jpg',
            title: '1大考：乘风破浪！师生携手直迎大考',
        },
    ],
}
const mutations = {
    setRightBoxNewsData (state, data) {
        state.data = data
    },
}
const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
