const state = {
    head:{
        icon: "https://www.ctrip.com/favicon.ico",
        link: 'https://www.ctrip.com/',
        title: ''
    },
    carouselData: [
        {
            link: 'https://s.click.taobao.com/t?union_lens=lensId%3APUB%401672158484%402106ef0c_0bbe_18554699828_5f2a%4001%40eyJmbG9vcklkIjozODg1Miiwiic3BtQiiI6Il9wb3J0YWxfdjJfcGFnZXNfYWN0aXZpdHlfb2ZmaWNpYWxfaW5kZXhfaHRtIn0ie%3BeventPageId%3A20150318020014241&e=m%3D2%26s%3DIvnBWBbHlKNw4vFB6t2Z2iperVdZeJviU%2F9%2F0taeK29yINtkUhsv0IGIoQKLixGejRLfZpCsldcvDECUuFAwKBTKRm%2F0KQqstIQvrk3Rn39Pw2IA3RN%2FnV9EeTtntI4431iT9rHVeimEzsi2L1ij00Hzhe02yjfRTaysLwyTT15CBmyzWObreM7ngtD9XDUvus04T2LxK84SQRbNf8i8VT8XsvPoXfKc4iyvewCd%2B8RVxOtAjtgWnUv%2BaZPfRJxm3Peb6GzeGw8PsKnJveZ7JnaH9JtUKSe2efZpbDTKrseztQ0ux3ZsjnPyxoLu3kWU71mHwGtpVlinagF44yV4JeF4xme1ir4BvRpaMHHH5WmM4zP2W98hoexibBPQpnhdrhb6VOeebIJnhd9M4jRdqT0BugKtkLVxbtSAsRxnYoaXG8WdNB0OzUmelLNQC%2FLC45DlEPzh0cAiWWZPBX3cfnEqY%2Bakgpmw',
            imgUrl: require('@/assets/images/soft/tianmaonianhuo.png'),
        },
        {
            link: 'https://union-click.jd.com/jdc?e=618%7Cpc%7C&p=JF8BAJ8JK1olXDYDZBoCUBVIMzZNXhpXVhgcFgcDTBBPHDRdBAlLARgyNQQqVjxfeARoASEcCGRXHUA6Xw1qRWMEK1scVAEEVVZUAU0nA24JG1IUXgAFXW5tCEonSAEJK1scVA4AUVtZCEMnA2gOG1oTXQ4CUl9cCnsQA2Y4G1gXBl5SFhptOHsUM2gIEk8TL0dQQFgvOHsWM2w4K1kUXAILZG4',
            imgUrl: require('@/assets/images/soft/jingdong.png'),
        },
    ],
}
const mutations = {
    setRightBoxNewsData (state, data) {
        state.data = data
    },
}
const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
