// 吸顶跟随效果
export default {
  data() {
    return {
      fixedElementConfig: { // 引用该mixins时覆盖， 注意涉及到element的id的不要重复
        element: '#fixed-element',
        placeholderElement: '#fixed-element-placehodler',
        fixedStyle: 'position: fixed; top: 0px; z-index: 999;border-bottom: 1px solid #d8dbe5;',
        offsetTop: 0,
        offsetParent: null,
        elementOffsetTop: null,
        log: false
      },
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const element = document.querySelector(this.fixedElementConfig.element)
      if(element !== null){
        this.fixedElementConfig.elementOffsetTop = this.fixedElementConfig.elementOffsetTop || element.offsetTop
        this.fixedElementConfig.offsetParent = this.fixedElementConfig.offsetParent || element.offsetParent
        const placeholderElement = document.querySelector(this.fixedElementConfig.placeholderElement)
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        scrollTop = scrollTop + (this.fixedElementConfig.offsetTop || 0)
        // let offsetTop = element.offsetTop + (this.fixedElementConfig.offsetTop || 0)
        let offsetTop = this.fixedElementConfig.elementOffsetTop
        try {
          offsetTop += this.fixedElementConfig.offsetParent.offsetTop;
        } catch (error) {
          // offsetTop =
        }
        // this.fixedElementConfig.log && console.log('scrollTop', scrollTop, offsetTop, this.fixedElementConfig.offsetTop)
        if(scrollTop > offsetTop) {
          if (this.whenFiexd) {
            this.whenFiexd(element, placeholderElement)
          } else {
            placeholderElement.setAttribute('style', `height: ${element.offsetHeight}px`);
            element.setAttribute('style', this.fixedElementConfig.fixedStyle);
          }
        } else {
          if (this.whenNoFiexd) {
            this.whenNoFiexd(element, placeholderElement)
          } else {
            placeholderElement.setAttribute('style', 'height: 0;')
            element.setAttribute('style', '');
          }
        }
      }
    },
  },
}
