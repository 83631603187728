<template>
  <div>
<!--    <LinkList></LinkList>-->
    <div class="middle-info-flow">
      <!-- <div id="fixed-element-placehodler-tab"></div> -->
      <div class="tab-wrap">
        <div
            id="fixed-element-tab"
            class="tab-content bac2">
          <ul class="tab-list">
            <li class="tab-item" :class="[currentActive === item.key? 'active' : '']" v-for="item in tabConfig"
                @click="clickTab(item)" :key="item.key">
              <span class="tab-title"> {{ item.title }} </span>
            </li>
          </ul>
        </div>
      </div>

      <LargeNews :data="data"></LargeNews>
    </div>
  </div>
</template>

<script>
import LargeNews from './LargeNews.vue'
// import LinkList from './LinkList'
import {newsDataState, scrollFixed} from '@/mixins'
export default {
  name: 'InfoFlow',
  mixins: [newsDataState, scrollFixed],
  components: {
    LargeNews,
    // LinkList
  },
  props: ['data'],
  data() {
    return {
      currentActive: '1',
      tabConfig: [
        {
          key: '1',
          title: '热点'
        },
        {
          key: '2',
          title: '娱乐'
        },
        {
          key: '3',
          title: '体育'
        },
        {
          key: '4',
          title: '财经'
        },
        {
          key: '5',
          title: '社会'
        },
        {
          key: '7',
          title: '军事'
        },
        {
          key: '8',
          title: '猎奇'
        },
      ],
      fixedElementConfig: {
        element: '#fixed-element-tab',
        placeholderElement: '#fixed-element-placehodler-tab',
        offsetTop: 181,
        log: false
      },
    }
  },
  mounted() {
    // this.fixedElementConfig.offsetTop = document.querySelector('#fixed-element-header').offsetHeight + 130
  },
  methods: {
    clickTab(item) {
      this.currentActive = item.key
      this.loadData(item.key, item)
    },

    loadData(key, item) {
      // TODO: 加载数据
      // //console.log(key)
      this.setPage(1)
      this.setType(item.title)
      this.getTopNews()
    },

    whenFiexd(element) {
      element.parentElement.setAttribute('style', 'height: 102px;')
      element.setAttribute('class', 'tab-content bac1 fixed')
      element.setAttribute('style', `padding-top: 0px; top: ${document.querySelector('#fixed-element-header').offsetHeight}px;`)
      element.querySelector('.tab-list').setAttribute('style', 'padding-top: 8px;')
      // element.querySelector('.tab-more').setAttribute('style', 'top: 9px;')
      // element.querySelector('.rec-btn').setAttribute('style', 'top: 9px;')
    },
    whenNoFiexd(element) {
      element.parentElement.setAttribute('style', 'height: 80px;')
      element.setAttribute('class', 'tab-content bac2')
      element.setAttribute('style', `padding-top: 0px;`)
      // element.querySelector('.tab-list').setAttribute('style', 'padding-top: 0;')
      // element.querySelector('.tab-more').setAttribute('style', '')
      // element.querySelector('.rec-btn').setAttribute('style', '')
    }
  },
};
</script>

<style>
.middle-info-flow {
  background-color: #fff;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  border-radius: 8px;
  width: 650px;
}

.middle-info-flow .tab-wrap {
  width: 650px;
  height: 80px;
}

.middle-info-flow .tab-content {
  width: 650px;
  height: 230px;
  position: relative;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
}

.middle-info-flow .tab-content.fixed {
  position: fixed;
  z-index: 10;
  height: 40px;
}

.middle-info-flow .pre-image {
}

.middle-info-flow .bac1 {
  background-color: white;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;

}

.middle-info-flow .bac2 {
}

.middle-info-flow .tab-list {
  padding: 10px 18px 0 0px;
}

.middle-info-flow .tab-item {
  display: inline-block;
  padding: 0 20px;
}

.middle-info-flow .tab-item.active .tab-title {
  color: #f30;
  font-weight: bold;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.middle-info-flow .tab-item.active .tab-title::after {
  content: "";
  position: absolute;
  top: 22px;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 6px;
}

.middle-info-flow .tab-title {
  font-size: 15px;
  line-height: 20px;
  color: #222;
  cursor: pointer;
}

.middle-info-flow .tab-title:hover {
  color: #f30;
}

.middle-info-flow .tab-more {
  position: absolute;
  right: 37px;
  top: 41px;
  font-size: 12px;
  width: 46px;
  height: 18px;
  background-color: #ff7c59;
  background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#ff7c59),
      to(#ff4646)
  );
  background-image: linear-gradient(90deg, #ff7c59 0%, #ff4646 100%);
  border-radius: 10px;
  text-align: center;
  color: white;
  line-height: 18px;
}

.middle-info-flow .tab-more:hover {
  background-color: #ff4646;
  background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#ff4646),
      to(#ff3100)
  );
  background-image: linear-gradient(90deg, #ff4646 0%, #ff3100 100%);
}

.middle-info-flow .tab-more .svg-icon {
  font-size: 9px;
  vertical-align: 0;
  width: 6px;
  height: 9px;
  margin-left: 1px;
}

.middle-info-flow .rec-btn {
  position: absolute;
  right: 14px;
  top: 41px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.middle-info-flow .rec-btn .dot {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
  background-size: 12px auto;
}

.middle-info-flow .rec-btn:hover .dot {
  opacity: 0.5;
}

.middle-info-flow .rec-btn .menu {
  height: 38px;
  width: 99px;
  top: 14px;
  right: -13px;
  position: absolute;
  z-index: 100;
}

.middle-info-flow .rec-btn .menu > div {
  height: 30px;
  bottom: 0;
  width: 100%;
  background: white;
  line-height: 30px;
  text-align: center;
  position: absolute;
  -webkit-box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  color: #666666;
}

.middle-info-flow .rec-btn .menu > div:hover {
  color: #f30;
  text-decoration: underline;
}

.middle-info-flow .rec-btn .menu > div:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 5px solid #fff;
  position: absolute;
  top: -5px;
  left: 73px;
}
</style>
