const state = {
    head:{
        icon: "https://www.ctrip.com/favicon.ico",
        link: 'https://www.ctrip.com/',
        title: '热门游戏'
    },
    data: [
        {
            link: 'http://cqyx.4366qq.com/4551/',
            imgUrl: '//business-syh-cdn.2345ii.com/eimg/202208/bc6aac5d9a3dc1d98597f2e97d9e3eca.png',
            title: '道士带8狗1'
        },
        {
            link: 'http://www.45yx.com/ws/713/835',
            imgUrl: '//business-syh-cdn.2345ii.com/eimg/202208/f902ed9c0382378ba43adde1ac8b8a43.jpg',
            title: '装备爆满屏1'
        },
        {
            link: 'http://mtw.so/5XdMDC',
            imgUrl: '//business-syh-cdn.2345ii.com/eimg/202208/49513f3c552377c8b1b0c61bd6a8bbae.png',
            title: '传奇1.761'
        },
        {
            link: 'http://mtw.so/64JZIj',
            imgUrl: '//business-syh-cdn.2345ii.com/eimg/202208/137a0fb8e0d76f94f2bc2e1d1d189e57.png',
            title: '道士带N狗1'
        },
    ],
}
const mutations = {
    setRightBoxNewsData (state, data) {
        state.data = data
    },
}
const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
