<template>
  <div class="page-home">

    <!-- 顶部搜索框和天气 -->
    <div class="head-and-search bg-white" id="fixed-element-header">
      <ContentWrap>
        <div class="search-wrap">
          <!-- logo -->
          <div class="logo">
            <img src="@/assets/images/logo.png"/>
          </div>

          <!-- 搜索栏 -->
          <div class="search">
            <Search :is_show_news="true"/>
          </div>

          <!-- 天气栏 -->
          <div style="margin-top: -5px;">
            <Weather/>
          </div>
        </div>
      </ContentWrap>
    </div>
    <!-- fixed时占位 -->
    <div id="fixed-element-placehodler-header" style=""></div>

    <!-- 我的导航和最新使用 -->
    <CustomNav></CustomNav>

    <!-- 常用大站 -->
    <ContentWrap class="bg-white round margin-top">
      <CommonUse></CommonUse>
    </ContentWrap>

    <!-- 主体 -->
    <ContentWrap class="content clearfix">
      <!-- 左侧 -->
      <div class="content-middle round margin-top">
        <div class="right-box">
          <RightBox></RightBox>
        </div>

      </div>

      <!-- 中间 -->
      <div class="content-right round margin-top">
        <!-- 信息流 -->
        <InfoFlow></InfoFlow>
      </div>

      <!-- 右侧 -->
      <div class="content-left round margin-top">
        <LeftBox></LeftBox>

      </div>
    </ContentWrap>

    <!-- footer -->
    <FooterInfo></FooterInfo>
  </div>
</template>

<script>
import Search from "./components/Search.vue";
import Weather from "./components/Weather.vue";
import CustomNav from "./components/CustomNav.vue";
import CommonUse from "./components/CommonUse.vue";
import InfoFlow from "./components/InfoFlow";
import RightBox from "./components/RightBox";
import LeftBox from "./components/LeftBox";
import FooterInfo from "./components/FooterInfo";

import {newsDataState, scrollFixed} from '@/mixins';

export default {
  name: "PageHome",
  mixins: [newsDataState, scrollFixed],
  components: {
    LeftBox,
    Search,
    Weather,
    CustomNav,
    CommonUse,
    InfoFlow,
    RightBox,
    FooterInfo
  },

  data() {
    return {
      fixedElementConfig: {
        element: '#fixed-element-header',
        placeholderElement: '#fixed-element-placehodler-header',
        fixedTop: '0',
      },
    };
  },

  created() {
    this.getTopNews();
    // this.addNewsData();
    this.getCommonData();
    this.getLinklistData();
    // this.getRightBoxNewsData();
    // this.test();
  },
  mounted() {
    // this.showAd();
  },

  methods: {
    showAd:function () {
      //console.log("showAd");
      //console.log("1231234", window.slotbymingyi);
      (window.slotbymingyi = window.slotbymingyi || []).push({
        position: 'position1',   //广告位置，需要向商务申请
        container: '123coco_page_home'      //广告容器id，必须唯一
      });
      //console.log("123123", window.slotbymingyi)
    }
  },
};
</script>

<style scoped lang="less">
.icon_logo {
  width: 16px;
  height: 16px;
}
.page-home {
  position: relative;
  overflow: hidden;
  padding-bottom: 70px;

  .head-and-search {
    width: 100%;
    position: relative;
    z-index: 11;
  }

  .search-wrap {
    display: flex;
    align-items: center;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;

    .logo {
      width: 150px;
      height: 51px;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .search {
      flex: 1;
    }
  }

  .content-middle {
    float: left;
    width: 270px;
    position: relative;

  }

  .content-right {
    float: left;
    width: 650px;
    position: relative;
    margin-left: 5px;

  }

  .content-left {
    float: left;
    width: 220px;
    margin-left: 5px;
    position: relative;
  }

  .famous-web {
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 5%);
    position: relative;
    margin-top: 10px;
    box-sizing: border-box;
  }
}

.xxl-news .xxl-news-ppt,
.xxl-news .xxl-news-item,
.xxl-news .xxl-zk-1,
.xxl-news .xxl-zk-2 {
  position: relative;
}
</style>
