<template>
  <div>
    <SectionHead v-bind="headConfig" />
    <div class="swiper-image-text">
      <div class="container" style="height: 180px;width: 240px;margin-top: 10px;">
        <swiper style="left: 0px" class="imgs" ref="swiper" :auto-update="true" :options="swiperOptions">
          <swiper-slide class="n-swiper-item" v-for="item in carouselData" :key="item.id">
            <a
              :href="item.link"
              target="_blank"
              ><img
                alt="pic"
                :src="item.imgUrl"
                :data-src="item.imgUrl"
                lazy="loaded" />
            </a>
            <span>
              <a
                :title="item.title"
                :href="item.link"
                target="_blank"
                ><img
                 :src="playIcon"
                 alt=""
               />{{item.title}}
              </a>
            </span>
          </swiper-slide>
        </swiper>
        <span class="left" @click="prev" style="top: 86px;"><span class="icon"></span></span>
        <span class="right" @click="next" style="top: 86px;"><span class="icon"></span></span>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHead from "./SectionHead";
const playIcon = require("@/assets/images/rightbox/play.png");

export default {
  name: "HotTv",
  components: {
    SectionHead,
  },
  data() {
    return {
      playIcon,
      headConfig: this.$store.state.rightbox_tv.head,
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 5000,
        }
      },
      carouselData: this.$store.state.rightbox_tv.carouselData,
    };
  },
  methods: {
    next() {
      //console.log('refs', this.$refs)
      return this.$refs.swiper.$swiper.slideNext()
    },
    prev() {
      return this.$refs.swiper.$swiper.slidePrev()
    }
  }
};
</script>

<style scoped>
.container {
  margin: 0 auto;
  width: 270px;
  height: 90px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}
.container:hover .left {
  display: block;
}
.container:hover .right {
  display: block;
}
.imgs {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  font-size: 0;
}
.imgs  .n-swiper-item {
  width: 10%;
  height: 100%;
  display: inline-block;
  position: relative;
}
.imgs .n-swiper-item a img {
  width: 100%;
  height: 100%;
}
.imgs  .n-swiper-item span {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 32px;
  text-align: center;
  /*background: url(//www-stream.2345cdn.net/s/new2345/img/transparent_2019050556048e1cb3ed41a78a36a2327a55cce5.png) 0 -204px;*/
}
.imgs .n-swiper-item span a {
  line-height: 32px;
  color: white;
  font-size: 14px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.imgs .n-swiper-item span a:hover {
  color: white;
}
.left,
.right {
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  z-index: 9;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 18px;
  height: 38px;
  background: rgba(0, 0, 0, 0.3);
  top: 26px;
  display: none;
}
.left .icon,
.right .icon {
  display: inline-block;
  width: 10px;
  height: 19px;
}
.left {
  left: 0;
}
.left .icon {
  /*background: url(//www-stream.2345cdn.net/s/new2345/img/module-2020081244afa00ba4063235060bb9cd222f38a5.png)*/
  /*  no-repeat 0 -207px;*/
  margin-top: 10px;
  margin-left: 2px;
}
.right {
  right: 0;
}
.right .icon {
  /*background: url(//www-stream.2345cdn.net/s/new2345/img/module-2020081244afa00ba4063235060bb9cd222f38a5.png)*/
  /*  no-repeat -15px -207px;*/
  margin-top: 10px;
  margin-left: 5px;
}
.point {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 15px;
  bottom: -40px;
  font-size: 0;
}
.txt-news {
  margin-top: 10px;
}
.txt-news li {
  padding-left: 40px;
  position: relative;
  height: 30px;
  line-height: 30px;
}
.txt-news li:before {
  content: "";
  height: 4px;
  width: 4px;
  overflow: hidden;
  background: #ccc;
  position: absolute;
  left: 24px;
  top: 13px;
  border-radius: 50%;
}
.txt-news li a {
  font-size: 15px;
  line-height: 30px;
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  color: #333;
}
.txt-news li a:hover {
  color: #f30;
}
.txt-news li .red {
  color: #f30;
}
.txt-news li i {
  margin-top: 7px;
  vertical-align: 11px;
  margin-left: 3px;
}

.swiper-image-text {
  margin-bottom: 19px;
}
.b-title {
  width: 270px;
  margin: 8px auto 0;
  padding-bottom: 15px;
}
.b-title span {
  width: 38px;
  height: 20px;
  border-radius: 14px;
  border: 1px solid #e6e6e6;
  text-align: center;
  line-height: 18px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}
.b-title a {
  display: block;
  color: #222;
  width: 242px;
  font-size: 14px;
  height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 16px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}
.b-title a:before {
  content: "";
  height: 6px;
  width: 6px;
  overflow: hidden;
  background: #ccc;
  position: absolute;
  left: 4px;
  top: 8px;
  border-radius: 50%;
}
.b-title a:hover {
  color: #f30;
}
.imgs .n-swiper-item span {
  text-align: left;
}
.imgs .n-swiper-item span img {
  width: 20px;
  height: 20px;
  float: left;
  margin-top: 7px;
  margin-left: 9px;
  margin-right: 3px;
}
</style>
