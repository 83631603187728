<template>
  <div class="left-box" id="left-box-content">
    <HotNews/>
    <HotApp/>
    <HotAd/>
    <!-- 用于被监听是否到底部的辅助节点 -->
    <li id="left-box-bottom-helper" class="left-box-bottom-helper"></li>
  </div>
</template>

<script>
import HotNews from './HotNews'
import HotApp from "@/views/components/LeftBox/Sections/HotApp";
import HotAd from "./HotAd"
import { isInViePortOfOne } from '@/utils';

const status = {
  lastInViePortOfOneStatus: false, // 用于记录上一次是否在视野中的状态
  helperScrollHeight: 100000, // 这个是用来记录是否左侧栏已经刚到到达底部的滚动条位置
}

export default {
  name: 'RightSections',
  components: {
    HotNews,
    HotApp,
    HotAd
  },
  data() {
    return {

    }
  },
  mounted() {
    this.listen()
  },
  methods: {
    listen() {
      const bottomEl = document.querySelector('#left-box-bottom-helper')
      const rightBox = document.querySelector('#left-box-content')
      window.addEventListener("scroll", () => {
        const isInViePortOfOneStatus = isInViePortOfOne(bottomEl)
        if((!status.lastInViePortOfOneStatus && isInViePortOfOneStatus) || (status.lastInViePortOfOneStatus && !isInViePortOfOneStatus)) {
          status.helperScrollHeight = document.documentElement.scrollTop + 56
        }

        if(document.documentElement.scrollTop > status.helperScrollHeight) {
          rightBox.classList = 'left-box left-fixed'
        } else {
          rightBox.classList = 'left-box'
        }
        status.lastInViePortOfOneStatus = isInViePortOfOneStatus
      })
    }
  }
}
</script>

<style scoped>
.left-box {
  width: 270px;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 5%);
  border-radius: 8px;
  overflow: hidden;
  background: #FFF;
}
.left-box-bottom-helper{
  height: 5px;
}

.left-fixed {
  position: fixed;
  bottom: 56px;
}
</style>
