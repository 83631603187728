//我的导航
//固定导航
const state = {
    //标题
    hot_buy_tabs_data: [
        {
            text: "天猫",
            type: "tianmao",
            link: "https://www.tmall.com",
        },
        {
            text: "爱淘宝",
            type: "taobao",
            link: "https://ai.taobao.com/",
        },
        {
            text: "京东",
            type: "haohuo",
            link: "https://chaoshi.jd.com/",
        },
        {
            text: "热卖",
            type: "remai",
            link: "https://ai.taobao.com/",
        },
        {
            text: "红包",
            type: "hongbao",
            link: "https://ai.taobao.com/",
        },
    ],
    //天猫
    hot_buy_tmail: {
        banner: {
            img: require('@/assets/images/gouwu/tianmaochaoshi.png'),
            href: 'https://s.click.taobao.com/t?union_lens=lensId%3APUB%401671286041%400b16eb8c_09ad_18520692c22_e3c7%4001%40%257B%2522floorId%2522%253A38852%252C%2522spmB%2522%253A%2522_portal_v2_pages_activity_official_index_htm%2522%257D%3BeventPageId%3A1586925572118&e=m%3D2%26s%3DrKMeE59vc%2BscQipKwQzePCperVdZeJviU%2F9%2F0taeK29yINtkUhsv0Phmdgx3m7M5f36Lv85hTTAvDECUuFAwKBTKRm%2F0KQqstIQvrk3Rn39Pw2IA3RN%2FnV9EeTtntI4431iT9rHVeinLP4XO97TuilWKPqsk%2FwWtTaysLwyTT15CBmyzWObreM7ngtD9XDUvus04T2LxK84SQRbNf8i8VT8XsvPoXfKc4iyvewCd%2B8TkxFoMprNDNc%2BETpxl6cLPz5NWqJ5LsPcrOtp58OXWEQJi7bstyR%2BbTctVgPiDkqvQeBkrrbGdcn9KmP7CQLvtyjn7eeYhZlUK2%2BxlJ%2FgaZmTP7z1CfjMBtwLUkdbL0sxC20i781Gu1q0QpWx%2F%2F%2FvQbPM%2BnUJPu7k2mSB0lXJPlzm1EhXKOMFkDOGMMI4SsZnnNAZy07fXAYPUIQIxzlHhUDtJQ5%2BtpdXGDF1NzTQoPw%3D%3D',
            title: 'xxx',
        },
        items_left: [
            {
                key: 1,
                href: 'https://s.click.taobao.com/t?union_lens=lensId%3APUB%401671244335%402107e623_09af_1851decc93d_c923%4001%40%257B%2522floorId%2522%253A38852%252C%2522spmB%2522%253A%2522_portal_v2_pages_activity_official_index_htm%2522%257D%3BeventPageId%3A20150318020005981&e=m%3D2%26s%3D3%2BPPm71spB4cQipKwQzePCperVdZeJviU%2F9%2F0taeK29yINtkUhsv0PKjqLP%2Bi%2FYO3IuvitHJXssvDECUuFAwKBTKRm%2F0KQqstIQvrk3Rn39Pw2IA3RN%2FnV9EeTtntI4431iT9rHVeinTRc1Hh2%2F36kK1xmnFj1cVTaysLwyTT15CBmyzWObreM7ngtD9XDUvus04T2LxK84SQRbNf8i8VT8XsvPoXfKcr01uZdwwg99g%2F1osjvwn0wDXC3D35jlq2MeToCO%2Fh%2BlBU%2BbgLkygHW3XfLtGcTn8zaOtPaM31zJpYe6f90erUIzXRi161TLe7qeUylWc6TSX3cwyLTlAhhf6um7FxAD8xgxdTc00KD8%3D',
                img: require('@/assets/images/gouwu/tianmaochaoshi.png'),
                title: '高端个护',
            },
            {
                key: 2,
                href: 'https://s.click.taobao.com/t?union_lens=lensId%3APUB%401671243778%4021051faa_099d_1851de44927_4a03%4001%40%257B%2522floorId%2522%253A38852%252C%2522spmB%2522%253A%2522_portal_v2_pages_activity_official_index_htm%2522%257D%3BeventPageId%3A20150318020010721&e=m%3D2%26s%3D%2BjVj%2B%2FtfUiwcQipKwQzePCperVdZeJviU%2F9%2F0taeK29yINtkUhsv0O5ktX1yqua3eVL0KyhNWcgvDECUuFAwKBTKRm%2F0KQqstIQvrk3Rn39Pw2IA3RN%2FnV9EeTtntI4431iT9rHVeinTRc1Hh2%2F36kK1xmnFj1cVTaysLwyTT15CBmyzWObreM7ngtD9XDUvus04T2LxK84SQRbNf8i8VT8XsvPoXfKcr01uZdwwg99g%2F1osjvwn0wDXC3D35jlq2MeToCO%2Fh%2Blwj7UOxS11gm3XfLtGcTn8zaOtPaM31zJpYe6f90erUF%2BrLIoIc50t7qeUylWc6TSX3cwyLTlAhp%2FLbwQGhvObxgxdTc00KD8%3D',
                img: require('@/assets/images/gouwu/tianmaochaoshi.png'),
                title: '服饰风尚',
            },
            {
                key: 3,
                href: 'https://s.click.taobao.com/t?union_lens=lensId%3APUB%401671244435%40210591aa_0adc_1851dee521d_3d04%4001%40%257B%2522floorId%2522%253A38852%252C%2522spmB%2522%253A%2522_portal_v2_pages_activity_official_index_htm%2522%257D%3BeventPageId%3A20150318020007050&e=m%3D2%26s%3DmuvdfkAGZ%2F4cQipKwQzePCperVdZeJviU%2F9%2F0taeK29yINtkUhsv0PKjqLP%2Bi%2FYOYfzLXn1LAqwvDECUuFAwKBTKRm%2F0KQqstIQvrk3Rn39Pw2IA3RN%2FnV9EeTtntI4431iT9rHVeinTRc1Hh2%2F36kK1xmnFj1cVTaysLwyTT15CBmyzWObreM7ngtD9XDUvus04T2LxK84SQRbNf8i8VT8XsvPoXfKcr01uZdwwg99g%2F1osjvwn0wDXC3D35jlq2MeToCO%2Fh%2BnjI4YCf%2BHlcW3XfLtGcTn8zaOtPaM31zJpYe6f90erUIKs%2Bxaudc9P7qeUylWc6TSX3cwyLTlAhqHoyfXpncuzxgxdTc00KD8%3D',
                img: require('@/assets/images/gouwu/tianmaochaoshi.png'),
                title: '直营家居',
            },
        ],
        items_right: [
            {
                key: 1,
                href: 'https://s.click.taobao.com/t?union_lens=lensId%3APUB%401671244510%40212a9072_0ae2_1851def7627_a36f%4001%40%257B%2522floorId%2522%253A38852%252C%2522spmB%2522%253A%2522_portal_v2_pages_activity_official_index_htm%2522%257D%3BeventPageId%3A20150318020004356&e=m%3D2%26s%3DaoLgnH6jiKQcQipKwQzePCperVdZeJviU%2F9%2F0taeK29yINtkUhsv0PKjqLP%2Bi%2FYOv5ynSOcKl3EvDECUuFAwKBTKRm%2F0KQqstIQvrk3Rn39Pw2IA3RN%2FnV9EeTtntI4431iT9rHVeinTRc1Hh2%2F36kK1xmnFj1cVTaysLwyTT15CBmyzWObreM7ngtD9XDUvus04T2LxK84SQRbNf8i8VT8XsvPoXfKcr01uZdwwg99g%2F1osjvwn0wDXC3D35jlq2MeToCO%2Fh%2Bm6Uju5lUjbfm3XfLtGcTn8zaOtPaM31zJpYe6f90erUPMiss3a9ZBF7qeUylWc6TSX3cwyLTlAhgo%2FBniPRM%2F2xgxdTc00KD8%3D',
                img: require('@/assets/images/gouwu/tianmaochaoshi.png'),
                title: '新品尝鲜',
            },
            {
                key: 2,
                href: 'https://s.click.taobao.com/t?union_lens=lensId%3APUB%401671286175%40212ba35f_0c23_185206b3796_0a50%4001%40%257B%2522floorId%2522%253A38852%252C%2522spmB%2522%253A%2522_portal_v2_pages_activity_official_index_htm%2522%257D%3BeventPageId%3A20150318020000731&e=m%3D2%26s%3DugQtM8BgL%2BMcQipKwQzePCperVdZeJviU%2F9%2F0taeK29yINtkUhsv0Phmdgx3m7M56FF7QIkSb9wvDECUuFAwKBTKRm%2F0KQqstIQvrk3Rn39Pw2IA3RN%2FnV9EeTtntI4431iT9rHVeinTRc1Hh2%2F36kK1xmnFj1cVTaysLwyTT15CBmyzWObreM7ngtD9XDUvus04T2LxK84SQRbNf8i8VT8XsvPoXfKcr01uZdwwg99g%2F1osjvwn0wDXC3D35jlq2MeToCO%2Fh%2BmPguVmV%2BJbAm3XfLtGcTn8zaOtPaM31zJpYe6f90erUMHHlqdPGPyF7qeUylWc6TSX3cwyLTlAht1HJ5cEIHz2xgxdTc00KD8%3D',
                img: require('@/assets/images/gouwu/tianmaochaoshi.png'),
                title: '萌宠上新',
            },
            {
                key: 3,
                href: 'https://s.click.taobao.com/t?union_lens=lensId%3APUB%401671286316%40213ec0a9_0a9b_185206d5c43_d37f%4001%40%257B%2522floorId%2522%253A38852%252C%2522spmB%2522%253A%2522_portal_v2_pages_activity_official_index_htm%2522%257D%3BeventPageId%3A20150318020012640&e=m%3D2%26s%3DwPJqGLMJ8IUcQipKwQzePCperVdZeJviU%2F9%2F0taeK29yINtkUhsv0Phmdgx3m7M5MXqywjQgg0cvDECUuFAwKBTKRm%2F0KQqstIQvrk3Rn39Pw2IA3RN%2FnV9EeTtntI4431iT9rHVeinTRc1Hh2%2F36kK1xmnFj1cVTaysLwyTT15CBmyzWObreM7ngtD9XDUvus04T2LxK84SQRbNf8i8VT8XsvPoXfKcr01uZdwwg99g%2F1osjvwn0wDXC3D35jlq2MeToCO%2Fh%2BlZxm6SNAHXum3XfLtGcTn8zaOtPaM31zJpYe6f90erUB89W8iqvhqP7qeUylWc6TSX3cwyLTlAhp5uMXeQCLiixgxdTc00KD8%3D',
                img: require('@/assets/images/gouwu/tianmaochaoshi.png'),
                title: '环球食品',
            },
        ],
    },
    //爱淘宝
    hot_buy_taobao: {
        banner: {
            img: require('@/assets/images/gouwu/aitaobao.png'),
            href: 'https://ai.taobao.com/',
            title: '爱淘宝',
        },
        item: {
            key: 1,
            href: 'https://ai.taobao.com/',
            img: require('@/assets/images/gouwu/aitaobao.png'),
            title: '爱淘宝',
           // original_price: 9.99,
           // price: 0.99,
        },
    },
    //京东
    hot_buy_good: {
        banners: [
            {
                key: 1,
                img: require('@/assets/images/gouwu/jiadian.png'),
                href: 'https://union-click.jd.com/jdc?e=618%7Cpc%7C&p=JF8BAKAJK1olXDYDZBoCUBVIMzZNXhpXVhgcDwYNXRNHXHBTTkRHA1ocZBY5QRhMRQdbezMWOm0EDB0oWwJSWA8EF2sVVA8FUl9VAUIRM28JGlscXAUEU1dtOEsWMyRmGmsVVA8KVltYDEsfM28PHVsWVAIAVlhbDE0nBG8BK1sWX11aBBwZOHsnAF8PG1IBW3RDBkpbensnAl8LK2sXXAcGXW5t',
                title: '家用电器',
            },
            {
                key: 2,
                img: require('@/assets/images/gouwu/jiaju.png'),
                href: 'https://union-click.jd.com/jdc?e=618%7Cpc%7C&p=JF8BAKkJK1olXDYDZBoCUBVIMzZNXhpXVhgcBgcNVxRDXnBTTkRHA1ocDQABXFRORjNVKx9JXVhUAygWaBBLSwlSZSdAIW1QNghRBHsXCmYPHVodVA8EZF5cCUseAmwOHFIlbQYDZBUzCXsXCmYAGV4QWQYKZF5aDksUCmsNG1sTWwcyU15UOEsUATRQSxlRbTYyV25aCEIDBR1JSU8TLzYyVW5eOHsVAm4MEmsl',
                title: '家居日用',
            }
        ],
        items_left: [
            {
                key: 1,
                href: 'https://union-click.jd.com/jdc?e=618%7Cpc%7C&p=JF8BAKAJK1olXDYDZBoCUBVIMzZNXhpXVhgcAQYNVxRHXXBTTkRHA1ocZCQfaRhSVydaeVxJKUNYMBheTCxIBR8EF2sVVA8FUl9VAUIRM28JGlscXAUEU1dtOEsWMyRmGmsVVA8KVltYDEsfM28PHVsWVAIGVl5UAEsnBG8BK1sWX11aBBwZOHsnAF8PG1IBW3RDBkpbensnAl8LK2sXXAcGXW5t',
                title: '办公电脑',
            },
            {
                key: 2,
                href: 'https://union-click.jd.com/jdc?e=618%7Cpc%7C&p=JF8BALAJK1olXDYDZBoCUBVIMzZNXhpXVhgcBgcNVxRDXnBTTkRHA1ocDgYYWhJDXClYWA8KBENeCW4qSSt_eG5yazhmAX1YKzsHUjlDdWx4F1clXQ8LU1hcAEIeBV8IGloVVAcBUllUOHsXAl9DdVolXQ8LXFxYDU8XC18IHF0VXg8GU19fAEIfM2gIEmsVXgRZDA4fTHsnM2w4HFscSQBwFQxJDjknM244GGslXwcDUFdtOA',
                title: '精品厨具',
            },
            {
                key: 3,
                href: 'https://union-click.jd.com/jdc?e=618%7Cpc%7C&p=JF8BAJ0JK1olXDYDZBoCUBVIMzZNXhpXVhgcAwADXVRMVnBaRQcLbXFEIzY6bzh-Y2hAWy0LXhxRKF8HfzsbD18IElISWwcKXVdbOEsWAm8BGlgTWg8yZF5cOAB5Al8IElIdXwMHUF5VOEsQBW8LEl8SWAUCUFttD0seM28LGQBNDURGZG5tC3sQA2YcHSlUDxIEJm5tCXsUM18KGloRVDYy',
                title: '食品饮料',
            },
            {
                key: 4,
                href: 'https://union-click.jd.com/jdc?e=618%7Cpc%7C&p=JF8BAKAJK1olXDYDZBoCUBVIMzZNXhpXVhgcDwYNXRNHXHBTTkRHA1ocZBY5QRhMRQdbezMWOm0EDB0oWwJSWA8EF2sVVA8FUl9VAUIRM28JGlscXAUEU1dtOEsWMyRmGmsVVA8KVltYDEsfM28PHVsWVAIAVlhbDE0nBG8BK1sWX11aBBwZOHsnAF8PG1IBW3RDBkpbensnAl8LK2sXXAcGXW5t',
                title: '家用电器',
            },
        ],
        items_right: [
            {
                key: 1,
                href: 'https://union-click.jd.com/jdc?e=618%7Cpc%7C&p=JF8BAKcJK1olXDYDZBoCUBVIMzZNXhpXVhgcBgcNVxRDXnBTTkRHA1ocDwlCUQ5LXl8LS0FWAgRDDTxZEjZHaG8ObChPPEByWFJtCEIeBGkJE1IcWzYCVV9dAUoUBWgBK2sVXDZJOl9tCEIeC20NHl8VVTYCU1hdC0ITBGcMHF0TbQECXW5dC0lMWz9KX2slbQUyU15UHE1lQj0cHSklbQcyV25tCkoWB2Y4Kw',
                title: '温馨家纺',
            },
            {
                key: 2,
                href: 'https://union-click.jd.com/jdc?e=618%7Cpc%7C&p=JF8BAKAJK1olXDYDZBoCUBVIMzZNXhpXVhgcHQYNVxJTU3BTTkRHA1ocZDYFCxdTA2lJfkVzFkR9FQE8eBRhag8EF2sVVA8FUl9VAUIRM28JGlscXAUEU1dtOEsWMyRmGmsVVA8KVltYDEsfM28PHVsWVAIEV19cCk4nBG8BK1sWX11aBBwZOHsnAF8PG1IBW3RDBkpbensnAl8LK2sXXAcGXW5t',
                title: '鲜花馆',
            },
            {
                key: 3,
                href: 'https://union-click.jd.com/jdc?e=618%7Cpc%7C&p=JF8BAKgJK1olXDYDZBoCUBVIMzZNXhpXVhgcBgcNVxRDXnBTTkRHA1ocFQoYFxJSXzI4BT1iDw5KKjw9AQNgRwQWWSZHL1wLAlJROEseCmgOGlMcVAAyVF9cCEIWAGkPEmslXQcyHzBcOEseCmcKHl4RXQ4yVFlbCEgeB2kNHF4TXTYFVFdtCEgVWDdYWR8lbTYBZFldAV8RcS5aD11nbTYDZF1tOEkWAmsBK2s',
                title: '宠物生活',
            },
            {
                key: 4,
                href: 'https://union-click.jd.com/jdc?e=618%7Cpc%7C&p=JF8BAK4JK1olXDYDZBoCUBVIMzZNXhpXVhgcBgcNVxRDXnBTTkRHA1ocVFleCVcXBGwMBAJQAVsyF1Y7f0hlBjBoWBN1FU9gUCsJcCxUc2MEK1scVAEEVVZUAU0nA24JG1IUXgAFXW5tCEonSAEJK1scVA4AUVtZCEMnA2gOG1gcWQAEXV9VCHsQA2Y4G1gXBl5SFhptOHsUM2gIEk8TL0dQQFgvOHsWM2w4K1kUXAILZG4',
                title: '家庭清洁',
            },
        ],
    },
    //热卖
    hot_buy_sale: {
        banners: [
            {
                key: 1,
                img: require('@/assets/images/gouwu/n95.png'),
                href: 'https://s.click.taobao.com/t?e=m%3D2%26s%3DoQqsnd0XLyEcQipKwQzePOeEDrYVVa64Dne87AjQPk9yINtkUhsv0FyIrP%2Fn3aCCmF3cATXfvYcvDECUuFAwKBTKRm%2F0KQqstIQvrk3Rn39Pw2IA3RN%2FnV9EeTtntI44hla2gak2AfYZCe1x%2BFKxUZ0xIgtFMPjyR%2FIYyRGoPsqKg5Pn9AFEvZGvt%2FjuRafBbuyJBMS0ytUU0zOFe%2BwxH2YzENhI9EhFEiM%2FlSG%2FbZTojfN%2BV7s7aTykTF5NBZmLxiXvDf8DaRs%3D&scm=null&pvid=null&app_pvid=59590_33.7.63.228_883_1671288535856&ptl=floorId%3A17741&originalFloorId%3A17741&app_pvid%3A59590_33.7.63.228_883_1671288535856&union_lens=lensId%3APUB%401671288535%4021073fe4_09c5_185208f3b4b_69e6%4002Qk1P757draVHpyI5ZOdSe',
                title: 'KN95防护口罩',
            },
            {
                key: 2,
                img: require('@/assets/images/gouwu/linjiapuzi.png'),
                href: 'https://s.click.taobao.com/t?e=m%3D2%26s%3DYJ8DoDyXzCscQipKwQzePOeEDrYVVa64Dne87AjQPk9yINtkUhsv0FyIrP%2Fn3aCC6qXaKBywLe4vDECUuFAwKBTKRm%2F0KQqstIQvrk3Rn39Pw2IA3RN%2FnV9EeTtntI44hla2gak2AfZXhkgbgmUp0%2FW7tSJMBkNIVNp7ubWq4Jf%2FSWOFjSxMDd1gZW6tlnyXukcGFQ66HuIU304tMofVLnIk3f91LdQrz6yNlCejocA787RRPgvyTL0Z%2FbOZSK95&scm=null&pvid=null&app_pvid=59590_33.7.32.186_858_1671288772560&ptl=floorId%3A17741&originalFloorId%3A17741&app_pvid%3A59590_33.7.32.186_858_1671288772560&union_lens=lensId%3APUB%401671288772%40210720ba_09ae_1852092d7ea_75b6%4002hjGwu6UfrFmEwMNnykYrG',
                title: '林家铺子水果罐头',
            }
        ],
        items_left: [
            {
                key: 1,
                href: 'https://ai.taobao.com/search/index.htm?_v=d&spm=a2e1u.27659560.search.1&key=口罩&pid=mm_131311678_42824299_264092963&union_lens=recoveryid%3A201_33.61.97.223_6115524_1671280191361%3Bprepvid%3A201_33.61.97.223_6115524_1671280191361',
                title: '口罩',
            },
            {
                key: 2,
                href: 'https://ai.taobao.com/search/index.htm?_v=d&spm=a2e1u.27655827.search.1&key=%E7%A9%BA%E8%B0%83&pid=mm_131311678_42824299_264092963&union_lens=recoveryid%3A201_33.61.97.223_6115524_1671280191361%3Bprepvid%3A201_33.8.154.255_6111492_1671287913959',
                title: '空调',
            },
            {
                key: 3,
                href: 'https://ai.taobao.com/search/index.htm?_v=d&spm=a2e1u.27655827.search.1&key=%E6%B4%97%E8%A1%A3%E6%9C%BA&pid=mm_131311678_42824299_264092963&union_lens=recoveryid%3A201_33.61.97.223_6115524_1671280191361%3Bprepvid%3A201_33.7.221.4_7183750_1671288045842',
                title: '洗衣机',
            },
            {
                key: 4,
                href: 'https://ai.taobao.com/search/index.htm?_v=d&spm=a2e1u.27655827.search.1&key=%E5%86%85%E8%A1%A3&pid=mm_131311678_42824299_264092963&union_lens=recoveryid%3A201_33.61.97.223_6115524_1671280191361%3Bprepvid%3A201_33.7.196.76_6110338_1671288084768',
                title: '内衣',
            },
        ],
        items_right: [
            {
                key: 1,
                href: 'https://ai.taobao.com/search/index.htm?_v=d&spm=a2e1u.27655827.search.1&key=%E6%9C%8D%E9%A5%B0&pid=mm_131311678_42824299_264092963&union_lens=recoveryid%3A201_33.61.97.223_6115524_1671280191361%3Bprepvid%3A201_33.44.57.94_6109352_1671288131959',
                title: '服饰',
            },
            {
                key: 2,
                href: 'https://ai.taobao.com/search/index.htm?_v=d&spm=a2e1u.27655827.search.1&key=%E7%BE%8E%E5%A6%86&pid=mm_131311678_42824299_264092963&union_lens=recoveryid%3A201_33.61.97.223_6115524_1671280191361%3Bprepvid%3A201_33.7.200.130_7255097_1671288165888',
                title: '美妆',
            },
            {
                key: 3,
                href: 'https://ai.taobao.com/search/index.htm?_v=d&spm=a2e1u.27655827.search.1&key=%E6%AF%8D%E5%A9%B4&pid=mm_131311678_42824299_264092963&union_lens=recoveryid%3A201_33.61.97.223_6115524_1671280191361%3Bprepvid%3A201_33.44.96.149_6109146_1671288207463',
                title: '母婴',
            },
            {
                key: 4,
                href: 'https://ai.taobao.com/search/index.htm?_v=d&spm=a2e1u.27655827.search.1&key=%E6%88%B7%E5%A4%96%E8%BF%90%E5%8A%A8&pid=mm_131311678_42824299_264092963&union_lens=recoveryid%3A201_33.61.97.223_6115524_1671280191361%3Bprepvid%3A201_33.50.201.183_6113818_1671288242110',
                title: '户外运动',
            },
        ],
    },
    //红包
    hot_buy_hongbao: {
        banner: {
            img: require('@/assets/images/gouwu/tianmaochaoshi.png'),
            href: 'https://s.click.taobao.com/t?union_lens=lensId%3AOPT%401661839745%402105398b_0bfd_182ed5e20e9_3d14%4001%3BeventPageId%3A20150318020009996&amp;e=m%3D2%26s%3DGqKu0n4Q1iEcQipKwQzePCperVdZeJviU%2F9%2F0taeK29yINtkUhsv0FQHuCBXapqh441YJiiF3HK%2BoSHEpDXDKOUOfgG3HIw4roTsnCHHxWVauesh7HXTsUr8%2BWogrtWzogfc9UhHI0lKaJluSZBCVstf3VMtO%2BcunxayKUIwUM7CbAGjvJeFYhvzeiceWLrTM7kxpdONUAK2ZesAkYM2UrHfv3%2BBYcPW%2FknKiHyNnnzx2TVyAt4KVV6jEit7Q%2BTTDDIVBsijSCjzGbClblec8wZuqbA1a%2BDE8Fn%2BjqVHOkwxEwcOVoIylM2jrT2jN9cyyULf0VHOuwXW2YNw9KX4KAFZ8SrSRJaTHR43IsevfNsNxtKElQIb6oY7q73JSNeHii3qlKczh5bGJe8N%2FwNpGw%3D%3D',
            title: 'xxx',
        },
        item: {
            key: 1,
            href: 'https://s.click.taobao.com/t?union_lens=lensId%3AOPT%401661839745%402105398b_0bfd_182ed5e20e9_3d14%4001%3BeventPageId%3A20150318020009996&amp;e=m%3D2%26s%3DGqKu0n4Q1iEcQipKwQzePCperVdZeJviU%2F9%2F0taeK29yINtkUhsv0FQHuCBXapqh441YJiiF3HK%2BoSHEpDXDKOUOfgG3HIw4roTsnCHHxWVauesh7HXTsUr8%2BWogrtWzogfc9UhHI0lKaJluSZBCVstf3VMtO%2BcunxayKUIwUM7CbAGjvJeFYhvzeiceWLrTM7kxpdONUAK2ZesAkYM2UrHfv3%2BBYcPW%2FknKiHyNnnzx2TVyAt4KVV6jEit7Q%2BTTDDIVBsijSCjzGbClblec8wZuqbA1a%2BDE8Fn%2BjqVHOkwxEwcOVoIylM2jrT2jN9cyyULf0VHOuwXW2YNw9KX4KAFZ8SrSRJaTHR43IsevfNsNxtKElQIb6oY7q73JSNeHii3qlKczh5bGJe8N%2FwNpGw%3D%3D',
            img:require('@/assets/images/gouwu/tianmaochaoshi.png'),
            title: '女装优惠红包',
            original_price: 9.99,
            price: 0.99,
        },
    },
}
const mutations = {}
const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
