<template>
  <div class="weather">
    <iframe allowtransparency="true" frameborder="0" width="210" height="51" scrolling="no" src="//tianqi.2345.com/plugin/widget/index.htm?s=2&z=3&t=1&v=2&d=1&bd=0&k=&f=&ltf=009944&htf=cc0000&q=0&e=1&a=1&c=54511&w=200&h=51&align=right"></iframe>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.weather{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
