<template>
  <div>
    <SectionHead v-bind="headConfig"/>
    <div class="wrapper clearfix">
      <div class="m_rec_tabs cncc">
        <div class="rec_tabs-bar">
          <a
              v-for="item in hot_buy_tabs_data"
              @mouseover="hoverTab(item)"
              :href="item.link"
              class="tabs-tab"
              :class="[currentTabType === item.type ? 'active' : '']"
              target="_blank"
              :key="item.key"
          >
            {{ item.text }}
          </a>
        </div>
        <div class="rec_tabs_content">
          <div class="tab_panel" v-show="currentTabType === 'tianmao'">
            <div>
              <div class="rec_base_banner">
                <div class="swiper mask_bg">
                  <div class="swiper-wrapper">
                    <div
                        class="swiper-scroller g_clr"
                        style="width: 283px; height: 108px"
                    >
                      <div class="swiper-slide on" style="z-index: 1">
                        <a href="https://s.click.taobao.com/t?union_lens=lensId%3AOPT%401661839745%402105398b_0bfd_182ed5e20e9_3d14%4001%3BeventPageId%3A20150318020009996&amp;e=m%3D2%26s%3DGqKu0n4Q1iEcQipKwQzePCperVdZeJviU%2F9%2F0taeK29yINtkUhsv0FQHuCBXapqh441YJiiF3HK%2BoSHEpDXDKOUOfgG3HIw4roTsnCHHxWVauesh7HXTsUr8%2BWogrtWzogfc9UhHI0lKaJluSZBCVstf3VMtO%2BcunxayKUIwUM7CbAGjvJeFYhvzeiceWLrTM7kxpdONUAK2ZesAkYM2UrHfv3%2BBYcPW%2FknKiHyNnnzx2TVyAt4KVV6jEit7Q%2BTTDDIVBsijSCjzGbClblec8wZuqbA1a%2BDE8Fn%2BjqVHOkwxEwcOVoIylM2jrT2jN9cyyULf0VHOuwXW2YNw9KX4KAFZ8SrSRJaTHR43IsevfNsNxtKElQIb6oY7q73JSNeHii3qlKczh5bGJe8N%2FwNpGw%3D%3D"
                           target="_blank">
                          <div
                              class="g_wh g_fimg"
                              loaded="1"
                              :style="{
                                  'backgroundImage':'url('+hot_buy_tmail.banner.img+')'
                                }
                                "
                          >
                          </div>
                          <span class="swiper-slide-title g_oh">天猫banner</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box_bottom_space"></div>
              <div class="goods_wrap g_clr">
                <div class="goods_list g_fl">
                  <a v-for="item in hot_buy_tmail.items_left"
                     :key="item.key"
                     :href="item.href"
                     class="goods_item"
                     target="_blank"
                     :data-src="item.img"
                     lazy="loaded"
                     :style="{
                                  'backgroundImage':'url('+item.img+')'
                                }
                                "
                  >{{ item.title }}</a>
                </div>
                <div class="goods_list g_fl">
                  <a v-for="item in hot_buy_tmail.items_right"
                     :key="item.key"
                     :href="item.href"
                     class="goods_item"
                     target="_blank"
                     :data-src="item.img"
                     lazy="loaded"
                     :style="{
                                  'backgroundImage':'url('+item.img+')'
                                }
                                "
                  >{{ item.title }}</a>
                </div>
              </div>
            </div>
            <div class="box_bottom_space"></div>
          </div>
          <div class="tab_panel" v-show="currentTabType === 'taobao'">
            <div>
              <div class="rec_base_banner">
                <div class="swiper mask_bg">
                  <div class="swiper-wrapper">
                    <div
                        class="swiper-scroller g_clr"
                        style="width: 283px; height: 108px"
                    >
                      <div class="swiper-slide on" style="z-index: 1">
                        <a
                            did="393"
                            dbd="Rhotsale"
                            dcd="102"
                            ddd="0"
                            dnd="淘宝banner"
                            :href="hot_buy_taobao.banner.href"
                            target="_blank"
                        >
                          <div
                              class="g_wh g_fimg"
                              loaded="1"
                              :style="{
                                  'backgroundImage':'url('+hot_buy_taobao.banner.img+')'
                                }
                                "
                          ></div>
                          <span class="swiper-slide-title g_oh"
                          >淘宝banner</span
                          ></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box_bottom_space"></div>
              <div class="goods_wrap g_clr tbwrap" did="394">
                <div class="swiper custom_swiper_link_goods">
                  <div class="swiper-wrapper">
                    <div
                        class="swiper-scroller g_clr"
                        style="width: 283px; height: 140px"
                    >
                      <div class="swiper-slide-template">
                        <a
                            :href="hot_buy_taobao.item.href"
                            class="goods-item report-liehu"
                            target="_blank"
                        ><img
                            :src="hot_buy_taobao.item.img"
                            alt=""
                            class="left-img g_wh"
                            loaded="1"
                        />
                          <div class="right-text">
                            <h3 class="goods-title">
                              <p class="g_oh_two">
                                {{ hot_buy_taobao.item.title }}
                              </p>
                            </h3>
                            <p class="save-price">
                              <span>￥{{ hot_buy_taobao.item.original_price }}</span>
                            </p>
                            <p class="g_oh final-price">
                              到手价<strong class="price">￥{{ hot_buy_taobao.item.price }}</strong>
                            </p>
                            <div class="buy-btn">领券购买</div>
                          </div>
                        </a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box_bottom_space"></div>
          </div>
          <div class="tab_panel" v-show="currentTabType === 'haohuo'">
            <div>
              <div class="goods_wrap g_clr">
                <div class="goods_img_list">
                  <a v-for="item in hot_buy_good.banners"
                     :key="item.key"
                     :dnd="item.title"
                     :href="item.href"
                     target="_blank"
                  >
                    <img class="g_wh"
                         :src="item.img"
                         loaded="1"
                    />
                    <span>
                      {{ item.title }}
                    </span>
                  </a>
                </div>
                <div class="box_bottom_space"></div>
                <div class="goods_list g_fl">
                  <a  v-for="item in hot_buy_good.items_left"
                      :key="item.key"
                      :href="item.href"
                      class="goods_item"
                      target="_blank"
                  >{{ item.title }}</a>
                </div>
                <div class="goods_list g_fl">
                  <a  v-for="item in hot_buy_good.items_right"
                      :key="item.key"
                      :href="item.href"
                      class="goods_item"
                      target="_blank"
                  >{{ item.title }}</a>
                </div>
              </div>
            </div>
            <div class="box_bottom_space"></div>
          </div>
          <div class="tab_panel" v-show="currentTabType === 'remai'">
            <div>
              <div class="goods_wrap g_clr">
                <div class="goods_img_list">
                  <a v-for="item in hot_buy_sale.banners"
                     :key="item.key"
                     :dnd="item.title"
                     :href="item.href"
                     target="_blank"
                  >
                    <img class="g_wh"
                         :src="item.img"
                         loaded="1"
                    />
                    <span>
                      {{ item.title }}
                    </span>
                  </a>
                </div>
                <div class="box_bottom_space"></div>
                <div class="goods_list g_fl">
                  <a  v-for="item in hot_buy_sale.items_left"
                      :key="item.key"
                      :href="item.href"
                      class="goods_item"
                      target="_blank"
                  >{{ item.title }}</a>
                </div>
                <div class="goods_list g_fl">
                  <a  v-for="item in hot_buy_sale.items_right"
                      :key="item.key"
                      :href="item.href"
                      class="goods_item"
                      target="_blank"
                  >{{ item.title }}</a>
                </div>
              </div>
            </div>
            <div class="box_bottom_space"></div>
          </div>
          <div class="tab_panel" v-show="currentTabType === 'hongbao'">
            <div class="rec_base_banner">
              <div class="swiper mask_bg">
                <div class="swiper-wrapper">
                  <div
                      class="swiper-scroller g_clr"
                      style="width: 283px; height: 108px"
                  >
                    <div class="swiper-slide on" style="z-index: 1">
                      <a
                          did="393"
                          dbd="Rhotsale"
                          dcd="102"
                          ddd="0"
                          dnd="淘宝banner"
                          :href="hot_buy_taobao.banner.href"
                          target="_blank"
                      >
                        <div
                            class="g_wh g_fimg"
                            loaded="1"
                            :style="{
                                  'backgroundImage':'url('+hot_buy_taobao.banner.img+')'
                                }
                                "
                        ></div>
                        <span class="swiper-slide-title g_oh"
                        >淘宝banner</span
                        ></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box_bottom_space"></div>
            <div>
              <div class="rec_base_banner">
                <div class="swiper custom_swiper_link_goods">
                  <div class="swiper-wrapper">
                    <div
                        class="swiper-scroller g_clr"
                        style="width: 283px; height: 140px"
                    >
                      <div class="swiper-slide-template">
                        <a
                            :href="hot_buy_hongbao.item.href"
                            target="_blank"
                            class="goods-item report-liehu"
                        ><img
                            :src="hot_buy_hongbao.item.img"
                            alt=""
                            class="left-img g_wh"
                            loaded="1"
                        />
                          <div class="right-text">
                            <h3 class="goods-title">
                              <p class="g_oh_two">
                                {{ hot_buy_hongbao.item.title }}
                              </p>
                            </h3>
                            <p class="save-price">
                              <span>￥{{ hot_buy_hongbao.item.original_price }}</span>
                            </p>
                            <p class="g_oh final-price">
                              到手价<strong class="price">￥{{ hot_buy_hongbao.item.price }}</strong>
                            </p>
                            <div class="buy-btn">领券购买</div>
                          </div>
                        </a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box_bottom_space"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHead from "./SectionHead";
import {newsDataState} from '@/mixins';

const HeadIcon = require("@/assets/images/rightbox/hotbuy.png");

export default {
  name: "HotBuy",
  mixins: [newsDataState],
  components: {
    SectionHead,
  },
  data() {
    return {
      headConfig: {
        icon: HeadIcon,
        link: "",
        title: "热卖",
      },
      currentTabType: "tianmao",
      data: [],
    };
  },
  methods: {
    hoverTab(item) {
      this.currentTabType = item.type;
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 4px;
}

.iconn {
  background-image: url(@/assets/images/rightbox/hotbuy-sprite.png);
}

.icon_ad_22 {
  width: 20px;
  height: 10px;
  background-position: -338px -118px;
}

.icon_ad_22,
.icon_ad_32 {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_ad_32 {
  width: 32px;
  height: 32px;
  background-position: -138px -77px;
}

.icon_add_grey_11 {
  background-position: -80px -214px;
}

.icon_add_grey_11,
.icon_add_white_11 {
  display: inline-block;
  width: 11px;
  height: 11px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_add_white_11 {
  background-position: -91px -214px;
}

.icon_arrow_down_12 {
  display: inline-block;
  width: 12px;
  height: 8px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -308px -132px;
}

.icon_arrow_down_16 {
  display: inline-block;
  width: 16px;
  height: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -206px -188px;
}

.icon_arrow_down_8 {
  display: inline-block;
  width: 8px;
  height: 6px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -350px -87px;
}

.icon_arrow_left_24.png {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -302px -70px;
}

.icon_arrow_left_8 {
  display: inline-block;
  width: 8px;
  height: 12px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -196px -109px;
}

.icon_arrow_right_11 {
  display: inline-block;
  width: 11px;
  height: 9px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -188px -175px;
}

.icon_arrow_right_15 {
  display: inline-block;
  width: 15px;
  height: 32px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 0 -240px;
}

.icon_arrow_right_24 {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -326px -70px;
}

.icon_arrow_right_8 {
  display: inline-block;
  width: 8px;
  height: 12px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -44px -226px;
}

.icon_arrow_up_12 {
  width: 12px;
  height: 8px;
  background-position: -300px -132px;
}

.icon_arrow_up_12,
.icon_btn_bg {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_btn_bg {
  width: 162px;
  height: 30px;
  background-position: 0 -158px;
}

.icon_close_14 {
  width: 14px;
  height: 14px;
  background-position: -168px -109px;
}

.icon_close_14,
.icon_close_30 {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_close_30 {
  width: 30px;
  height: 30px;
  background-position: -160px -126px;
}

.icon_close_8 {
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -358px -118px;
}

.icon_close_active {
  background-position: -350px -70px;
}

.icon_close_active,
.icon_close_default {
  display: inline-block;
  width: 17px;
  height: 17px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_close_default {
  background-position: -350px -94px;
}

.icon_close_hover {
  display: inline-block;
  width: 17px;
  height: 17px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -188px -158px;
}

.icon_constellation_v2_hover {
  background-position: -230px -70px;
}

.icon_constellation_v2,
.icon_constellation_v2_hover {
  display: inline-block;
  width: 72px;
  height: 24px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_constellation_v2 {
  background-position: -230px -94px;
}

.icon_constellation {
  width: 38px;
  height: 38px;
  background-position: -325px -148px;
}

.icon_constellation,
.icon_coupon {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_coupon {
  width: 162px;
  height: 30px;
  background-position: -205px 0;
}

.icon_email {
  width: 12px;
  height: 10px;
  background-position: -222px -188px;
}

.icon_email,
.icon_guess_like {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_guess_like {
  width: 25px;
  height: 78px;
  background-position: -205px -70px;
}

.icon_hot_rank {
  display: inline-block;
  width: 22px;
  height: 25px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -22px -214px;
}

.icon_info_flow_more_tips {
  display: inline-block;
  width: 160px;
  height: 32px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 0 -126px;
}

.icon_jinxuan {
  width: 22px;
  background-position: 0 -214px;
}

.icon_jinxuan,
.icon_like_active {
  display: inline-block;
  height: 26px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_like_active {
  width: 26px;
  background-position: -162px -158px;
}

.icon_like_hover {
  background-position: 0 -188px;
}

.icon_like_hover,
.icon_like_normal {
  display: inline-block;
  width: 26px;
  height: 26px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_like_normal {
  background-position: -26px -188px;
}

.icon_loading_32 {
  width: 32px;
  height: 32px;
  background-position: -170px -77px;
}

.icon_loading_32,
.icon_logo_360_138 {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_logo_360_138 {
  width: 138px;
  height: 42px;
  background-position: 0 0;
}

.icon_logo_360_24 {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -302px -94px;
}

.icon_logo_baidu_138 {
  display: inline-block;
  width: 138px;
  height: 42px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 0 -42px;
}

.icon_logo_baidu_24 {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -326px -94px;
}

.icon_logo_baidu_40 {
  width: 40px;
  background-position: -325px -30px;
}

.icon_logo_baidu,
.icon_logo_baidu_40 {
  display: inline-block;
  height: 40px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_logo_baidu {
  width: 115px;
  background-position: -205px -30px;
}

.icon_logo_iciba_40 {
  background-position: -205px -148px;
}

.icon_logo_iciba_40,
.icon_logo_ks_40 {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_logo_ks_40 {
  background-position: -245px -148px;
}

.icon_logo_sougou_138 {
  display: inline-block;
  width: 138px;
  height: 42px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 0 -84px;
}

.icon_logo_sougou_24 {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -130px -188px;
}

.icon_logo_taobao_40 {
  width: 40px;
  height: 40px;
  background-position: -285px -148px;
}

.icon_logo_taobao_40,
.icon_next_8 {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_next_8 {
  width: 8px;
  height: 15px;
  background-position: -15px -240px;
}

.icon_play {
  width: 67px;
  height: 77px;
  background-position: -138px 0;
}

.icon_play,
.icon_police {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_police {
  width: 20px;
  height: 20px;
  background-position: -154px -188px;
}

.icon_prev_8 {
  width: 8px;
  height: 15px;
  background-position: -23px -240px;
}

.icon_prev_8,
.icon_recommend_red {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_recommend_red {
  width: 14px;
  height: 14px;
  background-position: -182px -109px;
}

.icon_refresh_12 {
  width: 12px;
  height: 12px;
  background-position: -44px -214px;
}

.icon_refresh_12,
.icon_refresh_14 {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_refresh_14 {
  width: 14px;
  height: 14px;
  background-position: -190px -126px;
}

.icon_refresh_active_12 {
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -56px -214px;
}

.icon_sm_coupon {
  display: inline-block;
  width: 78px;
  height: 22px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -230px -118px;
}

.icon_taobao_30 {
  background-position: -308px -118px;
}

.icon_taobao_30,
.icon_tmall_30 {
  display: inline-block;
  width: 30px;
  height: 14px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_tmall_30 {
  background-position: -138px -109px;
}

.icon_tv_12 {
  width: 12px;
  height: 12px;
  background-position: -68px -214px;
}

.icon_tv_12,
.icon_tv_18 {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_tv_18 {
  width: 18px;
  height: 18px;
  background-position: -174px -188px;
}

.icon_unlike_active {
  background-position: -52px -188px;
}

.icon_unlike_active,
.icon_unlike_hover {
  display: inline-block;
  width: 26px;
  height: 26px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_unlike_hover {
  background-position: -78px -188px;
}

.icon_unlike_normal {
  display: inline-block;
  width: 26px;
  height: 26px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: -104px -188px;
}

.icon_voice_close_14 {
  background-position: -190px -140px;
}

.icon_voice_close_14,
.icon_voice_open_14 {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon_voice_open_14 {
  background-position: -192px -188px;
}

.m_rec_tabs {
  font-size: 14px;
  color: #1a75ff;
}

.m_rec_tabs .tabs-tab {
  position: relative;
  display: inline-block;
  width: 44px;
  padding: 4px 0 6px 0;
  margin-right: 2px;
  color: #5f6776;
  text-align: center;
  cursor: pointer;
  background: #fff;
}

.m_rec_tabs .tabs-tab.active {
  color: #1a75ff;
}

.m_rec_tabs .tabs-tab.active:after {
  position: absolute;
  right: 4px;
  bottom: 2px;
  left: 4px;
  content: " ";
  border-bottom: 2px solid #1a75ff;
}

.m_rec_tabs .rec_tabs_content {
  padding-top: 8px;
}

.tab_panel {
  width: 100%;
  height: 100%;
}

.goods_img_list {
  height: 122px;
  overflow: hidden;
  font-size: 14px;
}

.goods_img_list a {
  position: relative;
  display: inline-block;
  width: 115px;
  height: 122px;
  background: #f5f5f5;
}

.goods_img_list a span {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 28px;
  line-height: 28px;
  color: #fff;
  text-align: center;
  background-color: rgba(26, 35, 49, 0.7);
}

.goods_img_list a:nth-child(2) {
  margin-left: 10px;
}

.goods_list {
  width: 50%;
  height: 120px;
}

.goods_img_list {
  height: 122px;
  overflow: hidden;
  font-size: 14px;
}

.goods_img_list a {
  position: relative;
  display: inline-block;
  width: 115px;
  height: 122px;
  background: #f5f5f5;
}

.goods_img_list a span {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 28px;
  line-height: 28px;
  color: #fff;
  text-align: center;
  background-color: rgba(26, 35, 49, 0.7);
}

.goods_img_list a:nth-child(2) {
  margin-left: 10px;
}

.goods_list {
  width: 50%;
  height: 120px;
}

.goods_item {
  position: relative;
  display: block;
  height: 30px;
  padding-left: 20px;
  overflow: hidden;
  line-height: 30px;
  background-repeat: no-repeat;
  background-position: 0;
  background-size: 16px 16px;
}

.g_fl {
  float: left;
}

.custom_swiper_link_goods .swiper-slide-template .goods-item .right-text {
  float: right;
  width: 115px;
  font-weight: 400;
}

.custom_swiper_link_goods
.swiper-slide-template
.goods-item
.right-text
.goods-title {
  height: 38px;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  color: #333;
}

.custom_swiper_link_goods
.swiper-slide-template
.goods-item
.right-text
.goods-title:hover {
  color: #fc1747;
}

.custom_swiper_link_goods
.swiper-slide-template
.goods-item
.right-text
.save-price {
  height: 18px;
  font-size: 12px;
  color: #999;
}

.custom_swiper_link_goods
.swiper-slide-template
.goods-item
.right-text
.save-price
span {
  position: relative;
}

.custom_swiper_link_goods
.swiper-slide-template
.goods-item
.right-text
.save-price
span:after {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  background-color: #999;
}

.custom_swiper_link_goods
.swiper-slide-template
.goods-item
.right-text
.final-price {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #fc1747;
}

.custom_swiper_link_goods
.swiper-slide-template
.goods-item
.right-text
.final-price
.price {
  font-size: 20px;
  font-weight: 700;
}

.custom_swiper_link_goods
.swiper-slide-template
.goods-item
.right-text
.buy-btn {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #fafafa;
  text-align: center;
  background-color: #fc1747;
}

.custom_swiper_link_goods .goods-item .left-img {
  width: 115px;
  height: 110px;
  margin-right: 10px;
}

.custom_swiper_link_goods .swiper-slide-template .goods-item {
  position: absolute;
  left: 0;
}

.swiper-slide a {
  position: relative;
  display: block;
  height: 100%;
}

.g_fimg {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.g_wh {
  width: 100%;
}

.g_h,
.g_wh {
  height: 100%;
}

.box_bottom_space {
  height: 8px;
}
</style>
