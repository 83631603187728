<template>
    <ul id="xxl-news" class="xxl-news" style="min-height: 864px">
      <!-- 左右栏轮播 -->
      <Carousel></Carousel>

      <!-- 信息流 -->
      <template>
        <InfoFlowItem v-for="(item, index) in newsData" :key="index" :data="item" :index="index"></InfoFlowItem>
      </template>
      <li id="flow-loading" v-if="flowLoading" class="load-status loading">加载中...</li>

      <!-- 用于被监听是否到底部的辅助节点 -->
      <li id="flow-bottom-helper" class="flow-bottom-helper"></li>
    </ul>
</template>

<script>
import Carousel from './Carousel'
import InfoFlowItem from './InfoFlowItem'
import { newsDataState } from '@/mixins';
import { isInViePortOfOne } from '@/utils';

export default {
  name: 'LargeNews',
  mixins: [newsDataState],
  components: {
    Carousel,
    InfoFlowItem
  },
  data() {
    return {
      contentEl: null,
      bottomEl: null,
      page: 2,
    }
  },
  mounted() {
    this.listen()
  },

  methods: {
    async listen() {
      const contentEl = this.contentEl || document.querySelector('#xxl-news')
      const bottomEl = this.loadingEl || document.querySelector('#flow-bottom-helper')
      this.contentEl = contentEl
      this.bottomEl = bottomEl


      document.addEventListener('scroll', async () => {
        if(isInViePortOfOne(bottomEl)) {
          if(this.flowLoading === true) return
          this.addPage()
          // 这里加载数据, 下面是模拟
          this.addNewsData()
          // setTimeout(() => {
          //   this.addNewsData({page: this.page})
          //   this.setFlowLoading(false)
          // }, 1500)

        }
      })
    },
  }
};
</script>

<style>

.flow-bottom-helper{
  height: 5px;
}
.xxl-news {
  margin-top: -31px;
  padding: 0 15px 5px;
  position: relative;
  width: 650px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.xxl-news .observer-ele {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
}
.xxl-news .xxl-news-ppt {
  padding-bottom: 9px;
}
.xxl-news .ppt-left {
  width: 220px;
  height: 180px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.xxl-news .ppt-hot {
  position: absolute;
  top: -14px;
  left: 0;
  font-size: 48px;
}
.xxl-news .ppt-right {
  width: 390px;
  display: inline-block;
  vertical-align: top;
}
.xxl-news .ppt-img {
  display: block;
  width: 100%;
  height: 180px;
  background-color: #d8d8d8;
  border-radius: 8px;
  overflow: hidden;
}
.xxl-news .ppt-img > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.xxl-news .ppt-title {
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  color: #222;
  padding-left: 20px;
  position: relative;
}
.xxl-news .ppt-title::before {
  content: "";
  position: absolute;
  left: 6px;
  top: 50%;
  margin-top: -2px;
  width: 4px;
  height: 4px;
  background-color: #d7d7d7;
  border-radius: 50%;
}
.xxl-news .ppt-title.bold {
  font-weight: bold;
}
.xxl-news .ppt-title.bold::before {
  background-color: #ee4343;
}
.xxl-news .ppt-title:hover {
  color: #f30;
  text-decoration: underline;
}
.xxl-news .ppt-item.active .ppt-title {
  color: #f30;
}
.xxl-news .ppt-item.active .ppt-title::before {
  width: 5px;
  height: 5px;
  background-size: 5px 5px;
  border-radius: 0;
}
.xxl-news .ppt-item:not(:last-child) {
  margin-bottom: 8px;
}
.xxl-news .news-link {
  display: block;
  line-height: 1;
}
.xxl-news .xxl-news-item:not(.four-news):hover {
  background-color: #f6f8fa;
}
.xxl-news .xxl-news-item.single-pic .news-right {
  padding-top: 0;
  height: 108px;
  position: relative;
}
.xxl-news .xxl-news-item.single-pic .news-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  max-height: 48px;
  height: auto;
}
.xxl-news .xxl-news-item.single-pic .news-info {
  margin-top: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 12px;
  line-height: 1;
}
.xxl-news .xxl-news-item.single-pic .news-link {
  padding: 20px 18px 20px 0;
}
.xxl-news .xxl-news-item.single-pic .news-img {
  margin-right: 16px;
}
.xxl-news .xxl-news-item.single-pic.video .news-img::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  /*background: url(//www-stream.2345cdn.net/s/new2345/img/icon_play2f1d9cd83f6e242ef01399e918e887217.png)*/
  /*  no-repeat center;*/
  background-size: contain;
}
.xxl-news .xxl-news-item.single-pic.video .news-title {
  position: relative;
  display: block;
  text-indent: 24px;
}
.xxl-news .xxl-news-item.single-pic.video .news-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 20px;
  height: 20px;
  /*background: url(//www-stream.2345cdn.net/s/new2345/img/icon_title_play4fd53e384b8134b52cb1e19a87cd2a55.png)*/
  /*  no-repeat center;*/
}
.xxl-news .xxl-news-item.single-pic.video.dy .news-title {
  text-indent: 52px;
}
.xxl-news .xxl-news-item.single-pic.video.dy .news-title::before {
  top: 1px;
  width: 48px;
  height: 21px;
  /*background-image: url(//www-stream.2345cdn.net/s/new2345/img/tag_douyin095a883c92d3a71f36f75cc1ecc33c16.png);*/
  background-size: contain;
}
.xxl-news .xxl-news-item.four-pics .news-link {
  padding: 20px 0;
}
.xxl-news .xxl-news-item.four-pics .news-img-list {
  margin-top: 8px;
}
.xxl-news .xxl-news-item.four-pics .news-img:not(:last-child) {
  margin-right: 16px;
}
.xxl-news .xxl-news-item.four-news {
  padding: 20px 0;
}
.xxl-news .xxl-news-ppt,
.xxl-news .xxl-news-item,
.xxl-news .xxl-zk-1,
.xxl-news .xxl-zk-2 {
  position: relative;
}
.xxl-news .xxl-news-ppt::after,
.xxl-news .xxl-news-item::after,
.xxl-news .xxl-zk-1::after,
.xxl-news .xxl-zk-2::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #f4f4f4;
  border-radius: 8px;
}
.xxl-news .xxl-news-ppt::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  border-radius: 8px;
  /*background: url(//www-stream.2345cdn.net/s/new2345/img/fengexian625cf4627f9f845c7fdf531e64900e13.png)*/
  /*  no-repeat 0 0;*/
  background-size: 100% auto;
}
.xxl-news .four-news-item {
  display: inline-block;
  width: 192px;
  height: 184px;
  background-color: #d8d8d8;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.xxl-news .four-news-item .news-link {
  height: 100%;
}
.xxl-news .four-news-item:not(:last-child) {
  margin-right: 16px;
}
.xxl-news .four-news-item img {
  width: 100%;
  height: 108px;
}
.xxl-news .four-news-item .news-duration1 {
  font-size: 12px;
  position: absolute;
  right: 8px;
  bottom: 84px;
  width: 40px;
  height: 16px;
  line-height: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  color: #fff;
  text-align: center;
}
.xxl-news .four-news-item .icon-play {
  width: 20px;
  height: 18px;
  vertical-align: bottom;
}
.xxl-news .news-layer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 102px;
  border-radius: 8px;
  padding: 20px 12px 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.xxl-news .news-layer .news-info {
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.75);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0;
}
.xxl-news .news-layer .news-title {
  color: #fff;
  margin-top: 4px;
  font-size: 14px;
  font-weight: bold;
  height: 54px;
  line-height: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: normal;
}
.xxl-news .four-news .news-layer .news-title {
  color: #fff !important;
}
.xxl-news .four-news-0 .news-layer {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(54, 61, 126, 0)),
    color-stop(20%, #363d7e),
    to(#363d7e)
  );
  background-image: linear-gradient(
    180deg,
    rgba(54, 61, 126, 0) 0%,
    #363d7e 20%,
    #363d7e 100%
  );
}
.xxl-news .four-news-1 .news-layer {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(50, 78, 82, 0)),
    color-stop(20%, #324e52),
    to(#324e52)
  );
  background-image: linear-gradient(
    180deg,
    rgba(50, 78, 82, 0) 0%,
    #324e52 20%,
    #324e52 100%
  );
}
.xxl-news .four-news-2 .news-layer {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(44, 60, 97, 0)),
    color-stop(20%, #2c3c61),
    to(#2c3c61)
  );
  background-image: linear-gradient(
    180deg,
    rgba(44, 60, 97, 0) 0%,
    #2c3c61 20%,
    #2c3c61 100%
  );
}
.xxl-news .four-news-3 .news-layer {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(112, 63, 53, 0)),
    color-stop(20%, #703f35),
    to(#703f35)
  );
  background-image: linear-gradient(
    180deg,
    rgba(112, 63, 53, 0) 0%,
    #703f35 20%,
    #703f35 100%
  );
}
.xxl-news .news-img {
  width: 192px;
  height: 108px;
  display: inline-block;
  background-color: #d8d8d8;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.xxl-news .news-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.xxl-news .news-img-bg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.xxl-news .news-duration {
  position: absolute;
  right: 9px;
  bottom: 8px;
  width: 50px;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 13px;
  color: #fff;
  text-align: center;
}
.xxl-news .news-right {
  display: inline-block;
  width: 390px;
  vertical-align: top;
  padding-top: 4px;
}
.xxl-news .news-title {
  font-size: 18px;
  height: 24px;
  line-height: 24px;
  /*font-weight: bold;*/
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #222;
}
.xxl-news .news-title:hover {
  color: #f30 !important;
  text-decoration: underline;
}
.xxl-news .news-desc {
  margin-top: 8px;
  color: #999;
  font-size: 14px;
  height: 38px;
  line-height: 19px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.xxl-news .news-info {
  margin-top: 17px;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #999;
}
.xxl-news .news-info > span:not(:last-child) {
  margin-right: 16px;
}
.xxl-news .xxl-zk-1 {
  padding: 20px 0;
}
.xxl-news .xxl-zk-1 video {
  background-color: #000;
}
.xxl-news .xxl-zk-1 .zk1-item {
  display: inline-block;
  width: 400px;
  height: 180px;
  overflow: hidden;
  position: relative;
}
.xxl-news .xxl-zk-1 .zk1-item:first-child {
  margin-right: 16px;
}
.xxl-news .xxl-zk-1 .news-link {
  width: 100%;
  height: 100%;
}
.xxl-news .xxl-zk-1 .news-img {
  width: 100%;
  height: 100%;
}
.xxl-news .xxl-zk-1 .zk-layer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 11px 14px 8px;
  height: 38px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.06)),
    to(#000)
  );
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, #000 100%);
  border-radius: 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.xxl-news .xxl-zk-1 .zk-title {
  font-size: 14px;
  height: 19px;
  line-height: 19px;
  text-align: center;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.xxl-news .xxl-zk-1 .zk-title:hover {
  color: #f30;
  text-decoration: underline;
}
.xxl-news .xxl-zk-1 .ad-mark {
  position: absolute;
  right: 14px;
  bottom: 1px;
}
.xxl-news .xxl-zk-2 video {
  background-color: #000;
}
.xxl-news .xxl-zk-2 .news-link {
  padding: 20px 0;
  position: relative;
}
.xxl-news .xxl-zk-2:not(.four-news):hover {
  background-color: #f6f8fa;
}
.xxl-news .xxl-zk-2 .news-img {
  width: 400px;
  height: 180px;
  margin-right: 16px;
}
.xxl-news .xxl-zk-2 .news-right {
  width: 408px;
  padding-top: 6px;
}
.xxl-news .xxl-zk-2 .news-title {
  height: 84px;
  line-height: 28px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: normal;
}
.xxl-news .xxl-zk-2 .news-title:hover {
  color: #f30;
}
.xxl-news .xxl-zk-2 .zk-desc {
  margin-top: 58px;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.xxl-news .xxl-zk-2 .ad-mark {
  position: absolute;
  bottom: 22px;
  right: 6px;
}
.xxl-news .xxl-content-wrap {
  margin: 0 -20px;
}
.xxl-news .load-status {
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
}
.xxl-news .load-status.loading {
  color: #666;
}
.xxl-news .load-status.load-error {
  color: #0078ff;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fff),
    color-stop(50%, #eff7ff),
    to(#fff)
  );
  background-image: linear-gradient(90deg, #fff 0%, #eff7ff 50%, #fff 100%);
}
.xxl-news .load-status.load-error span {
  cursor: pointer;
  display: inline-block;
}
.xxl-news .load-status.load-error span:hover {
  text-decoration: underline;
}
.xxl-news .xxl-ad-360.single-pic .ad-mark {
  position: absolute;
  right: 0;
  bottom: 0;
}
.xxl-news .xxl-ad-360.four-pics {
  padding: 20px 0;
}
.xxl-news .xxl-ad-360.four-pics .news-link {
  padding: 0;
}
.xxl-news .xxl-ad-360.four-pics .ad-mark {
  position: absolute;
  right: 14px;
  bottom: 20px;
}
.xxl-news .xxl-ad-360.video video {
  background-color: #000;
}
.xxl-news .xxl-ad-360 .icon-mute {
  position: absolute;
  right: 10px;
  bottom: 5px;
  width: 20px;
  height: 20px;
}
.xxl-news .xxl-ad-360 .icon-mute-on {
  background: url(//www-stream.2345cdn.net/s/new2345/img/mute-on2d564853b8faa96f43b935725fec3a71.png)
    no-repeat center;
  background-size: 20px;
}
.xxl-news .xxl-ad-360 .icon-mute-off {
  background: url(//www-stream.2345cdn.net/s/new2345/img/mute-off88f5ff0b5cb90ca194badfeea02ff25b.png)
    no-repeat center;
  background-size: 20px;
}
.xxl-news .xxl-ad-360.big-pic .news-img {
  height: 133px;
}
.xxl-news .xxl-ad-360.big-pic .zk-desc {
  margin-top: 11px;
}
.xxl-news .game-tab {
  padding-bottom: 10px;
}
.xxl-news .game-tab .game-item {
  display: inline-block;
}
.xxl-news .game-tab .game-layer {
  width: 100%;
  height: 130px;
  padding-top: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0 0 8px 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}
.xxl-news .game-tab .game-layer-0 {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(45, 102, 131, 0)),
    color-stop(50%, #2d6683),
    to(#2d6683)
  );
  background-image: linear-gradient(
    180deg,
    rgba(45, 102, 131, 0) 0%,
    #2d6683 50%,
    #2d6683 100%
  );
}
.xxl-news .game-tab .game-layer-1 {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(126, 57, 96, 0)),
    color-stop(50%, #7e3960),
    to(#7e3960)
  );
  background-image: linear-gradient(
    180deg,
    rgba(126, 57, 96, 0) 0%,
    #7e3960 50%,
    #7e3960 100%
  );
}
.xxl-news .game-tab .game-layer-2 {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(51, 49, 96, 0)),
    color-stop(50%, #333160),
    to(#333160)
  );
  background-image: linear-gradient(
    180deg,
    rgba(51, 49, 96, 0) 0%,
    #333160 50%,
    #333160 100%
  );
}
.xxl-news .game-tab .game-layer-3 {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(98, 39, 39, 0)),
    color-stop(50%, #622727),
    to(#622727)
  );
  background-image: linear-gradient(
    180deg,
    rgba(98, 39, 39, 0) 0%,
    #622727 50%,
    #622727 100%
  );
}
.xxl-news .game-tab .game-img {
  border-radius: 16px;
  overflow: hidden;
}
.xxl-news .game-tab .game-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.xxl-news .game-tab .news-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
}
.xxl-news .game-tab-0 {
  margin-right: -5px;
}
.xxl-news .game-tab-0 .news-link {
  position: relative;
}
.xxl-news .game-tab-0 .game-img {
  width: 198px;
  height: 264px;
}
.xxl-news .game-tab-0 .news-title {
  color: #fff;
  padding: 0 16px;
}
.xxl-news .game-tab-0 .begin-btn {
  width: 116px;
  height: 36px;
  margin: 8px auto 0;
  background-image: url(//www-stream.2345cdn.net/s/new2345/img/btn_youxi_normalae704c6896b794b179062b5d2c1aefdc.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.xxl-news .game-tab-0 .begin-btn:hover {
  background-image: url(//www-stream.2345cdn.net/s/new2345/img/btn_youxi_hover8a7bb3df0141759f7aa10838f533dde1.png);
}
.xxl-news .game-tab-0 .game-item:not(:last-child) {
  margin-right: 14px;
}
.xxl-news .game-tab-0 .game-item:first-child .game-img {
  border-top-left-radius: 30px;
}
.xxl-news .game-tab-0 .game-item:last-child .game-img,
.xxl-news .game-tab-0 .game-item:last-child .game-layer {
  border-bottom-right-radius: 30px;
}
.xxl-news .game-tab-1 {
  margin-top: 16px;
}
.xxl-news .game-tab-1 .game-item {
  width: 69px;
}
.xxl-news .game-tab-1 .game-item:not(:last-child) {
  margin-right: 26px;
}
.xxl-news .game-tab-1 .game-img {
  width: 69px;
  height: 69px;
}
.xxl-news .game-tab-1 .news-title {
  font-size: 14px;
  height: 19px;
  line-height: 19px;
  margin-top: 2px;
  color: #222;
  text-align: center;
}
.ie9 .xxl-news .news-layer {
  height: 90px;
  border-radius: 0 0 8px 8px;
  padding-top: 8px;
}
.ie9 .xxl-news .four-news-0 .news-layer {
  background: #363d7e;
}
.ie9 .xxl-news .four-news-1 .news-layer {
  background: #324e52;
}
.ie9 .xxl-news .four-news-2 .news-layer {
  background: #2c3c61;
}
.ie9 .xxl-news .four-news-3 .news-layer {
  background: #703f35;
}
.ie9 .xxl-news .game-tab .game-layer {
  height: 104px;
  padding-top: 14px;
}
.ie9 .xxl-news .game-tab .game-layer-0 {
  background: #2d6683;
}
.ie9 .xxl-news .game-tab .game-layer-1 {
  background: #7e3960;
}
.ie9 .xxl-news .game-tab .game-layer-2 {
  background: #333160;
}
.ie9 .xxl-news .game-tab .game-layer-3 {
  background: #622727;
}
.ie9 .xxl-news .load-status.load-error {
  background-color: #eff7ff;
}

.comp-ppt{
  width: 100%;
  height: 100%;
}
.comp-ppt .ppt-swiper-wrap{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.comp-ppt .ppt-swiper-item{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 200ms;
  transition: all 200ms;
}
.comp-ppt .ppt-swiper-item.active{
  opacity: 1;
  z-index: 1;
}
.comp-ppt .ppt-swiper-pagination{
  text-align: center;
  margin-top: 6px;
  font-size: 0;
}
.comp-ppt .ppt-swiper-pagination-bar{
  width: 4px;
  height: 4px;
  background-color: #d8d8d8;
  border-radius: 50%;
  display: inline-block;
  margin: 0 3px;
  cursor: pointer;
}
.comp-ppt .ppt-swiper-pagination-bar.active{
  width: 12px;
  background-color: #f30;
  border-radius: 8px;
}
</style>
