<template>
  <ContentWrap class="custom-nav">
    <div class="content">
      <div class="header" style="padding-top: 5px">
        <div class="slider-menu" id="smenu">
          <ul class="nav">
            <li class="anchor"></li>
            <li
                class="nav-item"
                v-for="(item, index) in navOptions"
                @click="changeNav(index, item.name)"
                :key="item.name"
            >
              <a
                  class="nav-link"
                  :class="[currentTabIndex === index ? 'active' : '']"
              >{{ item.title }}</a
              >
            </li>
          </ul>
        </div>
        <div class="flex-fill"></div>
        <a class="btn-edit text-xs" href="javascript:;" @click="toggleMode">{{ mode === 1 ? '编辑' : '完成' }}</a>
      </div>
      <div class="url-card-wrap" v-show="currentTabIndex === 0">
<!--        我的导航-固定的-->
        <div
            class="url-card"
            v-for="(item, index) in nav_data_merge"
            :key="index"
        >
        <img src="@/assets/images/delete.png" class="card-del" @click="deleteStoreNav(item.id)" v-if="mode === 2 && item.isLocal" />
        <div class="url-body mini">
          <a
          :href="item.href"
          @click="setHistory(item)"
          target="_blank"
          class="card mb-3 site-819"
          >
              <div class="card-body">
                <div class="url-content d-flex align-items-center">
                  <div
                      class="
                      url-img
                      rounded-circle
                      mr-2
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                  >
                    <img
                        class="lazy unfancybox loaded"
                        :src="getWebIcon(item.href)"
                    />
                  </div>
                  <div class="url-info flex-fill" style="padding-top: 2px">
                    <div class="text-sm overflowClip_1">
                      <strong>{{ item.title }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <a
                href="javascript:;"
                rel="external nofollow"
                title=""
                class="togo text-center text-muted is-views"
            >
            </a>
          </div>
        </div>
        <div class="url-card plus" v-if="mode === 2" @click="showAddWebModal">
          <div class="card-plus"> + </div>
        </div>
      </div>
      <modal name="add-web" :width="500" :height="230">
        <div class="add-web">
          <div class="add-web-title">添加自定义网址</div>
          <div class="form">
            <input class="input" v-model="webName" placeholder="网站名称"/>
            <input class="input" v-model="webUrl" />
          </div>
          <div class="btns">
            <div class="btn primary" @click="addWeb" style="margin-right: 15px">添加</div>
            <div class="btn" @click="cancelAddWeb">取消</div>
          </div>
        </div>
      </modal>
      <!-- 最新使用 -->
      <div class="" v-show="currentTabIndex === 1">
        <div class="nothing" v-if="contentOptions.history.length === 0">
          暂无使用记录
        </div>
        <div
            class="url-card"
            v-for="(item, index) in contentOptions.history"
            :key="index"
        >
          <img src="@/assets/images/delete.png" class="card-del" @click="deleteStoreHistoryNav(index)" v-if="mode === 2" />
          <div class="url-body mini">
            <a
                :href="item.href"
                @click="setHistory(item)"
                target="_blank"
                class="card mb-3 site-819"
            >
              <div class="card-body">
                <div class="url-content d-flex align-items-center">
                  <div
                      class="
                      url-img
                      rounded-circle
                      mr-2
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                  >
                    <img
                        class="lazy unfancybox loaded"
                        :src="getWebIcon(item.href)"
                        height="auto"
                    />
                  </div>
                  <div class="url-info flex-fill" style="padding-top: 2px">
                    <div class="text-sm overflowClip_1">
                      <strong>{{ item.title }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <a
                href="javascript:;"
                rel="external nofollow"
                title=""
                class="togo text-center text-muted is-views"
            >
            </a>
          </div>
        </div>
      </div>
    </div>
  </ContentWrap>
</template>

<script>
import {storage, setHistory} from "@/utils";
import {newsDataState} from "@/mixins";
import { nanoid } from 'nanoid'

export default {
  name: "CustomerNav",
  mixins: [newsDataState],
  data() {
    return {
      webName: '',
      webUrl: 'https://',
      storeNavData: [],
      currentTabIndex: 0,
      mineHistoryKey: "mine_history",
      navOptions: [
        {
          name: "mine",
          title: "自定义网址",
        },
        {
          name: "history",
          title: "最近使用",
        },
      ],
      anchorStyle: {
        left: 0,
        width: "auto",
        height: "auto",
        opacity: 1,
      },

      // 编辑模式
      mode: 1, // 1-非编辑模式, 2-编辑模式

      contentOptions: {

        // 自行添加的，存储在localstorage
        customMine: [],

        // 最近使用，存储在localstorage
        history: [
          {
            name: "liantong",
            title: "中国联通流量卡",
            href: "https://pan.baidu.com/",
          },
        ],
      },
    };
  },

  computed: {
    // 包含远程拉取的和缓存在localstorage的
    nav_data_merge() {
      return this.nav_data.concat(this.storeNavData)
    }
  },

  mounted() {
    this.storeNavData = storage.get('my-nav') || []
    this.changeNav(0);
    this.initHistory();
  },

  methods: {
    deleteStoreNav(id) {
      let storeNavData = storage.get('my-nav') || []
      storeNavData = storeNavData.filter(item => item.id !== id)
      this.storeNavData = storeNavData
      storage.set('my-nav', storeNavData)
    },
    deleteStoreHistoryNav(index) {
      let historyNavData = storage.get('mine_history') || []
      historyNavData.splice(index, 1)
      this.contentOptions.history = historyNavData
      storage.set('mine_history', historyNavData)
    },
    showAddWebModal() {
      this.$modal.show('add-web');
    },
    addWeb() {
      if(!this.webName || !this.webUrl) {
        alert("请填写信息！")
        return
      }
      if(!/(http|https):\/\/([\w.]+\/?)\S*/.test(this.webUrl)) {
        alert("请填写正确的网址！")
        return
      }
      let myNavStoreData = storage.get('my-nav') || []
      const itemData = {
        id: nanoid(5),
        isLocal: true,
        href: this.webUrl,
        title: this.webName
      }
      myNavStoreData.push(itemData)
      this.storeNavData.push(itemData)
      storage.set('my-nav', myNavStoreData)

      this.cancelAddWeb()
    },
    cancelAddWeb() {
      this.$modal.hide('add-web');
      this.webName = ''
      this.webUrl = 'https://'
    },

    changeNav(index) {
      this.currentTabIndex = index;
      const menu = document.querySelector("#smenu");
      const currentLi =
          menu.querySelectorAll(".nav-item")[this.currentTabIndex];

      this.anchorStyle.width = currentLi.clientWidth + "px";
      this.anchorStyle.height = currentLi.clientHeight + "px";
      this.anchorStyle.left = currentLi.offsetLeft + "px";
      this.initHistory()
    },

    initHistory() {
      this.contentOptions.history = storage.get(this.mineHistoryKey) || [];
    },

    setHistory(item) {
      this.contentOptions.history = setHistory(item);
    },

    toggleMode() {
      this.mode = this.mode === 1 ? 2 : 1;
    },

    getWebIcon(url) {
      return `https://api.iowen.cn/favicon/${this.domainName(url)}.png`;
      // return `https://api.iowen.cn/favicon/${url}.png`;
    },

    // 从url获取域名
    domainName(url) {
      try {
        var urlReg =
            /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/;
        return url.match(urlReg)[0];
      } catch (error) {
        return "";
      }
    },
  },
};
</script>

<style scoped lang="less">
.add-web{
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  .form{
    width: 100%;
    box-sizing: border-box;
  }

  &-title{
    font-size: 16px;
    padding-bottom: 15px;
  }

  .input {
    box-sizing: border-box;
    margin-bottom: 10px;
    color: #6d6e74;
    border: 1px solid #f1f3f6;
    background-color: #f1f3f6;
    width: 100%;
    padding: 6px 12px;
    // font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border-radius: 0.25rem;
  }

  .btn{
    border-radius: 3px;
    display: inline-block;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    line-height: 1.5;
    padding: 7px 15px;
    background-color: #666;
    cursor: pointer;

    &.primary {
      background: #2775bc;
    }
  }
}
.custom-nav {
  padding: 10px 0 0 0;

  .header {
    display: flex;
  }

  .slider-menu {
    position: relative;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    border: 3px solid transparent;
    overflow: hidden;
    margin-left: 10px;

    .anchor {
      cursor: pointer;
      transition: all 350ms;

      width: 60px;
      height: 40px;
      opacity: 0;

      position: absolute;
    }

    .nav-item {
      cursor: pointer;
      width: auto;
      height: auto;

      .nav-link {
        height: 28px;
        line-height: 28px;
        padding: 0 12px;
        display: block;
        text-decoration: none;
        position: relative;
        color: #888;
        text-align: center;
        font-size: 14px;
        padding: 0 16px;
        -webkit-transition: 0.25s;
        transition: 0.25s;

        &.active {
          color: #fff;
          background: #2775bc;
          border-radius: 100px;
        }
      }
    }

    .nav {
      position: relative;
      display: -ms-flexbox;
      white-space: nowrap;
      flex-wrap: initial;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
    }
  }

  .btn-edit {
    color: #ddd;
    line-height: 24px;
    background: #2775bc;
    padding: 0 10px;
    margin: 5px 0;
    margin-right: 10px;
    border-radius: 50px;
    white-space: nowrap;

    &:hover {
      color: #fff;
    }
  }

  .flex-fill {
    flex: 1;
  }

  .content {
    background-color: rgba(255,255,255,1.0);
    border-radius: 8px;
    padding-left: 30px;
  }

  .url-card {
    position: relative;
    display: inline-block;
    width: calc(12%);
    padding: 10px 10px 10px 10px;

    .card-del{
      cursor: pointer;
      position: absolute;
      top: -9px;
      right: -9px;
      color: #f1404b;
      line-height: 20px;
      width: 20px;
      height: 20px;
      z-index: 2;
    }

    .card-plus{
      background: rgba(136, 136, 136, 0.1);
      width: 100%;
      text-align: center;
      border: 2px dashed rgba(136, 136, 136, 0.5);
      border-radius: 3px;
      overflow: hidden;
      padding: 3px 0;
      box-sizing: border-box;
      font-size: 18px;
      cursor: pointer;
    }

    .url-body {
      overflow: hidden;
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
      transition: all 0.3s ease;
    }

    a.card {
      justify-content: center;
      background: #f1f3f6;
      border-width: 0;
      box-shadow: 0px 0px 10px -3px rgb(158 158 158 / 30%);
      transition: background-color 0.3s;

      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      // border: 1px solid rgba(0,0,0,.125);
      border-radius: 4px;
    }

    .card-body {
      padding: 7px 8px;
      position: relative;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      min-height: 1px;
    }

    .url-img {
      width: 20px;
      height: 20px;

      & > img {
        max-height: 100%;
        vertical-align: unset;
      }
    }

    .url-info {
      overflow: hidden;
      padding-right: 5px;
      font-size: 14px;
    }

    a.togo {
      top: 5px;
      position: absolute;
      right: 10px;
      line-height: 30px;
      opacity: 0.2;
      transition: opacity 0.3s;

      &:after {
        font-family: SimSun;
        content: " >";
      }
    }
  }

  .nothing {
    width: 100%;
    padding: 20px 0;
    text-align: center;
    color: #9d9d9d;
    background: rgb(255,255,255);
    border-radius: 5px;
  }

  .btn-edit {
    text-decoration: none;
  }
}
</style>
