//我的导航
//固定导航
const state = {
    nav_data: [
        {
            name: "baiduwangpan",
            title: "百度",
            href: "https://www.baidu.com/?tn=29021690_dg",
        },
        {
            name: "baiduwangpan",
            title: "知了C盘清理",
            href: "https://qingli.zhiletool.com",
        },
        {
            title: "百度翻译",
            href: "https://fanyi.baidu.com/",
        },
        {
            name: "heijing",
            title: "即时工具",
            href: "https://www.67tool.com/",
        },
        {
            name: "xunlei",
            title: "在线应用",
            href: "https://www.onlinedo.cn",
        },
        {
            name: "aiqiyi",
            title: "126邮箱",
            href: "https://mail.126.com/",
        },
        {
            name: "youhui",
            title: "飞猪",
            href: "https://s.click.taobao.com/t?union_lens=lensId%3APUB%401694681346%400bb1cee3_09e5_18a92e1318c_d6b4%4001%40eyJmbG9vcklkIjozODg1Miiwiic3BtQiiI6Il9wb3J0YWxfdjJfcGFnZXNfYWN0aXZpdHlfb2ZmaWNpYWxfaW5kZXhfaHRtIn0ie%3BeventPageId%3A20150318020002752&e=m%3D2%26s%3D3Nt8PDBsBeNw4vFB6t2Z2iperVdZeJviv2laukthwYhnX1vWUft3ZbemdT8kWotGp0dYaCQvYNUjH7cj5ocnB8cPzKAGsDqxc37RY3XTwZDwlZvUe%2BvDkvAy%2Fay3dFHhDVoYw9XnIR6aE4K9cP0A6TYGC9aGv6pK5UoOeRlV%2BcG%2Bh63zuUZMYUw7%2Fef2TPGCLG%2BNEopWdQMM%2B7XZOBKdtm1%2BMNutVMsdg8aaHqPlpqHGDmntuH4VtA%3D%3D",
        },
        {
            name: "baiduwenku",
            title: "携程",
            href: "https://www.ctrip.com/?AllianceID=1094590&sid=2220294&ouid=&app=0101F00",
        },
        {
            name: "baiduwenku",
            title: "爱淘宝",
            href: "https://ai.taobao.com/?pid=mm_131311678_42824299_264092963",
        },
        {
            name: "weiruan365",
            title: "京东",
            href: "https://chaoshi.jd.com/?cu=true&utm_source=www.xinzhuoxun.com&utm_medium=tuiguang&utm_campaign=t_1001802768_&utm_term=98a0175ccf9c451f86ee4c8757d62d0d",
        },
        {
            name: "chatgpt",
            title: "免费chatGPT中文版",
            href: "http://33bj.gpt.zw7.lol",
        },
    ],
}
const mutations = {
}
const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
