const state = {
    data:[
        {
            type: 'tab1',
            listData: [
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '(头条)高校国庆假期有变！缩短！调整！',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '高校国庆北京富婆嫁男下属 50岁生下三胞胎假期有变！缩短！调整！',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '这个领域缺人！月薪4万元还不好招',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '中国最贵女秘书朱旭：年薪849万',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '82岁的她仍帮中国解决一大难题',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '人大女神婚礼阵仗大！妖娆美丽',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '上海外婆的“精致生活”心态从容',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '红色高跟鞋为何会成为恐怖元素？',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '铁杆兄弟有难，中国愿意同甘共苦',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '铁杆兄弟有难，中国愿意同甘共苦',
                //     number: '666万'
                // }
            ]
        },

        {
            type: 'tab2',
            listData: [
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '(社会)高校国庆假期有变！缩短！调整！',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '高校国庆北京富婆嫁男下属 50岁生下三胞胎假期有变！缩短！调整！',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '这个领域缺人！月薪4万元还不好招',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '中国最贵女秘书朱旭：年薪849万',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '82岁的她仍帮中国解决一大难题',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '人大女神婚礼阵仗大！妖娆美丽',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '上海外婆的“精致生活”心态从容',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '红色高跟鞋为何会成为恐怖元素？',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '铁杆兄弟有难，中国愿意同甘共苦',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '上海外婆的“精致生活”心态从容',
                //     number: '666万'
                // },
            ]
        },

        {
            type: 'tab3',
            listData: [
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '(娱乐)高校国庆假期有变！缩短！调整！',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '高校国庆北京富婆嫁男下属 50岁生下三胞胎假期有变！缩短！调整！',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '这个领域缺人！月薪4万元还不好招',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '中国最贵女秘书朱旭：年薪849万',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '82岁的她仍帮中国解决一大难题',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '人大女神婚礼阵仗大！妖娆美丽',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '上海外婆的“精致生活”心态从容',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '红色高跟鞋为何会成为恐怖元素？',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '铁杆兄弟有难，中国愿意同甘共苦',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '上海外婆的“精致生活”心态从容',
                //     number: '666万'
                // },
            ]
        },

        {
            type: 'tab4',
            listData: [
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '（热闻）高校国庆假期有变！缩短！调整！',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '高校国庆北京富婆嫁男下属 50岁生下三胞胎假期有变！缩短！调整！',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '这个领域缺人！月薪4万元还不好招',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '中国最贵女秘书朱旭：年薪849万',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '82岁的她仍帮中国解决一大难题',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '人大女神婚礼阵仗大！妖娆美丽',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '上海外婆的“精致生活”心态从容',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '红色高跟鞋为何会成为恐怖元素？',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '铁杆兄弟有难，中国愿意同甘共苦',
                //     number: '666万'
                // },
                // {
                //     link: 'https://news.jxcn.cn/p/20220923/37708548.html?from=jx61',
                //     title: '上海外婆的“精致生活”心态从容',
                //     number: '666万'
                // },
            ]
        }
    ],
    tab_data: [
        {
            text: '百度',
            type: 'tab1',
            // link: 'https://www.baidu.com'
        },
        {
            text: '微博',
            type: 'tab2',
            // link: 'https://www.baidu.com'
        },
        {
            text: '抖音',
            type: 'tab3',
            // link: 'https://www.baidu.com'
        },
        // {
        //     text: '知乎',
        //     type: 'tab4',
        //     // link: 'https://www.baidu.com'
        // },
    ],
}
const mutations = {
    setRightBoxNewsData (state, data) {
        state.data = data
    },
}
const actions = {
    // 获取头条
    // async getRightBoxNewsData({ commit }){
    //     // mock
    //     const data = [
    //         {
    //             text: '头条',
    //             type: 'tab1',
    //             link: 'https://www.baidu.com'
    //         },
    //         {
    //             text: '微博',
    //             type: 'tab2',
    //             link: 'https://www.baidu.com'
    //         },
    //         {
    //             text: '微信',
    //             type: 'tab3',
    //             link: 'https://www.baidu.com'
    //         },
    //         {
    //             text: '知乎',
    //             type: 'tab4',
    //             link: 'https://www.baidu.com'
    //         },
    //     ];
    //     commit('setRightBoxNewsData', data)
    // }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
