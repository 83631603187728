<template>
  <div>
    <Sections></Sections>
  </div>
</template>

<script>
// import News from './News'
import Sections from './Sections'
export default {
  name: 'RightBox',
  components: {
    // News,
    Sections
  }
}
</script>

<style>

</style>
