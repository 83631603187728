<template>
  <div id="app">
<!--    <PageHome/>-->
    <router-view></router-view>
  </div>
</template>

<script>
// import PageHome from './views/PageHome'

export default {
  name: 'App',
  components: {
    // PageHome
  }
}
</script>

<style>
</style>
