<template>
  <div>
    <li class="xxl-news-item single-pic">
      <a :href="data.url" target="_blank" class="news-link">
        <div class="news-img">
          <div
              class="news-img-bg hover-scale"
              :data-src="data.pic"
              lazy="loaded"
              :style="'background-image: url(' + data.pic + ')'"
          ></div>
        </div>
        <div class="news-right">
          <div class="news-title">
            {{ data.title }}
          </div>
          <div class="news-desc">
            {{ data.subTitle }}
          </div>
          <div class="news-info">
            <span>{{ data.category }}</span><span>{{ data.source }}</span><span>{{ timeToString(data.create_time) }}</span>
          </div>
        </div>
      </a>
    </li>
    <li class="xxl-news-item single-pic" v-if="index !== 0 && index%4 === 0">
      <div :id="'info_'+ index" style="width: 620px;"></div>
    </li>
  </div>

</template>

<script>
import { timeToString } from "@/utils"

export default {
  name: "InfoFlowItems",
  props: ['data','index'],
  data() {
    return {

    };
  },
  methods: {
    timeToString,
  },
  mounted() {
    let self = this;
    // ////console.log(this.index%4 === 0)
    (function () {
      (window.slotbymingyi = window.slotbymingyi || []).push({
        position: 'position2',   //广告位置，需要向商务申请
        container: 'info_'+ self.index      //广告容器id，必须唯一
      });
    })();
  }
};
</script>

<style>
</style>
