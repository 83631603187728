<template>
  <div class="right-box" id="right-box-content">
    <News />
    <Tour/>
    <HotTv/>
    <HotBuy/>
<!--    <HotGame/>-->
<!--    <Apps/>-->
    <!-- 用于被监听是否到底部的辅助节点 -->
    <li id="right-box-bottom-helper" class="right-box-bottom-helper"></li>
  </div>
</template>

<script>
import News from '../News'
import Tour from './Tour'
import HotTv from './HotTv'
import HotBuy from './HotBuy'
// import HotGame from './HotGame'
// import Apps from './Apps'
import { isInViePortOfOne } from '@/utils';

const status = {
  lastInViePortOfOneStatus: false, // 用于记录上一次是否在视野中的状态
  helperScrollHeight: 100000, // 这个是用来记录是否左侧栏已经刚到到达底部的滚动条位置
}

export default {
  name: 'RightSections',
  components: {
    News,
    Tour,
    HotTv,
    HotBuy,
    // HotGame,
    // Apps
  },
  data() {
    return {

    }
  },
  mounted() {
    this.listen()
  },
  methods: {
    listen() {
      const bottomEl = document.querySelector('#right-box-bottom-helper')
      const rightBox = document.querySelector('#right-box-content')
      window.addEventListener("scroll", () => {
        const isInViePortOfOneStatus = isInViePortOfOne(bottomEl)
        if((!status.lastInViePortOfOneStatus && isInViePortOfOneStatus) || (status.lastInViePortOfOneStatus && !isInViePortOfOneStatus)) {
          status.helperScrollHeight = document.documentElement.scrollTop + 56
        }

        if(document.documentElement.scrollTop > status.helperScrollHeight) {
          rightBox.classList = 'right-box right-fixed'
        } else {
          rightBox.classList = 'right-box'
        }
        status.lastInViePortOfOneStatus = isInViePortOfOneStatus
      })
    }
  }
}
</script>

<style>
.right-box {
  width: 270px;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 5%);
  border-radius: 8px;
  overflow: hidden;
  background: #FFF;
}
.right-box-bottom-helper{
  height: 5px;
}

.right-fixed {
  position: fixed;
  bottom: 56px;
}
</style>
