const state = {
    head:{
        title: '热门电视剧',
        link: "http://kan.2345.com/?lm004108"
    },
    carouselData: [
        {
            link: 'http://kan.2345.com/?lm004108',
            imgUrl:require("@/assets/images/famous/TV1.png"),
        },
        {
            link: 'https://www.iqiyi.com/v_xkt6z3z798.html?vfm=f_998_234&fv=9bb2f60df7c22246',
            imgUrl:require("@/assets/images/famous/TV2.png"),
        },
        {
            link: 'https://tv.2345.com/detail/62242.html',
            imgUrl:require("@/assets/images/famous/TV3.jpg"),
        },
    ],
}
const mutations = {
    setRightBoxNewsData (state, data) {
        state.data = data
    },
}
const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
