<template>
  <div class="comp-ppt">
    <swiper @slideChangeTransitionEnd="slideChange" class="ppt-swiper-wrap" ref="mySwiper" :auto-update="true" :options="swiperOptions">
      <swiper-slide v-for="item in topNewsData.caroucel" :key="item.id">
        <a
          :href="item.url"
          target="_blank"
          class="ppt-img"
          ><img
            alt=""
            class="hover-scale"
            :src="item.pic"
            lazy="loaded" />
            <svg
            aria-hidden="true"
            class="svg-icon ppt-hot"
          >
            <use href="#icon-tag_hot"></use></svg>
        </a>
      </swiper-slide>
    </swiper>
    <div class="ppt-swiper-pagination"></div>


  </div>
</template>

<script>
import { newsDataState } from '@/mixins';
export default {
  name: "InfoSwiper",
  mixins: [newsDataState],
  data() {
    return {
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".ppt-swiper-pagination",
          clickable :true,
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    this.swiper.slideTo(3, 1000, false);
  },

  methods: {
    slideChange() {
      this.$emit('change', this.swiper.activeIndex)
    },
  }
};
</script>

<style>
.comp-ppt {
  width: 100%;
  height: 100%;
}
.comp-ppt .ppt-swiper-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.comp-ppt .ppt-swiper-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 200ms;
  transition: all 200ms;
}
.comp-ppt .ppt-swiper-item.active {
  opacity: 1;
  z-index: 1;
}
.comp-ppt .ppt-swiper-pagination {
  text-align: center;
  margin-top: 6px;
  font-size: 0;
}
.comp-ppt .swiper-pagination-bullet {
  width: 4px;
  height: 4px;
  background-color: #d8d8d8;
  border-radius: 50%;
  display: inline-block;
  margin: 0 3px;
  cursor: pointer;
}
.comp-ppt .swiper-pagination-bullet-active {
  width: 12px;
  background-color: #f30;
  border-radius: 8px;
}
</style>
