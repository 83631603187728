const state = {
    head:{
        icon: "https://www.ctrip.com/favicon.ico",
        link: 'https://qingli.zhiletool.com',
        title: 'C盘清理'
    },
    data:{
        link: 'https://qingli.zhiletool.com',
        img_src: require('@/assets/images/famous/zhilecpanqingli.png')
    },
}
const mutations = {
    setRightBoxNewsData (state, data) {
        state.data = data
    },
}
const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
