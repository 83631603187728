<template>
  <div>
    <div class="news-wrapper">
      <ul class="tag">
        <li v-for="item in tabs" :key="item.type">
          <a
            @mouseover="hoverTab(item)"
            :href="item.link"
            :class="[currentTabType === item.type? 'active' : '']"
            target="_blank"
          >
            {{item.text}}
          </a>
        </li>
      </ul>

      <div v-for="item in data" :key="item.type" class="baidu-info" v-show="currentTabType === item.type">
        <ul class="list-info">
          <li v-for="(listItem, listIndex) in item.listData" :key="listIndex">
            <span :class="'span' + listIndex">{{listIndex + 1}}</span>
            <a
              :title="listItem.title"
              :href="listItem.link"
              target="_blank"
              class=""
              >{{listItem.title}}
            </a>
<!--            <div class="right">-->
<!--              {{listItem.number}}-->
<!--            </div>-->
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import {newsDataState} from "@/mixins";

export default {
  name: "News",
  mixins: [newsDataState],
  data() {
    return {
      swiperOptions: {
        loop: true,
        autoplay: false
      },
      currentTabType: 'tab1', // 对应下方的type字段
      tabs: this.$store.state.rightbox_news.tab_data,
      data: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    hoverTab(item) {
      this.currentTabType = item.type
    },
    async getData() {
      // mock
      let data = []
      let result = {}
      //toutiao
      let resp = await request.get("/hots?type=baidu")
      //console.log('resp', resp)
      result.type = "tab1"
      result.listData = resp.data.data
      data.push(result)
      //weibo
      result = {}
      resp = await request.get("/hots?type=weibo")
      result.type = "tab2"
      result.listData = resp.data.data
      data.push(result)
      //weixin
      result = {}
      resp = await request.get("/hots?type=douyin")
      result.type = "tab3"
      result.listData = resp.data.data
      data.push(result)
    //   //zhihu
    //   result = {}
    //   resp = await request.get("/hots?type=zhihu")
    //   result.type = "tab4"
    //   result.listData = resp.data.data
    //   data.push(result)
      this.data = data
      //console.log("this.data", this.data)
      // this.setRightBoxNewsData(data)
    },
  }
};
</script>

<style>

.news-wrapper {
  background: #fff;
  font-size: 14px;
  width: 270px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  border-top: none;
  border-radius: 8px;
  padding-bottom: 19px;
}
.news-wrapper .tag {
  overflow: hidden;
}
.news-wrapper .tag li {
  float: left;
  width: 90px;
}
.news-wrapper .tag a {
  font-size: 15px;
  float: left;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 100%;
  position: relative;
  text-decoration: none;
}
.news-wrapper .tag .active {
  color: #f30;
  font-weight: 700;
  font-size: 16px;
}
.news-wrapper .tag .active:before {
  content: "";
  width: 30%;
  height: 4px;
  overflow: hidden;
  position: absolute;
  left: 28px;
  top: 32px;
  border-radius: 4px;
  /*background-color: #f30;*/
  /*background-image: -webkit-gradient(*/
  /*  linear,*/
  /*  left top,*/
  /*  right top,*/
  /*  from(#f30),*/
  /*  to(#f30)*/
  /*);*/
  /*background-image: linear-gradient(to right, #f30, #f30);*/
}
.news-wrapper .tag {
  background-size: 100% 100%;
}

.baidu-info {
  width: 270px;
  margin: 0 auto;
}
.baidu-info .list-info {
  font-family: MicrosoftYaHei;
  line-height: 32px;
}
.baidu-info .list-info li {
  max-height: 32px;
  margin-left: 15px;
  overflow: hidden;
}
.baidu-info .list-info span {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  float: left;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background: #CDCDCD;
  border-radius: 4px;
  margin-top: 8px;
  margin-right: 4px;
  color: white;
}
.baidu-info .list-info .span0 {
  background: #FF0000;
  background-image: -webkit-gradient(linear, right top, left top, from(#FF4310), color-stop(98%, #FF0000));
  background-image: linear-gradient(270deg, #FF4310 0%, #FF0000 98%);
}
.baidu-info .list-info .span1 {
  background: #FF7A0F;
  background-image: -webkit-gradient(linear, right top, left top, from(#FFA23C), to(#FF7A0F));
  background-image: linear-gradient(270deg, #FFA23C 0%, #FF7A0F 100%);
}
.baidu-info .list-info .span2 {
  background: #FFB446;
  background-image: -webkit-gradient(linear, right top, left top, from(#FFD06A), to(#FFB446));
  background-image: linear-gradient(270deg, #FFD06A 0%, #FFB446 100%);
}
.baidu-info .list-info .right {
  float: right;
  color: #999;
  font-size: 12px;
  max-width: 43px;
}
.baidu-info .list-info a {
  font-size: 14px;
  float: left;
  color: #222222;
  text-overflow: ellipsis;
  height: 32px;
  overflow: hidden;
  max-width: 224px;
  white-space: nowrap;
}
.baidu-info .list-info a:hover {
  color: #FF5125;
}
.baidu-info .change {
  width: 62px;
  height: 24px;
  background: #FFFFFF;
  border-radius: 14px;
  border: 1px solid rgba(0, 101, 255, 0.3);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  color: #0166FF;
  margin: auto;
  margin-bottom: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.baidu-info .change:hover {
  border: none;
  line-height: 24px;
  color: white;
  background: #0094FF;
  background-image: -webkit-gradient(linear, left top, right top, from(#0094FF), to(#0051FF));
  background-image: linear-gradient(90deg, #0094FF 0%, #0051FF 100%);
}
</style>
