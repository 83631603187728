<template>
  <div>
    <SectionHead v-bind="headConfig"/>
    <div class="card-body">
      <div class="row row-sm my-n1" v-for="(item, index) in apps" :key="index">
        <div class="url-card col-6  my-1">
          <a :href="item[0].link" target="_blank"
             class="card post-min m-0">
            <div class="card-body" style="padding:0.3rem 0.5rem;">
              <div class="url-content d-flex align-items-center">
                <div class="url-img rounded-circle">
                  <img class="lazy unfancybox loaded"
                       :src="item[0].img"
                       :data-src="item[0].img"
                       height="auto" alt="Font Awesome Icon"
                       data-was-processed="true">
                </div>
                <div class="url-info pl-1 flex-fill">
                  <div class="text-xs overflowClip_1">{{item[0].title}}</div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="url-card col-6  my-1">
          <a :href="item[1].link" target="_blank"
             class="card post-min m-0">
            <div class="card-body" style="padding:0.3rem 0.5rem;">
              <div class="url-content d-flex align-items-center">
                <div class="url-img rounded-circle">
                  <img class="lazy unfancybox loaded"
                       :src="item[1].img"
                       :data-src="item[1].img"
                       height="auto" alt="Font Awesome Icon"
                       data-was-processed="true">
                </div>
                <div class="url-info pl-1 flex-fill">
                  <div class="text-xs overflowClip_1">{{item[1].title}}</div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHead from "./SectionHead";


export default {
  name: "HotTv",
  components: {
    SectionHead,
  },
  data() {
    return {
      headConfig: this.$store.state.leftbox_app.head,
      apps: this.$store.state.leftbox_app.apps,
    };
  },
  methods: {
  }
};
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 1.25rem;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.row-sm {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.my-n1 {
  margin-bottom: -0.25rem !important;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 45%;
  position: relative;
  width: 100%;
  margin-left: 5px;
  margin-bottom: 5px;
}

.url-card {
  background: #f1f3f6;
  box-shadow: unset;
  transition: .3s;
}

.url-card .url-img {
  width: 20px;
  height: 20px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  background: rgba(128, 128, 128, .1);
  overflow: hidden;
}

.url-card .url-img > img {
  width: 20px;
  height: 20px;
}

.url-card .url-info {
  overflow: hidden;
}

.pl-1, .px-1 {
  padding-left: 0.25rem !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}
</style>
