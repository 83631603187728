<template>
  <div class="hotsearchCon">
    <div id="hotsearch-box" class="hotsearch-box js-hotsearch-box">
      <div class="hotsearchtop" monkey="hotsearchShow1">
        <div v-show="!isExpand" class="allhot-item">
          <ul class="boxhot">
            <li class="text" v-for="item in shortNews" :key="item.id">
              <a
                  alog-custom="hotq:,ts:367mi,abtest:GA0,recom:0,organic:0,index:0,ss:rp"
                  class="js-hotword"
                  target="_blank"
                  :href="item.link"
              >{{item.title}}</a>
              <span class="hot-icon hot-icon-3" data-lu="hotTags">热</span>
            </li>
          </ul>
        </div>
      </div>
      <div
          v-show="isExpand"
          class="hotsearchbottom select"
          data-hooks="hotsearchbottom"
          monkey="hotsearchShow3"
      >
        <ul class="boxhot">
          <li class="text" v-for="item in shortNews" :key="item.id">
            <a
                alog-custom="hotq:,ts:367mi,abtest:GA0,recom:0,organic:0,index:2,ss:rp"
                class="js-hotword"
                hidefocus="true"
                :href="item.link"
                data-lu="link"
                target="_blank"
            >{{ item.title }}</a>
            <span class="hot-icon hot-icon-0" data-lu="hotTags">热</span>
          </li>
        </ul>
        <ul class="boxhot">
          <li class="text" v-for="item in news_one" :key="item.id">
            <a
                alog-custom="hotq:,ts:367mi,abtest:GA0,recom:0,organic:0,index:0,ss:rp"
                class="js-hotword"
                hidefocus="true"
                :href="item.link"
                data-lu="link"
                target="_blank"
            >{{ item.title }}</a>
            <span class="hot-icon hot-icon-0" data-lu="hotTags"></span>
          </li>
        </ul>
        <ul class="boxhot">
          <li class="text" v-for="item in news_two" :key="item.id">
            <a
                alog-custom="hotq:,ts:367mi,abtest:GA0,recom:0,organic:0,index:0,ss:rp"
                class="js-hotword"
                hidefocus="true"
                :href="item.link"
                data-lu="link"
                target="_blank"
            >{{ item.title }}</a>
            <span class="hot-icon hot-icon-0" data-lu="hotTags"></span>
          </li>
        </ul>
      </div>
      <a @click="toggleMode"
         class="ctrlBtn"
         :class="[isExpand? 'select': '']"
         alog-text="热搜词控制按钮"
         data-hooks="ctrlBtn"
         title="更多"
         href="javascript:;"
         style="display: inline"
      > </a>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      isExpand: false,
      news_one:[],
      news_two:[],
      shortNews: []
    }
  },
  mounted() {
    this.getNewsData()
  },
  methods: {
    toggleMode() {
      this.isExpand = !this.isExpand
    },
    async getNewsData() {
      const res = await request.get('/hots?page=1&type=baidu')
      //console.log("res", res.data.data)
      this.shortNews = res.data.data.splice(0,3)
      //console.log("res", res.data.data)
      this.news_one = res.data.data.splice(0,3)
      //console.log("res", res.data.data)
      this.news_two = res.data.data.splice(0,3)
      //console.log("res", res.data.data)
      // //console.log("getNewsData111", this.shortNews,this.news_one,this.news_two)
    }
  }
}
</script>

<style scoped>
.hotsearchCon {
  width: auto;
  margin: 14px auto 0;
  *margin-left: 45px;
  position: relative;
  z-index: 10;
}

.hotsearchCon .hotsearch-box {
  z-index: 103;
  height: 30px;
  width: 652px
}

.hotsearchCon .hotsearch-box .hotsearchtop {
  overflow: hidden;
  position: relative;
  height: 30px;
  z-index: 105;
}

.hotsearchCon .hotsearch-box .hotsearchtop.select {
  width: 25px;
  float: right;
}

.hotsearchCon .hotsearch-box .allhot-item {
  position: relative;
}

.hotsearchCon .hotsearch-box .boxhot {
  overflow: hidden;
  height: 23px;
  line-height: 23px;
  font-size: 12px;
  padding: 0 18px 0 0px;
  position: relative;
  z-index: 106;
  text-align: left;
}

.hotsearchCon .hotsearch-box .boxhot li {
  display: inline-block;
  *zoom: 1;
  font-family: "Microsoft YaHei";
}

.hotsearchCon .hotsearch-box .boxhot .hot-icon {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 2px;
  font-size: 12px;
  font-family: Arial, sans-serif;
  line-height: 16px;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  z-index: -1;
}

.hotsearchCon .hotsearch-box .boxhot .hot-icon- {
  display: none;
}

.hotsearchCon .hotsearch-box .boxhot .hot-icon-0 {
  display: none;
}

.hotsearchCon .hotsearch-box .boxhot .hot-icon-1 {
  background-color: #ff455b;
}

.hotsearchCon .hotsearch-box .boxhot .hot-icon-2 {
  background-color: #3ca6ff;
}

.hotsearchCon .hotsearch-box .boxhot .hot-icon-3 {
  background-color: #ff9812;
}

.hotsearchCon .hotsearch-box .boxhot .hot-icon-4 {
  background-color: #ff7413;
}

.hotsearchCon .hotsearch-box .boxhot .hot-icon-5 {
  background-color: #d61a6e;
}

.hotsearchCon .hotsearch-box .boxhot .title {
  margin-right: 6px;
  display: none;
}

.hotsearchCon .hotsearch-box .boxhot .text {
}

.hotsearchCon .hotsearch-box .boxhot a {
  color: #333;
  font-size: 13px;
}

.hotsearchCon .hotsearch-box .boxhot .js-hotword:hover {
  color: #f50;
  text-decoration: underline;
}

.hotsearchCon .hotsearch-box .ctrlBtn {
  display: none;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 5px;
  top: 3px;
  z-index: 107;
  background: url(https://hao123-static.cdn.bcebos.com/fe-res/her/static/indexnew/component/hotsearch-top/select-bottomx1.4ab8def.png) no-repeat center;
  background: url(https://hao123-static.cdn.bcebos.com/fe-res/her/static/indexnew/component/hotsearch-top/select-bottomx2.51effee.png) no-repeat center / contain;
}

.hotsearchCon .hotsearch-box .ctrlBtn:hover {
  /*background: url(https://hao123-static.cdn.bcebos.com/fe-res/her/static/indexnew/component/hotsearch-top/select-bottom-hoverx1.4410b4e.png) no-repeat center;*/
  /*background: url(https://hao123-static.cdn.bcebos.com/fe-res/her/static/indexnew/component/hotsearch-top/select-bottom-hoverx2.17a7b06.png) no-repeat center / contain;*/
}

.hotsearchCon .hotsearch-box .ctrlBtn.select {
  width: 14px;
  height: 14px;
  background: url(https://hao123-static.cdn.bcebos.com/fe-res/her/static/indexnew/component/hotsearch-top/select-topx1.957c4c1.png) no-repeat center;
  background: url(https://hao123-static.cdn.bcebos.com/fe-res/her/static/indexnew/component/hotsearch-top/select-topx2.6497c56.png) no-repeat center / contain;
}

.hotsearchCon .hotsearch-box .ctrlBtn.select:hover {
  /*background: url(https://hao123-static.cdn.bcebos.com/fe-res/her/static/indexnew/component/hotsearch-top/select-top-hoverx1.6d27062.png) no-repeat center;*/
  /*background: url(https://hao123-static.cdn.bcebos.com/fe-res/her/static/indexnew/component/hotsearch-top/select-top-hoverx2.7aa86f6.png) no-repeat center / contain;*/
}

.hotsearchCon .hotsearch-box .hotsearchbottom {
  height: 90px;
  overflow: hidden;
  display: none;
  border: 1px solid #dee3eb;
  border-top: 0;
  position: relative;
  top: -66px;
  left: 0;
  z-index: 104;
  width: 652px;
  background-color: #fafbfc;
  zoom: 1;
  padding-top: 20px;
  border-radius: 0 0 10px 10px;
}

.hotsearchCon .hotsearch-box .select {
  display: block;
}

.hotsearchCon .hotsearch-box .hotsearchtop .boxhot {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 30px;
}

.hotsearchCon .hovercls {
  position: relative;
  color: #f50;
}

.hotsearchCon .hotsearch-box .hotsearchtop .boxhot li {
  display: inline;
  font-family: "Microsoft YaHei";
}

.hotsearchCon .hotsearch-box .hotsearchtop .boxhot li a {
  display: inline;
}

.hotsearchCon .hotsearch-box .hotsearchbottom .boxhot {
  margin-bottom: 7px;
  padding-left: 15px;
}

.hotsearchCon .hotsearch-box .hotsearchbottom .boxhot li {
  font-family: "Microsoft YaHei";
  width: 194px;
  height: 20px;
  overflow: hidden;
  margin-right: 10px;
  padding-left: 1px;
}
</style>
