<template>
  <div class="page-home">
    <div style="position:fixed;top: 0;z-index: 100;background: white;left: 0;right: 0;margin: 0 auto;">
      <ContentWrap>
        <div class="search-wrap" style="width:1000px;margin-left:100px">
          <!-- logo -->
          <div class="logo">
            <img src="@/assets/images/003.png"/>
          </div>

          <!-- 搜索栏 -->
          <div class="search">
            <SearchSub/>
          </div>
        </div>
      </ContentWrap>
      <ContentWrap class="margin-top" style="width:1000px !important;margin-top: 10px;font-size: 18px;padding-left: 15px;margin-bottom: 20px;">
        <a v-for="(item,j) in types" :key="j" href="javascript:" @click.prevent="onTypes(item)" class="titles"
          :style="{color:(j === 4?'red':'#262626'),font_size:'16px'}">
          {{ item }}
        </a>
      </ContentWrap>
    </div>

    <!-- 常用大站 -->
    <div style="margin-top: 210px;">
      <div v-for="(items, i) in titles" :key="i">
        <ContentWrap class="margin-top" style="margin-top: 30px;font-size: 18px;padding-left: 115px;">
          <a v-for="(item,j) in items" :key="j" href="javascript:" @click.prevent="onTitle(i,j)" class="titles"
            :style="{color:(titles_index[i] === j?'red':'#888')}">
            {{ item }}
          </a>
        </ContentWrap>
        <ContentWrap class="bg-white round margin-top" style="width:1000px !important;"
                    v-for="(item, j ) in items"
                    :key="j">
          <MoreCom :index="item" v-show=" titles_index[i] === j"></MoreCom>
        </ContentWrap>
      </div>
    </div>

    <ContentWrap  class="bg-white round" style="width: 1000px;margin-top: 50px">
      <div id="more_video_id" style="width: 1000px;height: 150px"></div>
    </ContentWrap>
  </div>
</template>

<script>
import MoreCom from "./components/MoreCom.vue";
import SearchSub from "./components/SearchSub.vue";

import {newsDataState, scrollFixed} from '@/mixins';

export default {
  name: "PageHome",
  mixins: [newsDataState, scrollFixed],
  components: {
    MoreCom,
    SearchSub,
  },

  data() {
    return {
      fixedElementConfig: {
        element: '#fixed-element-header',
        placeholderElement: '#fixed-element-placehodler-header',
        fixedTop: '0',
      },
      titles: [],
      types:["首页","办公","新闻","综合","视频","生活","游戏"],
      titles_index: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,],
    };
  },

  created() {
    // this.addNewsData();
    this.getMoreVideoData();
    this.getLinklistData();
    this.titles =this.$store.state.more.title;
    // this.getRightBoxNewsData();
    // this.test();
  },
  mounted() {
    (function () {
      (window.slotbymingyi = window.slotbymingyi || []).push({
        position: 'position1',   //广告位置，需要向商务申请
        container: 'more_video_id'      //广告容器id，必须唯一
      });
    })();
  },
  methods: {
    onTitle(i, j) {
      this.titles_index.splice(i, 1, j)
    },
    onTypes(item){
      if(item === "综合"){
        window.location.href="/more_all";
      }else if(item === "新闻"){
        window.location.href="/more";
      }else if(item === "视频"){
        window.location.href="/more_video";
      }else if(item === "生活"){
        window.location.href="/more_live";
      }else if(item === "游戏"){
        window.location.href="/more_game";
      }else if(item === "首页"){
        window.location.href="/";
      }else if(item === "办公"){
        window.location.href="/more_business";
      }
    }
  },
};
</script>

<style scoped lang="less">

.titles {
  color: #888;
  margin-right: 30px;
  text-decoration: none;
}

a:hover {
  color: red !important;
  text-decoration: none;
}

.icon_logo {
  width: 16px;
  height: 16px;
}
.page-home {
  position: relative;
  overflow: hidden;
  padding-bottom: 70px;

  .head-and-search {
    width: 100%;
    position: relative;
    z-index: 11;
  }

  .search-wrap {
    display: flex;
    align-items: center;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;

    .logo {
      width: 170px;
      height: 58px;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .search {
      flex: 1;
    }
  }

  .content-middle {
    float: left;
    width: 270px;
    position: relative;

  }

  .content-right {
    float: left;
    width: 650px;
    position: relative;
    margin-left: 5px;

  }

  .content-left {
    float: left;
    width: 220px;
    margin-left: 5px;
    position: relative;
  }

  .famous-web {
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 5%);
    position: relative;
    margin-top: 10px;
    box-sizing: border-box;
  }
}

.xxl-news .xxl-news-ppt,
.xxl-news .xxl-news-item,
.xxl-news .xxl-zk-1,
.xxl-news .xxl-zk-2 {
  position: relative;
}
</style>
