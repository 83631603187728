<template>
  <div class="famous-web">
    <ul class="clearfix" v-for="(line, lIndex) in list" :key="lIndex">
      <li v-for="(item, iIndex) in line" :key="iIndex">
        <a @click.prevent="linkTo(item)" href="javascript:void(0)" class="icon" :class="[item.isHot ? 'fred': '']">
          {{ item.title }}
        </a>
        <template v-if="item.subTitle">
          <span class="dot">·</span>
          <a @click.prevent="linkTo({url:item.subUrl,title:item.subTitle})" href="javascript:void(0)">
            {{ item.subTitle }}
          </a>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import {setHistory} from "@/utils";
// const baseImagePath = '../../assets/images/famous/'

export default {
  props: ['index'],
  data() {
    return {
      list: [],
      titles: [],
    }
  },
  computed: {
    common_data() {
      return this.$store.state.more.data[this.index];
    },
    common_title() {
      return this.$store.state.more.title;
    }
  },

  mounted() {
    this.list = this.common_data;
    this.titles = this.common_title;
  },

  methods: {
    linkTo(item) {
      setHistory({href: item.url, title: item.title})
      window.open(item.url)
    },
  }
}
</script>

<style scoped>

.famous-web {
  margin-top: 10px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 10px 0 10px 30px;
  position: relative;
}

.famous-web > ul {
  /*width: 1155px;*/
  margin-bottom: 4px;
}

.famous-web > ul > li {
  font-size: 15px;
  float: left;
  height: 32px;
  width: 121px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
}

.famous-web > ul > span {
  font-size: 15px;
  float: left;
  height: 32px;
  width: 40px;
  line-height: 32px;
  margin-right: 4px;
  overflow: hidden;
  white-space: nowrap;
}

.famous-web > ul > li > a {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.famous-web > ul > li > a:hover .drop-wrapper {
  display: block;
}

.famous-web > ul > li img {
  height: 20px;
}

.famous-web > ul > li .icon img {
  width: 16px;
  height: 16px;
  float: left;
  margin-top: 8px;
  margin-right: 2px;
}

.famous-web > ul > li .icon-bubble {
  display: inline-block;
  height: 32px;
  width: 36px;
  background-image: url('@/assets/images/sprite1.png');
  background-repeat: no-repeat;
  background-position: 0 -91px;
}

.famous-web > ul > li .icon-bubble > a {
  float: right;
  width: 30px;
  height: 32px;
  white-space: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #e30000;
  padding-top: 1px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.famous-web > ul > li .icon-bubble > a:hover {
  text-decoration: none;
}

.famous-web > ul > li .icon-bubble:hover {
  background-image: url('@/assets/images/sprite1.png');
  background-repeat: no-repeat;
  background-position: -40px -91px;
}

.famous-web > ul > li .icon-bubble:hover a {
  color: #fff;
}

.famous-web > ul > li .icon-img img {
  width: auto;
  height: 32px;
}

.famous-web > ul > li .single-img img {
  width: auto;
  height: 32px;
}

.famous-web > ul > li .two-img {
  height: 32px;
  background-repeat: no-repeat;
}

.famous-web > ul > li .two-img:first-child {
  background-position: 0 0;
}

.famous-web > ul > li .two-img:first-child:hover {
  background-position: 0 -32px;
}

.famous-web > ul > li .two-img:last-child {
  background-position: 0 -64px;
}

.famous-web > ul > li .two-img:last-child:hover {
  background-position: 0 -96px;
}

.famous-web > ul > li .drop-wrapper {
  position: absolute;
  left: 0;
  padding-top: 3px;
  width: 104px;
  height: 6px;
  top: 17px;
}

.famous-web > ul > li .drop-wrapper > div {
  position: absolute;
  top: 3px;
  z-index: 1;
  padding: 4px 0;
  border: 1px solid #d8d8d8;
  width: 104px;
  background: #fff;
  text-align: center;
  border-radius: 4px;
}

.famous-web > ul > li .drop-wrapper > div a {
  display: block;
  font-size: 15px;
  line-height: 28px;
  height: 28px;
  overflow: hidden;
  color: #333;
  background: #fff;
  white-space: pre;
  cursor: pointer;
}

.famous-web > ul > li .drop-wrapper > div a:hover {
  text-decoration: none;
  color: #fff !important;
  background: #439df7;
}

.famous-web > ul > li svg {
  width: 6px;
  fill: #686868;
}

.famous-web > ul > li .fred {
  color: #f30;
}

.famous-web > ul > li .fgreen {
  color: #11b006;
}

.famous-web > ul:last-child {
  margin-bottom: 0;
}

.famous-web > ul .last-li {
  max-width: 114px;
}

.famous-web .add {
  position: absolute;
  right: 3px;
  top: 3px;
  cursor: pointer;
  float: left;
  width: 16px;
  height: 16px;
  line-height: 20px;
  background-color: #ffffff;
  border: solid 1px #e4e4e4;
  overflow: hidden;
  color: #0c6ad4;
  border-radius: 50%;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.famous-web .add .icon-oper {
  display: inline-block;
  vertical-align: 4px;
  width: 8px;
  height: 8px;
  overflow: hidden;
  /*background-image: url(//www-stream.2345cdn.net/s/new2345/img/skin-2020081307f844e41fe99775878b91605b1fa78b.png);*/
  background-repeat: no-repeat;
}

.famous-web .add .icon-oper-add {
  background-position: 0 -72px;
}

.famous-web .add .icon-oper-de {
  background-position: 0 -85px;
}

.famous-web .collection ul {
  width: 1150px;
  position: relative;
}

.famous-web .collection .item-add-mini {
  position: absolute;
  width: 16px;
  height: 16px;
  bottom: 8px;
  right: 46px;
  /*background-image: url(//www-stream.2345cdn.net/s/new2345/img/skin-2020081307f844e41fe99775878b91605b1fa78b.png);*/
  background-repeat: no-repeat;
  background-position: 0 -98px;
  cursor: pointer;
}

.famous-web .collection li {
  float: left;
  width: 150px;
  height: 32px;
  margin-right: 4px;
  line-height: 32px;
}

.famous-web .collection li a {
  font-size: 15px;
  display: inline;
}

.famous-web .collection li span {
  cursor: pointer;
  color: #999;
}

.famous-web .collection li span i {
  float: left;
  width: 18px;
  height: 18px;
  /*background-image: url(//www-stream.2345cdn.net/s/new2345/img/skin-2020081307f844e41fe99775878b91605b1fa78b.png);*/
  background-repeat: no-repeat;
  background-position: -20px -96px;
  margin-top: 7px;
  margin-right: 2px;
}

.famous-web .collection li span:hover {
  color: #0c6ad4;
}

.famous-web .collection li span:hover i {
  background-position: -20px -117px;
}
</style>
