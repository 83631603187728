<template>
  <div class="footer-info" style="height: 28px" v-show="show">
    <div class="about-box">
      <router-link to="/about" target="_blank">关于本站</router-link>
      <i>|</i>
      <router-link to="/agreement" target="_blank">免责声明</router-link>
      <i>|</i>
      <router-link to="/contact" target="_blank">商务合作</router-link>
      <i>|</i>
      <a href="https://beian.miit.gov.cn" target="_blank">琼ICP备2022017946号</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false
    }
  },
  mounted() {
    window.addEventListener("scroll", this.listen);
  },
  methods: {
    listen() {
      if (
          !!document.documentElement.scrollTop &&
          document.documentElement.scrollTop > 600
      ) {
        this.show = true
      } else {
        this.show = false
      }
    },
  },
};
</script>

<style scoped>
.footer-info {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 56px;
  color: #999;
  font-size: 12px;
  text-align: center;
  background: #f8f9fb;
  z-index: 11;
}

.footer-info .about-box {
  position: relative;
  width: 1190px;
  margin: 0 auto;
  height: 26px;
  line-height: 30px;
}

.footer-info .about-box a {
  color: #999;
}

.footer-info .about-box i {
  margin: 0 2px;
}

.footer-info .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin: -3px 3px 0;
  background: url(@/assets/images/footer-icons.png) no-repeat;
}

.footer-info .icon1 {
  background-position: -10px -10px;
}

.footer-info .icon2 {
  background-position: -54px -10px;
}

.footer-info .icon3 {
  background-position: -98px -10px;
}

.footer-info .icon4 {
  width: 20px;
  height: 20px;
  background: url(@/assets/images/footer-icons.png) no-repeat;
  background-position: -29px -348px;
}

.footer-info .footer-bd-wm {
  visibility: hidden;
  height: 1px;
}
</style>
