const state = {
    data:[],
    title:[],
}
const mutations = {
    setCommonData (state, data) {
        state.data = data
    },
    setTitle(state, data){
        state.title = data
    }
}
const actions = {
    // 获取头条
    async getCommonData({ commit }){
        // mock
        const data = [
            // 第0行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/baidufanyi.png'),
                    url: 'https://fanyi.baidu.com/',
                    title: '百度翻译'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/baiduwangpan.png'),
                    url: 'https://pan.baidu.com/',
                    title: '百度网盘'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xmind.png'),
                    url: 'https://xmind.cn/',
                    title: 'Xmind'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/qiantuwang.png'),
                    url: 'https://588ku.com/',
                    title: '千库网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wenxinyige.png'),
                    url: 'https://yige.baidu.com/',
                    title: '文心一格'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/aijianji.png'),
                    url: 'https://www.ijianji.com/',
                    title: '爱剪辑'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/lupindashi.png'),
                    url: 'https://www.callmysoft.com/luping?promotionid=aw0QN2p1gwtAEZviNxivtvnaBb4wLwzq',
                    title: '录屏大师'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/meituxiuxiu.png'),
                    url: 'https://pc.meitu.com/',
                    title: '美图秀秀'
                },
                // {
                //     isHot: false,
                //     icon: require('@/assets/images/famous/pdfzaixian.png'),
                //     url: 'https://www.ilovepdf.com/zh-cn',
                //     title: 'PDF在线工具'
                // },
            ],
            // 第一行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/renmin.png'),
                    url: 'http://www.people.com.cn',
                    title: '人民网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xinhua.png'),
                    url: 'http://www.xinhuanet.com',
                    title: '新华网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huangqiu.png'),
                    url: 'https://www.huanqiu.com',
                    title: '环球'
                },
               {
                    isHot: false, //是否标红字体
                    icon: "https://weibo.com/favicon.ico", // 图标
                    url: 'https://www.sina.com.cn', // 主跳转地址
                    title: '新浪', // 主标题
                    expand: [ // 下拉内容，TODO:

                    ],
                    subUrl: 'https://www.weibo.com/', // 副跳转地址
                    subTitle: '微博', // 副标题
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://www.qq.com',
                    title: '腾讯网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/souhu.png'),
                    url: 'https://www.sohu.com',
                    title: '搜狐'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/wangyi.png'),
                    url: 'https://www.163.com',
                    title: '网易'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/fenghuang.png'),
                    url: 'https://www.ifeng.com',
                    title: '凤凰网'
                },
                // {
                //     isHot: false,
                //     icon: require('@/assets/images/famous/dongfang.png'),
                //     url: 'https://www.eastmoney.com/',
                //     title: '东方财富'
                // },
            ],

            // 第二行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/taobao.png'),
                    url: 'https://ai.taobao.com/?pid=mm_131311678_42824299_264092963',
                    title: '爱淘宝'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/jingdong.png'),
                    url: 'https://chaoshi.jd.com/?cu=true&utm_source=www.xinzhuoxun.com&utm_medium=tuiguang&utm_campaign=t_1001802768_&utm_term=e703cdef8f1d401aa6544bad4f03d2a9',
                    title: '京东',
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tianmao.png'),
                    url: 'https://www.tmall.com/',
                    title: '天猫'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/weipinhui.png'),
                    url: 'https://www.vip.com/',
                    title: '唯品会'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/1688.png'),
                    url: 'https://p4psearch.1688.com/',
                    title: '1688'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/dangdang.png'),
                    url: 'http://www.dangdang.com/',
                    title: '当当网'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/suningyigou.png'),
                    url: 'https://www.suning.com',
                    title: '苏宁易购'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tianmao.png'),
                    url: 'https://pages.tmall.com/',
                    title: '天猫国际'
                },
                // {
                //     isHot: false,
                //     icon: require('@/assets/images/famous/tianmao.png'),
                //     url: 'https://pages.tmall.com',
                //     title: '天猫国际'
                // },
            ],

            // 第三行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/tengxun.png'),
                    url: 'https://v.qq.com/',
                    title: '腾讯视频'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/douyin.png'),
                    url: 'https://www.douyin.com',
                    title: '抖音',
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/aiqiyi.png'),
                    url: 'https://www.iqiyi.com',
                    title: '爱奇艺'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/youku.png'),
                    url: 'https://youku.com',
                    title: '优酷'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/2345yingshi.png'),
                    url: 'http://kan.2345.com/?lm004108',
                    title: '2345视频'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/bilibili.png'),
                    url: 'https://www.bilibili.com/',
                    title: '哔哩哔哩'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/dianshi.png'),
                    url: 'http://kan.2345.com/?lm004108',
                    title: '电视剧'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/huya.png'),
                    url: 'https://www.huya.com',
                    title: '虎牙直播',
                },
                // {
                //     isHot: false,
                //     icon: require('@/assets/images/famous/liujianfang.png'),
                //     url: 'https://v.6.cn/',
                //     title: '六间房直播',
                // },
            ],
            
            
            // 第四行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/xiecheng.png'),
                    url: 'https://www.ctrip.com/?AllianceID=1094590&sid=2220294&ouid=&app=0101F00',
                    title: '携程'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/feizhu.png'),
                    url: 'https://www.fliggy.com/',
                    title: '飞猪'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/58.png'),
                    url: 'https://haikou.58.com/',
                    title: '58同城'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/12306.png'),
                    url: 'https://www.12306.cn/',
                    title: '12306'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/qianchengwuyou.png'),
                    url: 'https://www.51job.com',
                    title: '前程无忧'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/zhihu.png'),
                    url: 'https://www.zhihu.com/',
                    title: '知乎'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/qichezhijia.png'),
                    url: 'https://www.autohome.com.cn/',
                    title: '汽车之家'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/anjuke.png'),
                    url: 'https://anjuke.com',
                    title: '安居客'
                },
                // {
                //     isHot: false,
                //     icon: require('@/assets/images/famous/douban.png'),
                //     url: 'https://www.douban.com',
                //     title: '豆瓣'
                // },
            ],


            // 第五行
            [
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/37wangyou.png'),
                    url: 'http://baiduzm.37.com/',
                    title: '37网游'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/9377.png'),
                    url: 'https://tp.9377j.com/',
                    title: '9377页游'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/51youxi.png'),
                    url: 'https://code.51.com/',
                    title: '51游戏'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/4399.png'),
                    url: 'https://www.4399.com',
                    title: '4399游戏'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/yeyou.png'),
                    url: 'https://wan.2345.com/iconTg/tg2/index8.html',
                    title: '热门游戏'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/7k7k.png'),
                    url: 'http://www.7k7k.com/',
                    title: '7k7k游戏'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/toutiao.png'),
                    url: 'http://tp.9377s.com/1159531/',
                    title: '2022传奇'
                },
                {
                    isHot: false,
                    icon: require('@/assets/images/famous/toutiao.png'),
                    url: 'https://wan.baidu.com/cover?gameId=24063258&idfrom=4084',
                    title: '原始传奇'
                },
                // {
                //     isHot: false,
                //     icon: require('@/assets/images/famous/7k7k.png'),
                //     url: 'http://www.7k7k.com/',
                //     title: '小游戏'
                // },
            ],
        ];
        const title = [
            "办公",
            "新闻",
            "综合",
            "视频",
            "生活",
            "游戏"
        ];
        commit('setCommonData', data)
        commit('setTitle', title)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
