const state = {
    data:[]
}
const mutations = {
    setLinklistData (state, data) {
        state.data = data
    },
}
const actions = {
    // 获取头条
    async getLinklistData({ commit }){
        // mock
        const data = [
            {
                title: "游戏",
                href: "https://www.baidu.com",
                moreHref: "https://www.baidu.com",
                links: [
                    {
                        title: "一刀9990亿",
                        href: "https://www.baidu.com",
                    },
                    {
                        isHot: true, //标红
                        title: "页游前十名",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "0充打到爽",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "变态吸血传奇",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "大圣高爆服",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "装备全靠爆",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "一刀一传奇",
                        href: "https://www.baidu.com",
                    },
                ],
            },
            {
                title: "热门",
                href: "https://www.baidu.com",
                links: [
                    {
                        title: "今日新闻头条",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "重打军事新闻",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "中华网军事",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "特发突发军事",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "紧急新军事",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "MAX散人打金",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "【魂环】传奇",
                        href: "https://www.baidu.com",
                    },
                ],
            },
            {
                title: "页游",
                href: "https://www.baidu.com",
                links: [
                    {
                        title: "一刀一红装",
                        href: "https://www.baidu.com",
                    },
                    {
                        isHot: true,
                        title: "4399游戏",
                        href: "https://www.baidu.com",
                    },
                    {
                        icon: "https://www.91duba.com/image/game/20220722/5c2cc0b7a7abbc693c5dadd4d373416a.png_.webp",
                        title: "传奇高爆版",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "1秒9999刀",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "道士带N狗",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "传奇0首充",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "新山海经OL",
                        href: "https://www.baidu.com",
                    },
                ],
            },
            {
                title: "生活",
                href: "https://www.baidu.com",
                links: [
                    {
                        title: "头条新闻",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "今日热点新闻",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "唯品会",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "安居客特价房",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "爱奇艺",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "体育快讯",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "最新影视",
                        href: "https://www.baidu.com",
                    },
                ],
            },
            {
                title: "娱乐",
                href: "https://www.baidu.com",
                links: [
                    {
                        title: "7K7K游戏",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "2345游戏",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "痘印短视频",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "复古传奇1.76",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "开局一颗蛋",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "热播电影",
                        href: "https://www.baidu.com",
                    },
                    {
                        title: "热门动漫",
                        href: "https://www.baidu.com",
                    },
                ],
            },
        ];
        commit('setLinklistData', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
